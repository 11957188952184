import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Container, TextField, Typography, Grid } from '@material-ui/core';
import { authUser } from '../../services/User';
import { saveAuth } from '../../hooks/useAuth';
import { getPublicIp } from '../../utils/index';
import Login2FACheckout from '../Login/Login2FACheckout';
import LoginAccountsCheckout from '../Login/LoginAccountsCheckout';

function DomainLoginComponent(props) {

  const navigate = useNavigate();

  const [errValidUser, setErrValidUser] = useState(null);

  const location = useLocation();

  const subTotalAdd = location.state.data.subtotaladd;
  const subtotal = location.state.data.subtotal;
  const total = location.state.data.total;
  const domain = location.state.data.domain;
  const pricedomain = location.state.data.pricedomain;
  const pricerenewdomain = location.state.data.pricerenewdomain;
  const eventDomain = location.state.data.eventdomain;
  const freedomain = location.state.data.freedomain;
  const billingcycle = location.state.data.billingcycle;
  const paymentmethod = null;
  const coupon = location.state.data.coupon;
  const purchaserecordtype = location.state.data.purchaserecordtype;
  const epp = location.state.data.epp;

  const qtyanios = location.state.data.qtyanios;
  const domainaddons = location.state.data.domainaddons;
  const nameservers = location.state.data.nameservers;

  const [order, setOrder] = useState(null);

  //segment 2fa
  const [with2fa, setWith2fa] = useState(0);
  const [id2fa, setId2fa] = useState(null);

  //with multiples accounts
  const [accounts, setAccounts] = useState(null);
  const [withAccounts, setWithAccounts] = useState(false);
  const [uid, setUid] = useState(null);
  const [language, setLanguage] = useState(null);

  const isCodeAuth2fa = (val) => {
    if (val.accounts === 1) {
      saveAuth(val);
      navigate('/cart/domain/checkout/', { state: { data: order } });

    } else {
      setAccounts(val.accountsdata);
      setUid(val.uid);
      setLanguage(val.language);
      setWithAccounts(true);
      setWith2fa(2); //para ocultar code auth
    }
  }

  const selectProfile = (val) => {
    saveAuth(val);
    navigate('/cart/domain/checkout/', { state: { data: order } });
  }

  return (
    <>
      {with2fa === 0 &&
        <Container maxWidth="md">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('E-mail inválido').max(255).required('Email es requerido'),
              password: Yup.string().max(255).required('Contraseña es requerida')
            })}

            onSubmit={async (val, actions) => {


              const ip = await getPublicIp();
              //const priceproductdec = priceProduct.toFixed(2);
              //const pricedomaindec = pricedomain.toFixed(2);
              //const pricerenewdomaindec = pricerenewdomain.toFixed(2);

              const order = {
                subtotaladd: subTotalAdd,
                subtotal: subtotal,
                total: total,
                domain: domain,
                pricedomain: pricedomain,
                pricerenewdomain: pricerenewdomain,
                freedomain: freedomain,
                eventdomain: eventDomain,
                billingcycle: billingcycle,
                paymentmethod: paymentmethod,
                ipaddress: ip,
                domainaddons: domainaddons,
                nameservers: nameservers,
                qtyanios: qtyanios,
                coupon: coupon,
                purchaserecordtype: purchaserecordtype,
                epp: epp,
                status: 'buyinprogress'
              }

              setOrder(order);

              const res = await authUser(val.email, val.password, ip);

              if (res.error === false) {
                setErrValidUser(null);

                if (res.active2fa !== 1) {

                  if (res.accounts === 1) {
                    saveAuth(res);
                    navigate(`/cart/domain/checkout/`, { state: { data: order } });
                  } else {
                    setAccounts(res.accountsdata);
                    setUid(res.uid);
                    setLanguage(res.language);
                    setWithAccounts(true);
                    setWith2fa(2); //ocultar code auth
                  }

                } else { //con auth
                  setWith2fa(res.active2fa);
                  setId2fa(res.id2fa);
                }

              } else {
                actions.setSubmitting(false);
                setErrValidUser("Usuario y/o contraseña incorrecta");

                actions.resetForm();
              }

            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>

                <Grid container spacing={1}>
                  {errValidUser &&
                    <Grid item xs={12} md={12}>
                      <Typography sx={{ color: 'red', fontSize: '1.3rem' }} >
                        {errValidUser}
                      </Typography>
                    </Grid>
                  }

                  <Grid item xs={12} md={6}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Correo electrónico"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Introduce tu contraseña"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>


                </Grid>

                <Grid container sx={{ mt: 2 }}>

                  <Grid item xs={12} md={12}>
                    <Box sx={{ py: 3 }}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Continúa con tu cuenta
                      </Button>
                    </Box>
                  </Grid>

                </Grid>


              </form>
            )}
          </Formik>
        </Container>
      }

      {with2fa === 1 &&
        <Login2FACheckout id2fa={id2fa} validatecode={isCodeAuth2fa} />
      }

      {withAccounts === true &&
        <>
          {accounts &&
            <LoginAccountsCheckout accounts={accounts} uid={uid} language={language} selectProfile={selectProfile} />
          }
        </>
      }

    </>
  );
}

export default DomainLoginComponent;