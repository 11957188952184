import { Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import appConfig from '../config/main.config';
import { createStripePaymentIntentTest } from '../services/Payment';
import { LoadingButton } from '@mui/lab';
import CheckoutFormTest from './test-checkout-form';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
//const stripePromise = loadStripe(appConfig.pk_stripe);
const stripePromise = loadStripe(appConfig.pk_stripe, {   
    betas: ['link_beta_2'],
    apiVersion: '2020-08-27;link_beta=v1',
});

function StripePaymentElementTest(props) {
    const [clientSecret, setClientSecret] = useState(null);
    const [loadingButton, setLoadingButton] = useState(false);
    const [options, setOptions] = useState(null);

    const [client_email_test, setClientEmail] =  useState(''); 

    const handlePayIntentStripe = async () => {
        setLoadingButton(true);
        //const csecret = await createStripePaymentIntent(props.invoice);
        const datatest = {
            invoiceid: '715427',
            total: 49.00,
            client_id: 16813,
            client_email: 'luselen70@gmail.com'
        }

        const csecret = await createStripePaymentIntentTest(datatest);

            if(csecret.error === false ){
                setLoadingButton(false);
                setClientEmail(csecret.client_email);
                setOptions({
                    clientSecret: csecret.client_secret,
                    appearance: {
                        theme: 'flat'
                    }
                });

                setClientSecret(csecret.client_secret);
            }else{
                setClientSecret(null);
                //poner un mensaje para intentar con otra pasarela en caso de error
            }
    };

    return (
        <div>
            {clientSecret === null ?
                <Container>
                    <LoadingButton loading={loadingButton} variant="contained" style={{color: '#fff'}} fullWidth onClick={handlePayIntentStripe}>Pago Único 2</LoadingButton>
                </Container>
                :
                <Elements stripe={stripePromise} options={options}>
                    <CheckoutFormTest clientEmail={client_email_test} />
                </Elements>
            }

        </div>
    );
}

export default StripePaymentElementTest;