import axios from 'axios';
import appConfig from '../config/main.config';
const apiserv = appConfig.url_api;

export const getInvoicesClient = () => {

    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/invoices/`;

    const client = axios.get(api, { headers: { "Authorization": `Bearer ${token}` } })
        .then(res => {
            //console.log('res data', res.data)
            //return res.data;
            return { ...res.data, ...{error: false}}
            //return  {...res.data, ...{error: false}};
        })
        .catch((error) => {
            //console.log('error', error.config);
            //return  {error: true};
            return {error: true, message: error.response}
            //return [];

        });

    return client;

};

export function getInvoiceClient(id) {

    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/invoices/${id}`;

    const invoice = axios.get(api, { headers: { "Authorization": `Bearer ${token}` } })
        .then(res => {
            //console.log('res data', res.data)
            //return res.data;
            return { ...res.data, ...{ error: false } };
        })
        .catch((error) => {
            //console.log(error.config);
            return {error: true, message: error.response}
        });

    return invoice;

};

export function getInvoiceDescription(id) {

    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/invoices/${id}/description/`;

    const invoice = axios.get(api, { headers: { "Authorization": `Bearer ${token}` } })
        .then(res => {
            //console.log('res data', res.data)
            //return res.data;
            return { descriptions: res.data, error: false };
        })
        .catch((error) => {
            //console.log(error.config);
            return {error: true, message: error.response}
        });

    return invoice;

};

export const createFullRecord = (data) => {
    const api = `${apiserv}/invoices/fullrecord/`;

    const invoice = axios.post(api, data )
        .then(res => {
            return { ...res.data, ...{ error: false } };
        })
        .catch((error) => {
            return {error: true}
            /*if(error.response.status === 404){
                return { ...{message: error.message}, ...{error: true} }
            }else{
                return { ...error.response.data, ...{error: true} }
            }*/
    });

    return invoice;
};

export const createFullDomainRecord = (data) => {
    const api = `${apiserv}/invoices/fulldomainrecord/`;

    const invoice = axios.post(api, data )
        .then(res => {
            return { ...res.data, ...{ error: false } };
        })
        .catch((error) => {
            return {error: true}
            /*if(error.response.status === 404){
                return { ...{message: error.message}, ...{error: true} }
            }else{
                return { ...error.response.data, ...{error: true} }
            }*/
    });

    return invoice;
};

export const createRecord = (data) => {
    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/invoices/record/`;

    const newtransaction = axios.post(api, data, { headers: { "Authorization": `Bearer ${token}` } })
        .then(res => {
            return { ...res.data, ...{ error: false } };
        })
        .catch((error) => {
            if(error.response.status === 404){
                return { ...{message: error.message}, ...{error: true} }
            }else{
                //return { ...error.response.data, ...{error: true} }
                return {error: true, message: error.response}
            }
    });

    return newtransaction;
};

export const createDomainRecord = (data) => {
    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/invoices/domainrecord/`;

    const newtransaction = axios.post(api, data, { headers: { "Authorization": `Bearer ${token}` } })
        .then(res => {
            return { ...res.data, ...{ error: false } };
        })
        .catch((error) => {
            if(error.response.status === 404){
                return { ...{message: error.message}, ...{error: true} }
            }else{
                //console.log('domain record', error.response.data);
                //return { ...error.response.data, ...{error: true} }
                return {error: true, message: error.response}
            }
    });

    return newtransaction;
};

export const updatePaymentMethod = (data) => {
    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/invoices/${data.invoiceid}/paymentmethod/`;

    const invoiceupdated = axios.put(api, data, { headers: { "Authorization": `Bearer ${token}` } })
        .then(res => {
            return { ...res.data, ...{ error: false } };
        })
        .catch((error) => {
            if(error.response.status === 404){
                return { ...{message: error.message}, ...{error: true} }
            }else{
                return { ...error.response.data, ...{error: true} }
            }
    });

    return invoiceupdated;
};