import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Container, Grid, TextField, Typography } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import { logout } from '../../hooks/useAuth';
import { downloadFile, getTicketReplies, setTicketReply, updateStatusTicket } from '../../services/Ticket';
import SpinnerLoad from '../../components/FormsUI/Spinner';
import AccountIcon from '@mui/icons-material/Person';
import ManageIcon from '@mui/icons-material/ManageAccounts';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import AttachIcon from '@material-ui/icons/AttachFile';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup'
import { LoadingButton } from '@mui/lab';
import DialogAttachment from './DialogAttachment';
import { Button } from '@mui/material';
import DialogDownload from './DialogDownload';

const Ticket = () => {
    const navigate = useNavigate();
    const { ticket_id, ticket_c } = useParams();
    const [error, setError] = useState(false);
    const [replies, setReplies] = useState([]);
    const [ticket, setTicket] = useState(null);

    const [statusLoading, setStatusLoading] = useState(false);
    const [closeLoading, setCloseLoading] = useState(false);

    //attachments
    const [allowedExtensions, setAllowedExtensions] = useState("*");
    const [files, setFiles] = useState([]);
    const [isSelectedFile, setIsSelectedFile] = useState(false);

    //dialog
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogDown, setOpenDialogDown] = useState(false);

    const initialValues = {
        message: ''
    }
    const validationSchema = Yup.object().shape({
        message: Yup.string()
            .required("Debe escribir un mensaje")
    })

    const sendMessage = async (values) => {
        setStatusLoading(true);
        const reply = await setTicketReply(ticket_id, ticket_c, ticket.id, values.message, files);

        if (reply.error === false) {
            window.location.reload();
        }

    }

    const handleCloseTicket = async () => {
        setCloseLoading(true);
        const data = { tid: ticket.id, status: "Closed" }
        const closeTicket = await updateStatusTicket(data);

        navigate("/clientarea/tickets/", { replace: true });
    }

    //attachments
    const handleChangeFile = (event) => {
        if (event.target.files.length !== 0) {
            //verificar extension de archivo
            const segmentsfilename = event.target.files[0].name.split(".");
            const extension = segmentsfilename[segmentsfilename.length - 1];

            const found = ticket.allowedFiles.find(element => (element.substring(1)) === extension);

            if (found) {
                setFiles([...files, event.target.files[0]]);
                setIsSelectedFile(true);
            } else {
                //error de extension
                setOpenDialog(true);
            }
        }
    }

    //dialog
    const handleDialog = (status) => {
        setOpenDialog(status);
    }
    const handleDialogDown = (status) => {
        setOpenDialogDown(status);
    }

    //download File
    const downloadAttachment = async (filename) => {
        const res = await downloadFile(ticket_id, ticket_c, filename);
        if (res.error === true) {
            setOpenDialogDown(true);
        }
    }

    useEffect(() => {

        const getReplies = async () => {
            const data = await getTicketReplies(ticket_id, ticket_c);

            if (data.error === true) {
                if (data.message.status === 401) {
                    logout();
                    navigate('/', { replace: true });
                }
                setError(true);
            }
            if (data.error === false) {
                setReplies(data.replies);
                setTicket(data.ticket);
                setAllowedExtensions(data.ticket.allowedFiles.toString());
            }
        }

        getReplies();

    }, [])

    return (
        <>
            <Helmet>
                <title>Portal - Área de clientes de Neothek</title>
            </Helmet>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center'
                }}
            >

                {ticket === null ?

                    <>
                        {error === true ?
                            <Container maxWidth="sm" variant='cont-nk'>

                                <Box sx={{ mb: 2, mt: 3 }}>
                                    <Typography
                                        color="textPrimary"
                                        variant="h2"
                                        align="center"
                                    >
                                        Lo sentimos,
                                    </Typography>

                                </Box>

                                <Box sx={{ pt: 1, pb: 2 }}>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="body1"
                                        align="center"
                                    >
                                        Ha existido una falla o este ticket no corresponde a su cuenta.
                                    </Typography>

                                </Box>

                            </Container>

                            :

                            <SpinnerLoad />
                        }
                    </>

                    :

                    <Container maxWidth="lg" variant='cont-nk'>


                        <Grid container sx={{ backgroundColor: '#f8f8f8' }} mt={3} mb={3} pb={2}>
                            <Box sx={{ paddingTop: '16px', paddingLeft: '10px' }}>
                                {(ticket.status === 'Open') &&
                                    <Chip color="secondary" size="small" label={ticket.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#E3E8EE', color: '#53566B' }} />
                                }
                                {(ticket.status === 'Answered' || ticket.status === 'Contestada') &&
                                    <Chip size="small" label={ticket.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#cbf4c9', color: '#268717' }} />
                                }
                                {(ticket.status === 'In Progress' || ticket.status === 'En progreso' || ticket.status === 'Escalado') &&
                                    <Chip size="small" label={ticket.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#aff8ff', color: '#1f8c96' }} />
                                }
                                {(ticket.status === 'Closed' || ticket.status === 'Cerrado' || ticket.status === 'cerrado') &&
                                    <Chip size="small" label={ticket.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#F8DADB', color: '#B72136' }} />
                                }
                                {ticket.status === 'Customer-Reply' &&
                                    <Chip size="small" label={ticket.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#c0ddff', color: '#6773c9' }} />
                                }
                                {ticket.status === 'Facturacion' &&
                                    <Chip size="small" label={ticket.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#73adff', color: '#fff' }} />
                                }
                                {(ticket.status === 'Diseno Web' || ticket.status === 'Grafico') &&
                                    <Chip size="small" label={ticket.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#a89fff', color: '#fff' }} />
                                }

                                <Typography variant='h4' sx={{ display: 'inline', pr: 1, pl: 2 }}>Ticket #{ticket.tid} : {ticket.title}</Typography>

                            </Box>
                        </Grid>

                        <Grid container sx={{ borderBottom: "1px solid #e5e5e5", marginLeft: "-15px", paddingBottom: "1rem", marginBottom: "4rem" }} spacing={4}>
                            <Grid item xs >
                                <Typography sx={{ fontSize: "1.37rem", color: "#5e5e5e", textAlign: "center" }}><b style={{ color: "#333" }}>Departamento</b> <br></br> {ticket.dpto}</Typography>
                            </Grid>
                            <Grid item xs >
                                <Typography sx={{ fontSize: "1.37rem", color: "#5e5e5e", textAlign: "center" }}><b style={{ color: "#333" }}>Prioridad</b> <br></br>  {ticket.urgency}</Typography>
                            </Grid>
                            <Grid item xs >
                                <Typography sx={{ fontSize: "1.37rem", color: "#5e5e5e", textAlign: "center" }}><b style={{ color: "#333" }}>Creado</b> <br></br>  {ticket.date}</Typography>
                            </Grid>

                            {(ticket.status !== 'Closed' && ticket.status !== 'Cerrado' && ticket.status !== 'cerrado') &&
                                <Grid item xs>
                                    <LoadingButton loading={closeLoading} onClick={handleCloseTicket} variant='outlined' color='primary' sx={{ fontSize: "1.2rem" }}>
                                        Cerrar Ticket
                                    </LoadingButton>
                                </Grid>
                            }

                        </Grid>


                        <Grid container sx={{ pb: 3 }}>
                            <Grid item xs={12} >
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        sx={{ backgroundColor: "#fbfbfb" }}
                                    >
                                        {(ticket.status === 'Closed' || ticket.status === 'Cerrado' || ticket.status === 'cerrado') ?
                                            <Typography sx={{ fontSize: "1.4rem", fontWeight: "600", display: "inline", paddingRight: "10px", color: "#051d4d" }}>Volver a abrir Ticket</Typography>
                                            :
                                            <Typography sx={{ fontSize: "1.4rem", fontWeight: "600", display: "inline", paddingRight: "10px", color: "#051d4d" }}>Escribir respuesta</Typography>
                                        }

                                        <EditIcon sx={{ fontSize: "1.95rem", color: "#051d4d" }} />
                                    </AccordionSummary>
                                    <AccordionDetails>


                                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={sendMessage}>
                                            {(props) => (

                                                <Form>

                                                    <Field as={TextField} variant="outlined" name='message' label='Mensaje' multiline rows={6} fullWidth
                                                        error={props.errors.message && props.touched.message}
                                                        helperText={<ErrorMessage name='message' />} required />

                                                    <Grid container mt={1}>
                                                        {isSelectedFile === true &&
                                                            <>
                                                                {files.map((file, index) => (
                                                                    <Grid item xs={12} key={index}>
                                                                        <Typography variant="body2"> <AttachIcon sx={{ fontSize: '1.6rem' }} />{file.name}</Typography>
                                                                    </Grid>
                                                                ))}
                                                            </>
                                                        }

                                                        <Button variant="outlined" component="label" sx={{ marginTop: 2 }}>
                                                            Adjuntar Archivos
                                                            <input hidden type="file" accept={allowedExtensions} style={{ display: 'none' }} onChange={handleChangeFile} />
                                                        </Button>
                                                    </Grid>

                                                    <Grid container justifyContent="flex-end">
                                                        <LoadingButton loading={statusLoading} type='submit' variant='contained' color='primary' sx={{ marginBottom: 2 }}>
                                                            Enviar mensaje
                                                        </LoadingButton>
                                                    </Grid>

                                                </Form>

                                            )}
                                        </Formik>

                                    </AccordionDetails>
                                </Accordion>

                            </Grid>

                        </Grid>

                        {/** replies */}

                        {replies.map((reply) => (
                            <Box key={reply.id} mb={5} pb={5} sx={{ border: "1px solid #e5e5e5", px: 2 }}>
                                <Grid container mt={3} mb={3} >
                                    <Grid item md={8} >
                                        {reply.userid === 0 ?
                                            <> <ManageIcon sx={{ marginBottom: "-5px", color: "#6185fc" }} /> <Typography sx={{ fontSize: "1.35rem", fontWeight: "600", display: "inline", color: "#6185fc" }} >  {reply.admin}</Typography> </>
                                            :
                                            <> <AccountIcon sx={{ marginBottom: "-5px" }} /> <Typography sx={{ fontSize: "1.35rem", fontWeight: "600", display: "inline" }}>{reply.firstname} {reply.lastname}</Typography>  </>
                                        }

                                    </Grid>
                                    <Grid item md={4} sx={{ paddingTop: "0.6rem", paddingLeft: "0.6rem" }}>
                                        <Grid container direction="row" justifyContent="flex-end" >
                                            <Typography sx={{ fontSize: "1.25rem", color: "#5e5e5e" }}>{reply.date}</Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid container sx={{ display: "inherit" }}>
                                    <Grid item md={12}>
                                        {
                                            reply.message.split("\n").map((item, idx) => (
                                                <Typography key={idx} sx={{ fontSize: "1.33rem", }}>
                                                    {item}
                                                    <br />
                                                </Typography>
                                            ))
                                        }

                                        {reply.attachment.length !== 0 &&
                                            <>
                                                <Typography sx={{ fontSize: "1.33rem", paddingTop: "2.5rem", paddingBottom: "1rem", fontWeight: "600" }}>Adjuntos</Typography>
                                                {reply.attachment.map((attach, idx) => (
                                                    <Typography key={idx} variant="txtButtonAttach-nk" component="button" onClick={() => downloadAttachment(attach) }><AttachIcon sx={{ fontSize: "1.5rem", mr: ".5rem" }} />{attach.substring(7)}</Typography>
                                                ))}
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        ))}

                        {/**primer ticket */}

                        <Box mb={5} sx={{ border: "1px solid #e5e5e5", px: 2, pb: 2 }}>
                            <Grid container mt={3} mb={3} >
                                <Grid item md={8} >

                                    {ticket.requestor_id === 0 ?
                                        <> <ManageIcon sx={{ marginBottom: "-5px", color: "#6185fc" }} /> <Typography sx={{ fontSize: "1.35rem", fontWeight: "600", display: "inline", color: "#6185fc" }} >  {ticket.admin}</Typography> </>
                                        :
                                        <> <AccountIcon sx={{ marginBottom: "-5px" }} /> <Typography sx={{ fontSize: "1.35rem", fontWeight: "600", display: "inline" }}>{ticket.firstname} {ticket.lastname}</Typography>  </>
                                    }
                                </Grid>
                                <Grid item md={4} sx={{ paddingTop: "0.6rem", paddingLeft: "0.6rem" }}>
                                    <Grid container direction="row" justifyContent="flex-end" >
                                        <Typography sx={{ fontSize: "1.25rem", color: "#5e5e5e" }}>{ticket.date}</Typography>
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid container sx={{ display: "inherit" }}>
                                <Grid item md={12}>
                                    {
                                        ticket.message.split("\n").map((item, idx) => (
                                            <Typography key={idx} sx={{ fontSize: "1.33rem", }}>
                                                {item}
                                                <br />
                                            </Typography>
                                        ))
                                    }

                                    {ticket.attachment.length !== 0 &&
                                        <>
                                            <Typography sx={{ fontSize: "1.33rem", paddingTop: "2.5rem", paddingBottom: "1rem", fontWeight: "600" }}>Adjuntos</Typography>
                                            {ticket.attachment.map((attach, idx) => (
                                                <Typography key={idx} variant="txtButtonAttach-nk" component="button" onClick={() => downloadAttachment(attach) } ><AttachIcon sx={{ fontSize: "1.5rem", mr: ".5rem" }} />{attach.substring(7)}</Typography>
                                            ))}
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </Box>

                    </Container>
                }

            </Box>

            <DialogAttachment openDialog={openDialog} actionDialog={handleDialog} />
            <DialogDownload openDialogDown={openDialogDown} actionDialogDown={handleDialogDown} />
        </>
    )
}

export default Ticket;