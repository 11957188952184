import React from 'react';
import { Box, Chip, Link, Paper, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, TablePagination } from '@material-ui/core'
import { useNavigate } from 'react-router';
import { NavLink as RouterLink } from 'react-router-dom';

const TableInvoices = (props) => {
    const { invoices, page, limit, handlePageChange, handleLimitChange } = props;
    const navigate = useNavigate();

    return (
        <Box mt={1} mb={3} >
            <TableContainer component={Paper} variant='tbLight-nk'>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="invoices table">
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Nota Nro.</strong></TableCell>
                            <TableCell align="left"><strong>Estado</strong></TableCell>
                            <TableCell align="right"><strong>Fecha</strong></TableCell>
                            <TableCell align="right"><strong>Vencimiento</strong></TableCell>
                            <TableCell align="right"><strong>Total</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {invoices.slice(page * limit, page * limit + limit).map((invoice) => (
                            <TableRow hover key={invoice.id}>
                                <TableCell component="th" scope="row">
                                    <Link component={RouterLink} to={`/clientarea/invoices/${invoice.id}/`} >{invoice.id}</Link>
                                    {/*<Link href={`/clientarea/invoices/${invoice.id}/`}>{invoice.id}</Link> */}
                                    {/* <Link component="button" variant="body2" onClick={() => { navigate(`/clientarea/invoices/${invoice.id}/`); }} >
                                        {invoice.id}
                                    </Link> */}
                                </TableCell>
                                <TableCell align="left">
                                    {invoice.status === 'Unpaid' &&
                                        <Chip color="secondary" size="small" label={invoice.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#E3E8EE', color: '#53566B' }} />
                                    }
                                    {invoice.status === 'Paid' &&
                                        <Chip size="small" label={invoice.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#cbf4c9', color: '#268717' }} />
                                    }
                                    {invoice.status === 'Cancelled' &&
                                        <Chip size="small" label={invoice.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#F8DADB', color: '#B72136' }} />
                                    }
                                    {invoice.status === 'Refunded' &&
                                        <Chip size="small" label={invoice.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#F8DADB', color: '#B72136' }} />
                                    }
                                </TableCell>
                                <TableCell align="right">{invoice.date}</TableCell>
                                <TableCell align="right">{invoice.duedate}</TableCell>
                                <TableCell align="right">${invoice.total}USD</TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={invoices.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25]}
            />

        </Box>

    );
}

export default TableInvoices;