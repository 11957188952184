import React, { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { verifyRenewDomain } from '../../services/Domain';
import { useNavigate } from 'react-router-dom';
import { getRenewInvoiceProduct } from '../../services/Product';
import { getNewdateAddMonth, getQtyMonths } from '../../utils';
import DialogRenewProduct from './DialogRenewProduct';

/*const useStyles = makeStyles((theme) => ({
    btnRenew:{
        '&:hover':{
            backgroundColor: "#5f81ff",
            color: "#fff"
        }
    }
}));*/

const ButtonRenewProduct = (props) => {
    const {hostid, userid, nextduedate, rawnextduedate, billingcycle} =  props;
    const navigate = useNavigate();
    //const classes = useStyles();

    const handleRenewDomain = async () => {

        const invoice = await getRenewInvoiceProduct(hostid, userid);
        //console.log("invoice renew host", invoice);

        if(invoice.error === false){
            navigate(`/clientarea/invoices/${invoice.idinvoice}/`, {replace: true});
        }else{
            navigate("/clientarea/products/", {replace: true});
        }
    }

    //dialog
    const [openDialog, setOpenDialog] = useState(false);
    const [titleDialog, setTitleDialog] = useState("");
    const [contentDialog, setContentDialog] = useState("");

    const popupconfirmation = () => {
        const months = getQtyMonths(billingcycle);
        const newdate = getNewdateAddMonth(rawnextduedate, months);
        setTitleDialog("Renovar Servicio");
        setContentDialog(`¿ Desea generar una nota de débito para renovar el servicio por el periodo: ${nextduedate} hasta ${newdate} ?`);
        setOpenDialog(true);
    }

    const actionDialog = (open, val) => {
        setOpenDialog(open);

        if(val === 1){
            //console.log("Aceptooo renovación");
            handleRenewDomain();
        }
    }
    

    return (
        <>
            {/*<Button variant="outlined" size="small" onClick={popupconfirmation} className={classes.btnRenew} >Renovar</Button>*/}
            <Button variant="contained" size="small" onClick={popupconfirmation} >Renovar Servicio</Button>
            <DialogRenewProduct openDialog={openDialog} titleDialog={titleDialog} contentDialog={contentDialog} actionDialog={actionDialog} />
        </>
    );
}

export default ButtonRenewProduct;