import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Box, Button, Container, Grid, Typography, MenuItem, TextField, Tab, Divider, makeStyles } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import StepperCart from '../components/FormsUI/StepperCart'
import VerificarDominio from '../components/verify/VerificarDominio'
import axios from 'axios'
import * as Yup from 'yup'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { getDomainTlds, getProductAvailable } from '../services/Product'
import { getPublicIp, getTimeNow } from '../utils/index'
import appConfig from '../config/main.config'
import SpinnerLoad from '../components/FormsUI/Spinner'
const api = appConfig.url_api;

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down('sm')]: {
    contDomainReg: {
      padding: '0px'
    }
  }
}));

const SearchDomain = (props) => {
  //const rrr= new URLSearchParams(props.location.search).get("pid")
  //this.props.match.params.id
  //console.log('res', this.props)
  const classes = useStyles();
  let { pid, cycle } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const confopt = urlParams.get('confopt');
  const subopt = urlParams.get('subopt');
  const coupon = urlParams.get('offer');
  const typeui = urlParams.get('typeui');

  const configproduct = {
    confopt: confopt,
    subopt: subopt
  }

  const [isProduct, setIsProduct] = useState(false);
  const [dataProduct, setDataProduct] = useState(null);

  if (cycle === undefined) cycle = '';

  const [domainRegister, setDomainRegister] = useState('');
  const [domainRegStatus, setDomainRegStatus] = useState('');
  const [tldRegister, setTldRegister] = useState('.com');
  const [loadingButtonReg, setLoadingButtonReg] = useState(false);

  const [domainTransfer, setDomainTransfer] = useState('');
  const [domainTraStatus, setDomainTraStatus] = useState('');
  const [tldTransfer, setTldTransfer] = useState('.com');
  const [loadingButtonTransf, setLoadingButtonTransf] = useState(false);

  const [product, setProduct] = useState(pid);
  const [billingcycle, setBillingcycle] = useState(cycle);

  //selectDomain
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([
    { label: "Cargando...", value: ".com" }
  ])

  //tabs
  const [valueTab, setValueTab] = useState('1');
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  }


  async function handleWhoisDomain(dom, ext) {
    const ipv4 = await getPublicIp()
    const today = getTimeNow('yyyy-mm-dd H:MM:ss')
    const domain = dom + ext

    const data = {
      domain: domain,
      date: today,
      ip: ipv4
    };

    axios.post(`${api}/logs/whois/`, data)
      .then(function (response) {
        //console.log(response);
      })
      .catch(function (error) {
        //console.log(error);
      });

  }

  const handleTransferDomain = (dom, ext) => {
    const nombredominio = {
      domain: dom,
      extension: ext
    };
    const domext = dom + ext;

    axios.post(`${api}/domain/isavailable/`, nombredominio)
      .then(function (response) {
        //console.log(response);
        setDomainTransfer(domext);
        setDomainTraStatus(response.data[domext]["status"]);
        setTldTransfer(ext);
        setLoadingButtonTransf(false);

      })
      .catch(function (error) {
        //console.log(error);
      });

    /*setDomainTransfer(domext);
    setDomainTraStatus('regthroughothers');
    setTldTransfer(ext); */

  };

  const handleVerifyDomain = (dom, ext) => {
    const nombredominio = {
      domain: dom,
      extension: ext
    };
    const domext = dom + ext;

    //no olvidar cambiar
    axios.post(`${api}/domain/isavailable/`, nombredominio)
      .then(function (response) {
        //console.log(response);
        setDomainRegister(domext);
        setDomainRegStatus(response.data[domext]["status"]);
        setTldRegister(ext);
        setLoadingButtonReg(false);

      })
      .catch(function (error) {
        //console.log(error);
      });

    /*setDomainRegister(domext);
    //setDomainRegStatus('regthroughus');
    setDomainRegStatus('available');
    setTldRegister(ext);*/

  };

  //Formik

  const initialValues = {
    dominioform: '',
    extensionform: '.com'
  }
  const validationSchema = Yup.object().shape({
    dominioform: Yup.string()
      .required("Requerido")
      .matches(/^[aA-zZ0-9-]+$/, 'Únicamente se permite letras y números sin espacios')
  })
  const onSubmitReg = (values, props) => {
    const dom = values.dominioform.toLowerCase();
    const ext = values.extensionform;
    setLoadingButtonReg(true);
    handleVerifyDomain(dom, ext);
    handleWhoisDomain(dom, ext);
    //props.resetForm()
  }
  const onSubmitTransfer = (values, props) => {
    const dom = values.dominioform.toLowerCase();
    const ext = values.extensionform;
    setLoadingButtonReg(true);
    handleTransferDomain(dom, ext);
    handleWhoisDomain(dom, ext);
  }

  //Formik Domain Own
  const initialValuesOwn = {
    dominioform: '',
    //tldform: ''
  }
  const validationSchemaOwn = Yup.object().shape({
    dominioform: Yup.string()
      .required('Requerido')
      .matches(/^([a-zA-Z0-9-]+\.[a-zA-Z0-9-]+)+$/, 'Nombre de dominio inválido, verifique que no tenga espacios y la extensión sea correcta'),
    //tldform: Yup.string()
    //.required('Requerido')
    //.matches(/^[aA-zZ0-9.]+$/, 'Únicamente se permite letras y números sin espacios')
    //.max(20, 'Se acepta máximo 20 dígitos')
  })
  const onSubmitOwn = (values, props) => {
    let dom = values.dominioform.toLowerCase();
    const tld = "";
    //const tld = values.tldform;
    //const domain = dom + tld;

    //controlar si viene con www
    if (dom.substring(0, 4) === "www.") {
      const textcont = dom.length - 4;
      dom = dom.substring(dom.length - textcont);
    }

    let pathnavigate = '/cart/product/revorder/';
    const path = location.pathname;
    const pathini = path.substring(1, 10);
    if (pathini === 'cart/plan') {
      pathnavigate = '/cart/plan/revorder/';
    }

    const domain = dom;
    navigate(pathnavigate, { state: { dominio: domain, product: product, billingcycle: billingcycle, tld: tld, eventDomain: 'domainown', confopt: confopt, subopt: subopt, coupon: coupon, typeui: typeui } });

  }

  useEffect(() => {
    let unmounted = false;
    async function getTLDs() {
      //const response = await fetch("/domains/extensions/")
      //const body = await response.json()
      const body = await getDomainTlds();

      if (!unmounted) {
        //console.log("entrando a la condicional, valor "+unmounted);
        setItems(body.map((domain) => ({ label: domain.extension, value: domain.extension })))
        setLoading(false)
      }

      //const existProd = await getProductAvailable(pid);
      //console.log('salio a buscar product', existProd);

    }

    //verificarProducto existe, sino redireciona a store
    async function isProduct() {
      const product = await getProductAvailable(pid);

      //console.log('location', location.pathname);

      const confopt = urlParams.get('confopt');
      const subopt = urlParams.get('subopt');

      if (product) { //si existe, verificar si exige dominio
        //console.log('salio a buscar product', product);
        if (product.showdomain === 1) { //si necesita dominio
          setDataProduct(product);
          setIsProduct(true);
          getTLDs();
        } else {
          let pathnavigate = '/cart/product/revorder/';
          const path = location.pathname;
          const pathini = path.substring(1, 10);
          if (pathini === 'cart/plan') {
            pathnavigate = '/cart/plan/revorder/';
          }

          navigate(pathnavigate, { state: { dominio: '', product: product.id, billingcycle: billingcycle, tld: '', eventDomain: 'nodomain', confopt: confopt, subopt: subopt, coupon: coupon, typeui: typeui } })
        }

      } else { //si product no existe redirige a store
        setIsProduct(false);
        navigate('/login/')
      }

    }

    isProduct();
    //getTLDs();
    return () => {
      unmounted = true;
      setIsProduct(false);
    }
  }, [])


  if (isProduct) {
    return (
      <>
        <Helmet>
          <title>Portal - Área de Clientes de Neothek - Buscar Dominio</title>
        </Helmet>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center'
          }}
        >
          <Container maxWidth="md">
            <Grid item xs={12} md={12}>
              <Box mt={3} mb={3} >
                <Typography variant='h4'>{dataProduct.name}</Typography>
                <Divider />
              </Box>
              <StepperCart step={0} />
            </Grid>
          </Container>

          <Container maxWidth="md" variant='cont-nk'>
            <Grid container>
              {/* <Grid item xs={12} md={12}>
                <StepperCart step={0} />
              </Grid> */}

              {/* tabs */}
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={valueTab}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                      <Tab label="Registrar un nuevo dominio" value="1" sx={{ fontSize: '11px' }} />
                      <Tab label="Transferir Dominio" value="2" sx={{ fontSize: '11px' }} />
                      <Tab label="Ya tengo dominio" value="3" sx={{ fontSize: '11px' }} />
                    </TabList>
                  </Box>
                  <TabPanel value="1" className={classes.contDomainReg}>
                    {/* registrar dominio */}
                    <Box sx={{ py: 4 }} >
                      <Grid item xs={12} md={12}>
                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitReg}>
                          {(props) => (

                            <Form>
                              <Grid container spacing={2}>

                                <Grid item sm={8} xs={7}>
                                  <Field as={TextField} variant="outlined" name='dominioform' label='Dominio' fullWidth
                                    error={props.errors.dominioform && props.touched.dominioform}
                                    helperText={<ErrorMessage name='dominioform' />} required />
                                </Grid>
                                <Grid item sm={4} xs={5}>
                                  <Field as={TextField} variant="outlined" name='extensionform' label='Extensión' select fullWidth>

                                    {items.map(({ label, value }) => (
                                      <MenuItem key={value} value={value}>
                                        {label}
                                      </MenuItem>
                                    ))}

                                  </Field>

                                </Grid>

                                <Grid container justifyContent="flex-end">
                                  <LoadingButton loading={loadingButtonReg} type='submit' variant='contained' color='primary' sx={{ my: 2 }}> Comprobar </LoadingButton>
                                </Grid>

                              </Grid>
                            </Form>

                          )}
                        </Formik>

                        <VerificarDominio domainstatus={domainRegStatus} inputdomain={domainRegister} product={product} billingcycle={billingcycle} tld={tldRegister} eventDomain='domainregister' configproduct={configproduct} coupon={coupon} typeui={typeui}></VerificarDominio>
                      </Grid>
                    </Box>
                    {/* registrar dominio */}
                  </TabPanel>
                  <TabPanel value="2" className={classes.contDomainReg}>
                    {/* transferir dominio */}
                    <Box sx={{ py: 4 }}>
                      <Grid item xs={12} md={12}>
                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitTransfer}>
                          {(props) => (

                            <Form>
                              <Grid container spacing={2}>

                                <Grid item xs={8}>
                                  <Field as={TextField} variant="outlined" name='dominioform' label='Dominio' fullWidth
                                    error={props.errors.dominioform && props.touched.dominioform}
                                    helperText={<ErrorMessage name='dominioform' />} required />
                                </Grid>
                                <Grid item xs={4}>
                                  <Field as={TextField} variant="outlined" name='extensionform' label='Extensión' select fullWidth>

                                    {items.map(({ label, value }) => (
                                      <MenuItem key={value} value={value}>
                                        {label}
                                      </MenuItem>
                                    ))}

                                  </Field>

                                </Grid>

                                <Grid container justifyContent="flex-end">
                                  <LoadingButton loading={loadingButtonTransf} type='submit' variant='contained' color='primary' sx={{ my: 2 }}>
                                    Transferir
                                  </LoadingButton>
                                </Grid>

                              </Grid>
                            </Form>

                          )}
                        </Formik>

                        <VerificarDominio domainstatus={domainTraStatus} inputdomain={domainTransfer} product={product} billingcycle={billingcycle} tld={tldTransfer} eventDomain='domaintransfer' configproduct={configproduct} coupon={coupon}></VerificarDominio>
                      </Grid>
                    </Box>
                    {/* transferir dominio */}
                  </TabPanel>
                  <TabPanel value="3" className={classes.contDomainReg}>
                    {/* yatengo dominio */}
                    <Box sx={{ py: 4 }}>
                      <Grid item xs={12} md={12}>
                        <Formik initialValues={initialValuesOwn} validationSchema={validationSchemaOwn} onSubmit={onSubmitOwn}>
                          {(props) => (

                            <Form>
                              <Grid container spacing={2}>

                                <Grid item xs={12}>
                                  <Field as={TextField} variant="outlined" name='dominioform' label='Dominio' placeholder='tudominio.com' fullWidth
                                    error={props.errors.dominioform && props.touched.dominioform}
                                    helperText={<ErrorMessage name='dominioform' />} required />
                                </Grid>
                                {/*<Grid item xs={4}>
                                  <Field as={TextField} variant="outlined" name='tldform' label='Extensión' placeholder='.com' fullWidth
                                    error={props.errors.tldform && props.touched.tldform}
                                    helperText={<ErrorMessage name='tldform' />} required />
                                </Grid> */}

                                <Grid container dir="rtl">
                                  <Button type='submit' variant='contained' color='primary' sx={{ my: 2 }}>
                                    Usar
                                  </Button>
                                </Grid>

                              </Grid>
                            </Form>

                          )}
                        </Formik>

                      </Grid>
                    </Box>
                    {/* yatengo dominio */}
                  </TabPanel>
                </TabContext>
              </Box>



            </Grid>


          </Container>
        </Box>
      </>
    )
  } else {
    return (
      <SpinnerLoad />
    )
  }



}

export default SearchDomain;
