import React, { useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles';
import { Button, Box } from '@material-ui/core';
import appConfig from '../../config/main.config';
import { hashString } from '../../utils';
import SpinnerLoad from '../FormsUI/Spinner';

const ButtonPayu = styled(Button)(({ theme }) => ({
    backgroundColor: '#A6C307',
    '&:hover': {
        backgroundColor: '#97b108',
    },
}));


const PayuButton = (props) => {

    const [data, setData] = useState('');


    useEffect(() => {
        const invoiceid = props.invoice.invoiceid;
        const total = props.invoice.total;
        const userid = props.invoice.client_id;
        const emailclient = props.invoice.client_email;
        const buyerName = `${props.invoice.client_firstname} ${props.invoice.client_lastname}`;
        let accountId = '501151';

        switch (props.invoice.client_country) {
            case 'CO': {
                accountId = '501151';
                break;
            };
            case 'PE': {
                accountId = '501069';
                break;
            };
            case 'MX': {
                accountId = '501152';
                break;
            };
            default: {
                accountId = '501151';
            }
        }

        //details for generate signature
        const gwApiKey = appConfig.apikey_payu;
        const gwMerchantId = appConfig.merchantid_payu;
        const gwReferenceCode = invoiceid;
        const amount = total;
        const gwCurrency = 'USD';

        const signature = hashString(gwApiKey + '~' + gwMerchantId + '~' + gwReferenceCode + '~' + amount + '~' + gwCurrency)

        const dataSend = {
            merchantId: gwMerchantId,
            accountId: accountId, //change en production por accountId //512321 valor para demo
            description: `Neothek - Nota Nro. ${invoiceid}`,
            referenceCode: invoiceid,
            amount: total,
            tax: 0.00,
            taxReturnBase: 0.00,
            shipmentValue: 0.00,
            currency: gwCurrency,
            signature: signature,
            buyerFullName: buyerName,
            buyerEmail: emailclient,
            urlactionpayu: appConfig.url_payu,
            responseUrl: `${appConfig.url_domain}/clientarea/response-payu/${userid}/${invoiceid}`,
            confirmationUrl: `${appConfig.url_domain}/clientarea/confirmation-payu/${userid}/${invoiceid}`,
        }

        setData(dataSend);
    }, []);


    return (

        <div>

            {/*$signature=md5($gatewayapikey.'~'.$gatewaymerchantId.'~'.$gatewayreferenceCode.'~'.$amount.'~'.$gatewaycurrency);*/}

            {data ? (
                <div>
                    <form action={data.urlactionpayu} method="post">
                        <input name="merchantId" type="hidden" value={data.merchantId} />
                        <input name="accountId" type="hidden" value={data.accountId} />
                        <input name="description" type="hidden" value={data.description} />
                        <input name="referenceCode" type="hidden" value={data.referenceCode} />
                        <input name="amount" type="hidden" value={data.amount} />
                        <input name="tax" type="hidden" value={data.tax} />
                        <input name="taxReturnBase" type="hidden" value={data.taxReturnBase} />
                        <input name="shipmentValue" type="hidden" value={data.shipmentValue} />
                        <input name="currency" type="hidden" value={data.currency} />
                        <input name="signature" type="hidden" value={data.signature} />
                        <input name="buyerFullName" type="hidden" value={data.buyerFullName} />
                        <input name="buyerEmail" type="hidden" value={data.buyerEmail} />
                        <input name="responseUrl" type="hidden" value={data.responseUrl} />
                        <input name="confirmationUrl" type="hidden" value={data.confirmationUrl} />

                        <Box sx={{ px: 2 }}>
                            <ButtonPayu variant="contained" fullWidth type="submit">Pagar Ahora</ButtonPayu>
                        </Box>

                    </form>
                </div>


            ) : (
                <div>
                    <Box sx={{ px: 2 }}>
                        <SpinnerLoad />
                    </Box>
                </div>


            )}

        </div>

    )
}

export default PayuButton;