import React from 'react';
import { Grid, Typography } from '@material-ui/core'

function SuccessPayment(props) {
    return (
        <Grid>
               <Typography variant='body2' color="textSecondary" style={{ textAlign: 'center' }}>Su pago está siendo procesado, recibirá la confirmación más adelante al correo registrado.</Typography>
               <Typography color="textSecondary" style={{ textAlign: 'center' }}>GRACIAS!!</Typography>
        </Grid>
    );
}

export default SuccessPayment;