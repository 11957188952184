import React, { useRef } from 'react';
import Radio from '@material-ui/core/Radio';
import DateRangeIcon from '@material-ui/icons/DateRange';
import UncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckedIcon from '@material-ui/icons/CheckCircle';
import { IconButton, makeStyles, Typography } from '@material-ui/core'
import { calculateDiscount } from '../../services/Promotion';

const useStyles = makeStyles((theme) => ({
    btnRadio:{
        marginLeft: "-120%",
        marginTop: "-10%"
    },
    popSave: {
        backgroundColor: '#dadce0', borderRadius: '15px', marginTop: '-15px', fontWeight: 600, fontSize: '15px', padding: '4px 15px'
    },
    txtTachado: {
        textDecoration: 'line-through', color: '#e78ddc', marginTop: '10px'
    },
    labelRenov: {
        marginTop: "19%"
    },
    [theme.breakpoints.down(1400)]: {
        btnRadio:{
            marginLeft: "-110%"
        }
    },
    [theme.breakpoints.down('md')]: {
        btnRadio:{
            marginLeft: "-125%"
        }
    },
    [theme.breakpoints.down('sm')]: {
        btnRadio:{
            marginLeft: "-109%",
            marginTop: "-8%"
        },
        txtTachado:{
            marginTop: "0px"
        },
        labelRenov: {
            marginTop: "3%"
        }
    },
}));

const CheckIcon = () => {
    return (
        <>
            <IconButton aria-label="date-range" size="small" sx={{ backgroundColor: '#5F81FF', color: '#fff' }}>
                <CheckedIcon sx={{ fontSize: '0.9em' }} />
            </IconButton>
        </>
    )
};

const UncheckIcon = () => {
    return (
        <>
            <IconButton aria-label="date-range" size="small" sx={{ backgroundColor: '#DADCE0', color: '#fff' }}>
                <UncheckedIcon sx={{ fontSize: '0.9em' }} />
            </IconButton>
        </>
    )
};

export default function CycleRadioExpress(props) {
    const calCycle = useRef();
    const classes = useStyles();

    const pricetot = props.cycleproducts[0].pricerenewmonth * props.qtymonths;
    const difference = (props.price * 100) / pricetot; //porcentaje
    let percentdiscount = Math.round(100 - difference);

    const period = props.period;

    //const pricewithcoupon = ((props.price - props.discount)/props.qtymonths).toFixed(2);

    const typeui = props.typeui;
    let pricerenewmonth = props.pricerenewmonth;

    let pricemonthnow = props.pricerenewmonth;
    let priceplan = props.price;

    //console.log('pricerenew', props);

    let save = (pricetot - priceplan).toFixed(2);

    if (props.promotion.coupon) {

        if (props.promotion.coupon.value !== 0) { //en caso de tener cupon con valor, No 0
            const discountplan = calculateDiscount(props.promotion.coupon, props.promotion.idProduct, props.price, props.periodeng); //descuento precio

            //console.log('discount', discountplan, props.qtymonths);

            priceplan = (props.price - discountplan).toFixed(2);

            if(typeui === 'annual'){
                pricemonthnow = ((props.price - discountplan)).toFixed(2);
                pricerenewmonth = (props.price).toFixed(2);
            }else{ //mostrar precios mensuales
                pricemonthnow = ((props.price - discountplan) / props.qtymonths).toFixed(2);
            }
            
            //porcentaje de descuento más cupón
            const percentcoupon = (priceplan * 100) / pricetot;
            percentdiscount = Math.round(100 - percentcoupon);

            //save
            save = (pricetot - priceplan).toFixed(2);
        } else { //cupon especial
            //buscar precio de renovación en copia de ccyleproducts, en caso de cupones especiales considerar los valores originales
            const regpricerenov = props.novariantcycleproducts.find(element => element.qtymonths === props.qtymonths);
            pricerenewmonth = regpricerenov.pricerenewmonth;

            //console.log('price renew month', pricerenewmonth);
        }

    }else{
        if(typeui === 'annual'){
            pricemonthnow = (props.price).toFixed(2);
            pricerenewmonth = (props.price).toFixed(2);
        }
    }

    const handleAtt = () => {
        const price = calCycle.current.attributes.price.value;
        const cycle = calCycle.current.attributes.periodeng.value

        props.onClick(price, cycle, percentdiscount)
    }


    return (
        <>
            {
                percentdiscount === 0 ?
                    <Typography className={classes.popSave} variant='txtDiscount-nk' >SETUP FREE</Typography>
                    :
                    <Typography className={classes.popSave} variant='txtDiscount-nk' >AHORRA {percentdiscount}%</Typography>
            }

            <Radio
                sx={{
                    '&:hover': {
                        bgcolor: 'transparent',
                    }
                }}
                disableRipple
                color="default"
                checkedIcon={<CheckIcon />}
                icon={<UncheckIcon />}
                {...props}
                ref={calCycle}
                onClick={handleAtt}
                className={classes.btnRadio}
            />

            <Typography variant='body2' sx={{ marginTop: "-5%", marginBottom: "4%" }}><strong>{props.qtymonths} mes(es)</strong></Typography>

            {props.promotion.coupon && props.promotion.coupon.value === 0 ?
                <>
                    {props.index === 0 ? <Typography sx={{ color: '#fff' }}>props.index</Typography> : <Typography variant='body2' className={classes.txtTachado}>{props.cycleproducts[0].pricerenewmonth}/mes</Typography>}

                </>
                :
                <Typography variant='body2' className={classes.txtTachado}>{props.cycleproducts[0].pricerenewmonth}/mes</Typography>
            }

            {/**para moviles */}
            {typeui === 'annual' ?
                <>
                {props.promotion.coupon && props.promotion.coupon.value === 0 ? 
                    <Typography sx={{ fontSize: "1.7em", letterSpacing: "1px", fontWeight: "bold", display: { xs: 'inline', sm: 'none'} }}> $ {pricemonthnow} <span style={{fontSize: "13px", textShadow: "none", color: "#858585"}}>USD/mes</span> </Typography>
                : 
                    <Typography sx={{ fontSize: "1.7em", letterSpacing: "1px", fontWeight: "bold", display: { xs: 'inline', sm: 'none'} }}> $ {pricemonthnow} <span style={{fontSize: "13px", textShadow: "none", color: "#858585"}}>USD</span> </Typography>
                }

                </>
                :
                <Typography sx={{ fontSize: "1.7em", letterSpacing: "1px", fontWeight: "bold", display: { xs: 'inline', sm: 'none'} }}> $ {pricemonthnow} <span style={{fontSize: "13px", textShadow: "none", color: "#858585"}}>USD/mes</span> </Typography>
            }
            
            {/**para equipos */}
            <Typography sx={{ fontSize: "3em", letterSpacing: "1px", fontWeight: "bold", marginLeft: "-17%", display: { xs: 'none', sm: 'block'} }}> $ {pricemonthnow.split('.')[0]} <span style={{fontSize:"2.5rem", position: "absolute", marginTop: "0.5rem"}} >{pricemonthnow.split('.')[1]}</span> </Typography>

            {/*<Typography color="textSecondary" sx={{ fontSize: "1em", marginTop: "-5%", display: { xs: 'none', sm: 'block'} }} > <strong>USD / mes</strong></Typography>
            <Typography variant='body2' color="textSecondary" sx={{ marginTop: "19%" }} className={classes.labelRenov}>Renovación ${pricerenewmonth}/mes </Typography>*/}

            {typeui === 'annual' ?
                <>
                {props.promotion.coupon && props.promotion.coupon.value === 0 ? 
                    <>
                        <Typography color="textSecondary" sx={{ fontSize: "1em", marginTop: "-5%", display: { xs: 'none', sm: 'block'} }} > <strong>USD / mes</strong></Typography>
                        <Typography variant='body2' color="textSecondary" sx={{ marginTop: "19%" }} className={classes.labelRenov}>Renovación ${pricerenewmonth}/mes</Typography>
                    </>
                    
                :
                    <>
                        <Typography color="textSecondary" sx={{ fontSize: "1em", marginTop: "-5%", display: { xs: 'none', sm: 'block'} }} > <strong>USD / {period}</strong></Typography>
                        <Typography variant='body2' color="textSecondary" sx={{ marginTop: "19%" }} className={classes.labelRenov}>Renovación ${pricerenewmonth} </Typography>
                    </>
                    
                }
                </>   
            :
                <>
                    <Typography color="textSecondary" sx={{ fontSize: "1em", marginTop: "-5%", display: { xs: 'none', sm: 'block'} }} > <strong>USD / mes</strong></Typography>
                    <Typography variant='body2' color="textSecondary" sx={{ marginTop: "19%" }} className={classes.labelRenov}>Renovación ${pricerenewmonth}/mes </Typography>
                </>
                
            }
            

            {/*<Typography variant='body2' color="textSecondary">Ahorras: ${save}/mes </Typography>
            <Typography>Subtotal: US$ {priceplan}</Typography> */}

        </>

    );
}