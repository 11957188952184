import { Box, Button, Chip, Container, Grid, Link, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import WorldIcon from '@mui/icons-material/Public';
import ExtensionIcon from '@mui/icons-material/Extension';
import { getDetailProduct } from '../../services/Product';
import SpinnerLoad from '../../components/FormsUI/Spinner';
import ButtonCpanel from '../CPanel/ButtonCpanel';
import ButtonRenewProduct from './ButtonRenewProduct';
import { NavLink as RouterLink } from 'react-router-dom';

const Product = (props) => {
    const navigate = useNavigate();
    const [product, setProduct] = useState(null);
    let { pid } = useParams();

    const handleDomainRenew = () => {
        navigate('/clientarea/domains/', { replace: true });
    }

    useEffect(() => {
        const getDetailHost = async () => {
            const fullhost = await getDetailProduct(pid);
            //console.log("fullhost", fullhost);
            setProduct(fullhost);
        };

        getDetailHost();
    }, []);


    return (
        <>
            <Helmet>
                <title>Portal Neothek - Área de clientes de Neothek</title>
            </Helmet>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center'
                }}
            >

                {product ?
                    <>
                        {product.error === false ?
                            <>
                                <Container maxWidth="lg" sx={{ pb: '5px', mt: 2 }}>
                                    <Link component={RouterLink} to={`/`} >{'Portal'}</Link> <span style={{ color: '#5f81ff' }}>{'  >  '}</span>
                                    <Link component={RouterLink} to={`/clientarea/products/`} >{'Servicios'}</Link> <span style={{ color: '#5f81ff' }}>{'  >  '}</span>
                                    <Link sx={{ textDecoration: 'none', color: '#23527c' }}>{product.name} {product.domain !== '' && <span> - {product.domain}</span>}</Link>
                                </Container>

                                <Container maxWidth="lg" variant='cont-nk'>
                                    <Grid container sx={{ backgroundColor: '#f8f8f8', mt: 3, mb: 3, pb: 2, px: 2, borderRadius: "10px" }} spacing={2} >
                                        <Grid item xs={12} sx={{ borderBottom: "1px solid #dfdfdf", pb: 3, mb: 1 }}>
                                            <Typography variant='h4' sx={{ display: 'inline', pr: 1 }}>{product.name} {product.domain !== '' && <span> - {product.domain}</span>} </Typography>
                                            {product.status === 'Active' ?
                                                <Chip size="small" label={product.status} sx={{ borderRadius: 1, marginTop: '-4px', height: '20px', backgroundColor: '#cbf4c9', color: '#268717' }} />
                                                :
                                                <>
                                                    {product.status === 'Pending' ?
                                                        <Chip color="secondary" size="small" label={product.status} sx={{ borderRadius: 1, marginTop: '-4px', height: '20px', backgroundColor: '#E3E8EE', color: '#53566B' }} />
                                                        :
                                                        <Chip size="small" label={product.status} sx={{ borderRadius: 1, marginTop: '-4px', height: '20px', backgroundColor: '#F8DADB', color: '#B72136' }} />
                                                    }
                                                </>
                                            }
                                        </Grid>

                                        <Grid item xs={12} sm={6} >
                                            {product.detailconfighost &&
                                                <Typography sx={{ fontSize: "1.35rem" }} color="colornk.grey4">
                                                    {product.detailconfighost.map((config, index) => (
                                                        <span key={index} style={{ fontWeight: "600" }}>{config}<br></br></span>
                                                    ))}
                                                </Typography>
                                            }
                                            <Typography sx={{ fontSize: "1.35rem" }} color="colornk.grey4"><span style={{ fontWeight: "600" }}>Vencimiento:</span> {product.nextduedate} </Typography>
                                            <Typography sx={{ fontSize: "1.35rem" }} color="colornk.grey4"><span style={{ fontWeight: "600" }}>Precio:</span> {product.billingcycle === 'One Time' ? <> ${product.firstpayment}USD </> : <> ${product.amount}USD </>} </Typography>
                                            <Typography sx={{ fontSize: "1.35rem" }} color="colornk.grey4"><span style={{ fontWeight: "600" }}>Ciclo:</span> {product.billingcycle} </Typography>

                                        </Grid>

                                        {((product.status === 'Active' || product.status === 'Suspended') && (product.billingcycle !== 'One Time' && product.billingcycle !== 'Free Account')) &&
                                            <Grid item xs={12} sm={6} >
                                                <Grid container justifyContent="flex-end">
                                                    {/*<Button variant="contained">Renovar Servicio</Button>*/}
                                                    <ButtonRenewProduct hostid={product.id} userid={product.userid} nextduedate={product.nextduedate} rawnextduedate={product.rawnextduedate} billingcycle={product.billingcycle} />
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>

                                    {(product.type === "hostingaccount" && product.status === 'Active') &&
                                        <Grid container sx={{ border: "1px solid #dadce0", borderRadius: "5px", py: 3, px: 2, marginBottom: "3rem" }}>
                                            <Grid item sm={6} xs={12} sx={{ pt: 3 }}>
                                                <Typography sx={{ fontSize: "1.45rem", paddingTop: ".1rem", fontWeight: "500" }}>Acceso cPanel</Typography>
                                                <Typography color="textSecondary" sx={{ fontSize: "1.33rem", paddingTop: ".6rem" }}>Para tener acceso a esta opción necesita tener habilitada la Verificación en Dos Pasos.</Typography>
                                            </Grid>
                                            <Grid item sm={6} xs={12} sx={{ pt: 3 }}>
                                                <Grid container justifyContent="flex-end">
                                                    {/*<Button variant="contained" onClick={handleAccesscPanel} sx={{ backgroundColor: "#F27121", "&:hover": { backgroundColor: "#e06214" } }} >  Acceder cPanel </Button> */}
                                                    <ButtonCpanel idhost={product.id} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }

                                    <Grid container spacing={3} pb={4}>
                                        {//(product.type === 'hostingaccount' || product.type === 'reselleraccount') &&
                                            (product.domain !== '') &&
                                            <>
                                                {product.detaildomain &&
                                                    <Grid item md={4} sm={6} xs={12} >
                                                        <Box sx={{ border: "1px solid #dadce0", borderRadius: "8px", px: 2, py: 2 }}>
                                                            <WorldIcon sx={{ mb: 4, fontSize: "3rem", color: "#1ecaee" }} />
                                                            <Typography color="gray.cust1Nk" sx={{ fontWeight: "600", pb: 2 }}>Dominio</Typography>

                                                            <Grid sx={{ pb: 3 }}>
                                                                <Typography sx={{ fontSize: "1.35rem" }} color="colornk.grey4"><span style={{ fontWeight: "600" }}>Nombre Dominio:</span> {product.detaildomain.name} </Typography>
                                                                <Typography sx={{ fontSize: "1.35rem" }} color="colornk.grey4"><span style={{ fontWeight: "600" }}>Precio:</span> {`$ ${product.detaildomain.recurringamount} USD (${product.detaildomain.registrationperiod} /años)`} </Typography>
                                                                <Typography sx={{ fontSize: "1.35rem" }} color="colornk.grey4"><span style={{ fontWeight: "600" }}>Vencimiento:</span> {product.detaildomain.expirydate}</Typography>
                                                            </Grid>

                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} sm={4}>
                                                                    {product.detaildomain.status === 'Active' ?
                                                                        <Chip size="small" label={product.detaildomain.status} sx={{ borderRadius: 1, marginTop: '-4px', height: '20px', backgroundColor: '#cbf4c9', color: '#268717' }} />
                                                                        :
                                                                        <>
                                                                            {product.detaildomain.status === 'Pending' ?
                                                                                <Chip color="secondary" size="small" label={product.detaildomain.status} sx={{ borderRadius: 1, marginTop: '-4px', height: '20px', backgroundColor: '#E3E8EE', color: '#53566B' }} />
                                                                                :
                                                                                <Chip size="small" label={product.detaildomain.status} sx={{ borderRadius: 1, marginTop: '-4px', height: '20px', backgroundColor: '#F8DADB', color: '#B72136' }} />
                                                                            }
                                                                        </>
                                                                    }
                                                                </Grid>
                                                                {product.detaildomain.status !== 'Pending' &&
                                                                    <Grid item xs={12} sm={8} >
                                                                        <Grid container justifyContent="flex-end">
                                                                            <Button onClick={handleDomainRenew} variant="outlined" sx={{ padding: "3px 15px" }}>Renovar Dominio</Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                }

                                                            </Grid>


                                                        </Box>
                                                    </Grid>
                                                }
                                            </>
                                        }

                                        {product.addons.map((addon, index) => (
                                            <Grid key={index} item md={4} sm={6} xs={12} >
                                                <Box sx={{ border: "1px solid #dadce0", borderRadius: "8px", px: 2, py: 2 }}>
                                                    <ExtensionIcon sx={{ mb: 4, fontSize: "3rem", color: "#9380ff" }} />
                                                    <Typography color="gray.cust1Nk" sx={{ fontWeight: "600", pb: 2 }}>Complemento</Typography>

                                                    <Grid sx={{ pb: 3 }}>
                                                        <Typography sx={{ fontSize: "1.35rem" }} color="colornk.grey4"><span style={{ fontWeight: "600" }}>Nombre:</span> {addon.name} </Typography>
                                                        <Typography sx={{ fontSize: "1.35rem" }} color="colornk.grey4"><span style={{ fontWeight: "600" }}>Precio:</span> $ {addon.amount} USD ({addon.billingcycle})</Typography>
                                                        <Typography sx={{ fontSize: "1.35rem" }} color="colornk.grey4"><span style={{ fontWeight: "600" }}>Vencimiento:</span>
                                                            {(addon.billingcycle === 'One Time' || addon.billingcycle === 'Free Account') ? <> - </> : <> {addon.nextduedate}</>}
                                                        </Typography>
                                                    </Grid>

                                                    {addon.status === 'Active' ?
                                                        <Chip size="small" label={addon.status} sx={{ borderRadius: 1, marginTop: '-4px', height: '20px', backgroundColor: '#cbf4c9', color: '#268717' }} />
                                                        :
                                                        <>
                                                            {addon.status === 'Pending' ?
                                                                <Chip color="secondary" size="small" label={addon.status} sx={{ borderRadius: 1, marginTop: '-4px', height: '20px', backgroundColor: '#E3E8EE', color: '#53566B' }} />
                                                                :
                                                                <Chip size="small" label={addon.status} sx={{ borderRadius: 1, marginTop: '-4px', height: '20px', backgroundColor: '#F8DADB', color: '#B72136' }} />
                                                            }
                                                        </>
                                                    }
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>

                                </Container>

                            </>

                            :
                            <Container maxWidth="sm" variant='cont-nk'>

                                <Box sx={{ mb: 2, mt: 3 }}>
                                    <Typography
                                        color="textPrimary"
                                        variant="h2"
                                        align="center"
                                    >
                                        Lo sentimos,
                                    </Typography>

                                </Box>

                                <Box sx={{ pt: 1, pb: 2 }}>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="body1"
                                        align="center"
                                    >
                                        El producto no se encuentra disponible entre sus servicios.
                                    </Typography>
                                </Box>

                            </Container>
                        }
                    </>
                    :
                    <SpinnerLoad />
                }

            </Box>

        </>
    );
}

export default Product;