import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const DialogChangePass = (props) => {

    const { statusdialog } = props;
    const navigate = useNavigate();

    /*const [openDialog, setOpenDialog] = useState(statusdialog);
    const [titleDialog, setTitleDialog] = useState('Error');
    const [contentDialog, setContentDialog] = useState('Ha existido una falla en el cambio de contraseña, por favor intente nuevamente.');
    const [styleDialog, setStyleDialog] = useState('error'); */

    const openDialog = statusdialog;
    const titleDialog = 'Error';
    const contentDialog = 'Ha existido una falla en el cambio de contraseña, por favor intente nuevamente.';
    const styleDialog = 'error';

    const handleCloseDialog = () => {
        //setOpenDialog(false);
        navigate('/clientarea/account/', { replace: true });
    };

    return (

        <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle variant="dialogTitle-nk" id="alert-dialog-title" color={styleDialog}>
                {titleDialog}
            </DialogTitle>
            <DialogContent>
                <Typography color={styleDialog}>{contentDialog}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} autoFocus variant='outlined' size="small">
                    Entendido
                </Button>
            </DialogActions>

        </Dialog>

    );
}

export default DialogChangePass;