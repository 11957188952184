import { Box, Container, Divider, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup'
import { LoadingButton } from '@mui/lab';
import { getOptionsTicket, openTicket } from '../../services/Ticket';
import SpinnerLoad from '../../components/FormsUI/Spinner';
import { useNavigate } from 'react-router-dom';
import { getPublicIp } from '../../utils';
import { Button } from '@mui/material';
import AttachIcon from '@material-ui/icons/AttachFile';
import DialogAttachment from './DialogAttachment';

const OpenTicket = (props) => {

    const [statusLoading, setStatusLoading] = useState(false);
    const [optionsticket, setOptionsticket] = useState(null);
    const [allowedExtensions, setAllowedExtensions] =  useState("*");
    //attachments
    const [files, setFiles] = useState([]);
    const [isSelectedFile, setIsSelectedFile] = useState(false);

    //dialog
    const [openDialog, setOpenDialog] = useState(false);

    const navigate = useNavigate();

    const initialValues = {
        subject: '',
        deptid: 1,
        urgency: 'Medium',
        message: '',
    }
    const validationSchema = Yup.object().shape({
        subject: Yup.string()
            .required("Debe escribir el asunto de mensaje"),
        message: Yup.string()
            .required("Debe escribir un mensaje")
    })

    const sendMessage = async (values) => {

        setStatusLoading(true);
        const ip = await getPublicIp();
        values["ip"] = ip;
        const ticket = await openTicket(values, files);

        if (ticket.error === false) {
            navigate(`/clientarea/tickets/${ticket.tid}/c/${ticket.c}/`, { replace: true });
        } else {
            navigate('/clientarea/tickets/', { replace: true });
        }
    }

    //attachments
    const handleChangeFile = (event) => {

        if (event.target.files.length !== 0) {
            //verificar extension de archivo
            const segmentsfilename = event.target.files[0].name.split(".");
            const extension = segmentsfilename[segmentsfilename.length - 1]

            const found = optionsticket.allowedFiles.find(element => (element.substring(1)) === extension);

            if (found) {
                setFiles([...files, event.target.files[0]]);
                setIsSelectedFile(true);
            } else {
                //error de extension
                setOpenDialog(true);
            }
        }

    }

    //dialog
    const handleDialog = (status) => {
        setOpenDialog(status);
    }

    /*const uploadAttachments = async () => {
        const upfiles = await uploadFiles(files);

        console.log("upfiles", upfiles);
    } */

    useEffect(() => {
        const getOptions = async () => {
            const options = await getOptionsTicket();

            if (options.error === false) {
                setOptionsticket(options);

                if(options.allowedFiles.length !== 0){
                    setAllowedExtensions(options.allowedFiles.toString());
                }
            }
        }

        getOptions();

    }, [])

    return (
        <>
            <Helmet>
                <title>Portal - Área de clientes de Neothek</title>
            </Helmet>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center'
                }}
            >

                <Container maxWidth="lg" variant='cont-nk'>

                    < Box >
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <Box mt={3} mb={3} >
                                    <Typography variant='h4'>Abrir Ticket</Typography>
                                    <Divider />
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>

                    {optionsticket === null ?
                        <SpinnerLoad />
                        :
                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={sendMessage}>
                            {(props) => (

                                <Form>
                                    <Grid container spacing={2} sx={{ pt: 4 }}>
                                        <Grid item xs={12}>
                                            <Field as={TextField} variant="outlined" name='subject' label='Asunto' fullWidth
                                                error={props.errors.subject && props.touched.subject}
                                                helperText={<ErrorMessage name='subject' />} required />
                                        </Grid>

                                        <Grid item sm={6} xs={12}>
                                            <Field as={TextField} variant="outlined" name='deptid' label='Departamento' select fullWidth>
                                                {optionsticket.departments.map(({ id, name }) => (
                                                    <MenuItem key={id} value={id}>
                                                        {name}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </Grid>

                                        <Grid item sm={6} xs={12}>
                                            <Field as={TextField} variant="outlined" name='urgency' label='Prioridad' select fullWidth>
                                                {optionsticket.urgencies.map(({ value }, index) => (
                                                    <MenuItem key={index} value={value}>
                                                        {value}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Field as={TextField} variant="outlined" name='message' label='Mensaje' multiline rows={6} fullWidth
                                                error={props.errors.message && props.touched.message}
                                                helperText={<ErrorMessage name='message' />} />
                                        </Grid>

                                    </Grid>

                                    <Grid container mt={1}>
                                        {isSelectedFile === true &&
                                            <>
                                                {files.map((file, index) => (
                                                    <Grid item xs={12} key={index}>
                                                        <Typography variant="body2"> <AttachIcon sx={{ fontSize: '1.6rem' }} />{file.name}</Typography>
                                                    </Grid>
                                                ))}
                                            </>
                                        }

                                        <Button variant="outlined" component="label" sx={{ marginTop: 2 }}>
                                            Adjuntar Archivos
                                            <input hidden type="file" accept={allowedExtensions} style={{ display: 'none' }} onChange={handleChangeFile} />
                                        </Button>

                                        {/*<Button onClick={uploadAttachments}>Subir Archivos</Button>*/}
                                    </Grid>

                                    <Grid container justifyContent="flex-end" sx={{ pb: 4 }}>
                                        <LoadingButton loading={statusLoading} type='submit' variant='contained' color='primary' sx={{ marginBottom: 2 }}>
                                            Enviar mensaje
                                        </LoadingButton>
                                    </Grid>

                                </Form>

                            )}
                        </Formik>
                    }

                </Container>

            </Box>

            <DialogAttachment openDialog={openDialog} actionDialog={handleDialog} />

        </>
    )
}

export default OpenTicket;