import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';

const DialogDownload = (props) => {

    //const openDialog = props.openDialog;
    const openDialog = props.openDialogDown;

    const handleCloseDialog = () => {
        props.actionDialogDown(false);
    };

    return (
        <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle variant="dialogTitle-nk" id="alert-dialog-title" color="error">
                Error
            </DialogTitle>
            <DialogContent>
                <Typography color="error">El archivo que trata de descargar no se encuentra disponible, por favor contacte con soporte.</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} autoFocus variant='outlined' size="small">
                    Entendido
                </Button>
            </DialogActions>

        </Dialog>
    );
}

export default DialogDownload;