import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function RoutesNavigation() {
    const location = useLocation();
    const navigate = useNavigate();

    //console.log('pathname', location.pathname);

    useEffect(() => {
        const redirectTo = location.pathname;
        navigate('/login/', {state: {redirectTo: redirectTo}});
    }, []);

    return (
        <div>
            
        </div>
    );
}

export default RoutesNavigation;