import React, {useRef, useState} from 'react'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Container, Link, TextField, Typography } from '@material-ui/core';
import ReCAPTCHA from "react-google-recaptcha";
import { authUser } from '../../services/User';
import { saveAuth } from '../../hooks/useAuth';
import appConfig from '../../config/main.config';
import LoadingButton from '@mui/lab/LoadingButton';
import { getPublicIp } from '../../utils';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const captcha = useRef(null);
  const [loadBut, setLoadBut] = useState(false);

  const [validCaptcha, setValidCaptcha] = useState(null);
  const [errValidUser, setErrValidUser] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const onChangeCaptcha = () =>{
    if(captcha.current.getValue()){
      setValidCaptcha(true);
      setButtonDisabled(false);
    }else{
      setValidCaptcha(false)
      setButtonDisabled(true);
    }
  }


  return (
    <>
      <Helmet>
        <title>Portal Neothek - Área de clientes de Neothek</title>
      </Helmet>
      <Box
        sx={{
          /*backgroundColor: 'background.default',*/
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm" variant='cont-nk' sx={{mt: "3rem"}}>
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('E-mail inválido').max(255).required('Email es requerido'),
              password: Yup.string().max(255).required('Contraseña es requerida')
            })}

            onSubmit={async (val, actions) => {
              setLoadBut(true);

              const ip = await getPublicIp();
              const res = await authUser(val.email, val.password, ip);
              //console.log("res login", res);

              if(res.error === false){
                setLoadBut(false);
                setErrValidUser(null);

                if(res.active2fa !== 1){

                  if(res.accounts === 1){
                    saveAuth(res);

                    if(location.state && location.state.redirectTo){
                      navigate(location.state.redirectTo, { replace: true });
                    }else{
                      navigate('/clientarea/dashboard/', { replace: true });
                    }

                  }else{
                    navigate('/login/accounts/', {state: {accounts: res.accountsdata, uid: res.uid, language: res.language}});
                  }

                }else{ //con auth 2FA
                  if(location.state && location.state.redirectTo){
                    navigate('/login/2fa/', { state: {redirectTo: location.state.redirectTo, id2fa: res.id2fa, active2fa: res.active2fa} });
                  }else{
                    navigate('/login/2fa/', { state: {id2fa: res.id2fa, active2fa: res.active2fa} });
                  }
                }

              }else{
                actions.setSubmitting(false);
                setErrValidUser("Usuario y/o contraseña incorrecta");
                setLoadBut(false);
                setButtonDisabled(true);

                actions.resetForm();
                captcha.current.reset();
              }      
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              //isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 2, mt: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                    align="center"
                  >
                    Login
                  </Typography>
                  
                </Box>

                <Box sx={{pt: 1, pb: 2}}>
                    <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="body1"
                        align="center"
                    >
                        Ingresar al Área de Clientes
                    </Typography>
                </Box>

              {errValidUser &&
                <Box>
                  <Typography sx={{color: 'red'}} variant="h6">
                    {errValidUser}
                  </Typography>
                </Box>
              }
                
               
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Correo electrónico"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                  sx={{marginBottom: '16px'}}
                />

                <Typography
                  color="textSecondary"
                  variant="body1"
                  align='right'
                  sx={{fontWeight: '600'}}
                >
                  <Link
                    component={RouterLink}
                    to="/reset/"
                    variant="h6"
                  >
                    ¿No recuerdas la contraseña?
                  </Link>
                </Typography>

                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Introduce tu contraseña"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                  sx={{marginTop: '8px'}}
                />

                <Box align='center' sx={{py: 2}}>
                  <ReCAPTCHA
                    ref={captcha}
                    sitekey={appConfig.sitekey_captcha}
                    onChange={onChangeCaptcha}
                  />
                 {validCaptcha === false && 
                    <Typography sx={{color: 'red'}} variant="h6">
                      Por favor acepte el capctha
                    </Typography>
                 }
                  
                </Box>

                <Box sx={{ py: 3 }}>
                  <LoadingButton color="primary" disabled={buttonDisabled} fullWidth size="large" type="submit" variant="contained" loading={loadBut}>Ingresar</LoadingButton>
                </Box>
                <Box sx={{paddingBottom: 3}}>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  ¿No tienes una cuenta?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/register/"
                    variant="h6"
                  >
                    Registrarse
                  </Link>
                </Typography>
                </Box>
                
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
