import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SpinnerLoad from '../FormsUI/Spinner';
import CheckoutForm from './StripeCheckoutFormElement';
import appConfig from '../../config/main.config';
import { useLocation } from 'react-router-dom';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(appConfig.pk_stripe);
/*const stripePromise = loadStripe(appConfig.pk_stripe, {   
    betas: ['link_beta_2'],
    apiVersion: '2020-08-27;link_beta=v1',
}); */

function StripePaymentElements() {
    const location = useLocation();

    let client_secret = null;
    let client_email = "";
    let client_firstname = "";
    let client_lastname = "";
    let client_country = "";
    let total = 0.00;
    let invoiceid = null;
    let client_fullname = "";
    
    if(location.state && location.state.payData.clientSecret){
            client_secret = location.state.payData.clientSecret;
            client_email = location.state.payData.client_email;
            client_firstname = location.state.payData.client_firstname;
            client_lastname = location.state.payData.client_lastname;
            client_country = location.state.payData.client_country;
            total = location.state.payData.total;
            invoiceid = location.state.payData.invoiceid;
            client_fullname = `${client_firstname} ${client_lastname}`;
    }

    const options = {
        clientSecret: client_secret,
        appearance: {
            theme: 'flat'
        },
        loader: 'auto'
    }

    return (
        <div>
            {client_secret === null && client_email === "" ?
                <SpinnerLoad />
                :
                <Elements stripe={stripePromise} options={options}>
                    <CheckoutForm clientEmail={client_email} clientFullname={client_fullname} clientCountry={client_country} invoiceid={invoiceid} total={total}/>
                </Elements>
            }
        </div>
    );
}

export default StripePaymentElements;