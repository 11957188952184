import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Checkbox, Divider, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { verifyCodeAuth2fa} from '../../services/User';
import { saveAuth } from '../../hooks/useAuth';
import { getPublicIp } from '../../utils';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    contLogin: {
        padding: "0 6rem"
    },
    [theme.breakpoints.down('sm')]: {
      contLogin: {
        padding: "0rem"
      }
    },
}));

function Login2FA() {
    const location = useLocation();
    const navigate = useNavigate();
    const classes = useStyles();

    let active2fa = null;
    let id2fa = null;

    if (location.state && location.state.active2fa && location.state.id2fa) {
        active2fa = location.state.active2fa;
        id2fa = location.state.id2fa;
    }
    const [errValidCode, setErrValidCode] = useState(null);
    const [loadingCode, setLoadingCode] = useState(false);

    const initialValues = {
        codeauth: '',
        remember: false
    };
    const validationSchema = Yup.object().shape({
        codeauth: Yup.string()
            .matches(/^[0-9]+$/, 'Sólo se permite números')
            .required("Código es requerido")
    });

    const confirmCode = async (val, actions) => {
        //console.log("values code", val, id2fa);

        const ip = await getPublicIp();
        const iscode = await verifyCodeAuth2fa(id2fa, val.codeauth, ip, val.remember);

        //console.log("iscode", iscode);

        if (iscode.error === false) {
            if (iscode.verify === true) {
                if (iscode.accounts === 1) {
                    saveAuth(iscode);

                    if (location.state && location.state.redirectTo) {
                        navigate(location.state.redirectTo, { replace: true });
                    } else {
                        navigate('/clientarea/dashboard/', { replace: true });
                    }
                } else {
                    navigate('/login/accounts/', {state: {accounts: iscode.accountsdata, uid: iscode.uid, language: iscode.language}});
                }

            } else {
                actions.resetForm();
                setErrValidCode("Código inválido");
                setLoadingCode(false);
            }
        } else {
            actions.resetForm();
            setErrValidCode("Código inválido");
            setLoadingCode(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>Portal - Área de clientes de Neothek</title>
            </Helmet>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center'
                }}
            >

                {active2fa === 1 ?

                    <Container maxWidth="sm" variant='cont-nk' sx={{ mt: "3rem" }}>
                        <Grid container spacing={2} className={classes.contLogin} >
                            <Grid item xs={12}>
                                <Box mt={3} mb={3} >
                                    <Typography variant='h4' sx={{ textAlign: "center" }}>Verificación en 2 Pasos</Typography>
                                    <Divider />
                                </Box>
                            </Grid>

                            <Grid item xs={12} sx={{ textAlign: "center" }}>
                                <SecurityIcon color="primary" sx={{ fontSize: "8rem" }} />
                            </Grid>

                            <Grid item xs={12} >
                                <Typography sx={{ fontSize: "1.40rem" }}>Para continuar, ingrese el código de verificación de 6 dígitos generado por su aplicación de autenticación.</Typography>

                                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={confirmCode}>
                                    {(props) => (

                                        <Form>
                                            <Grid container spacing={2} sx={{ pt: 4 }}>
                                                <Grid item xs={12}>
                                                    {errValidCode &&
                                                        <Box>
                                                            <Typography sx={{ color: 'red', paddingBottom: "1rem", fontSize: "1.2rem" }} >
                                                                {errValidCode}
                                                            </Typography>
                                                        </Box>
                                                    }
                                                    <Field as={TextField} variant="outlined" name='codeauth' label='Ingresa tu código' type="codeauth" fullWidth
                                                        error={props.errors.codeauth && props.touched.codeauth}
                                                        helperText={<ErrorMessage name='codeauth' />} required />
                                                </Grid>
                                            </Grid>

                                            <Grid container sx={{pt: 2}}>
                                                <Field type="checkbox" name="remember" as={FormControlLabel} control={<Checkbox />} label="Este equipo es de confianza. No preguntar el código de verificación por 30 días." sx={{'& .MuiCheckbox-root':{color: '#5F81FF'}, '& .MuiFormControlLabel-label':{fontSize: '1.34rem'}, '& .MuiSvgIcon-root':{fontSize: '2.2rem'} }}  />
                                            </Grid>

                                            <Grid container justifyContent="flex-end" sx={{ pb: 4 }}>
                                                <LoadingButton loading={loadingCode} type='submit' variant='contained' color='primary' sx={{ my: 2 }}>
                                                    Continuar
                                                </LoadingButton>
                                            </Grid>

                                        </Form>

                                    )}
                                </Formik>

                            </Grid>

                        </Grid>
                    </Container>

                    :

                    <Container maxWidth="sm" variant='cont-nk'>

                        <Box sx={{ mb: 2, mt: 3 }}>
                            <Typography
                                color="textPrimary"
                                variant="h2"
                                align="center"
                            >
                                Lo sentimos,
                            </Typography>

                        </Box>

                        <Box sx={{ pt: 1, pb: 2 }}>
                            <Typography
                                color="textSecondary"
                                gutterBottom
                                variant="body1"
                                align="center"
                            >
                                Esta opción no se encuentra disponible, por favor intente nuevamente.
                            </Typography>

                        </Box>

                    </Container>

                }

            </Box>

        </>
    );
}

export default Login2FA;