import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { verifyRenewDomain } from '../../services/Domain';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    btnRenew:{
        '&:hover':{
            backgroundColor: "#5f81ff",
            color: "#fff"
        }
    }
}));

const ButtonRenewDomain = (props) => {
    const {domainid, userid} =  props;
    const navigate = useNavigate();
    const classes = useStyles();

    const handleRenewDomain = async () => {

        const invoice = await verifyRenewDomain(domainid, userid);
        //console.log("invoice", invoice);

        if(invoice.error === false){
            if(invoice.idinvoice !== null){
                navigate(`/clientarea/invoices/${invoice.idinvoice}/`, {replace: true});
            }else{
                navigate("/clientarea/renewdomain/", {state: {domain: invoice.domain}});
            }
        }
    }

    return (
        <>
            <Button variant="outlined" size="small" onClick={handleRenewDomain} className={classes.btnRenew} >Renovar</Button>
        </>
    );
}

export default ButtonRenewDomain;