import React, { useEffect, useState } from 'react';
import { Container, Box, Grid, Typography, Divider, Tab, Paper } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import {getServicesClient} from '../../services/Services';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import TableDomains from './TableDomains';
import SpinnerLoad from '../../components/FormsUI/Spinner';
import { logout } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';


const Domains = (props) => {
    const navigate = useNavigate();
    const [fullDomains, setFullDomains] = useState(null);
    //let error = false;
    const [error, setError] = useState(false);

    //to pagination
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);

    //to filter
    const [status, setStatus] = useState('all');
    const [domains, setFilteredData] = useState(null);

    const [qtyExpired, setQtyExpired] = useState(0);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };
    const handleLimitChange = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };
    const handleStatusChange = (event, newValue) => {
        setPage(0);
        setStatus(newValue);
    };
    const getDaysToExpiry = (activedomains) => {
        const domainswithdays = activedomains;
        const today = new Date();

        activedomains.map((domain) => {
            const expirydate = new Date(domain.rawexpirydate);
            const difference = parseInt( (expirydate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24) );
            domain["expirydays"] = difference;
        })

        return domainswithdays;
    }

    useEffect(() => {

        const getServices = async () => {
            const serv = await getServicesClient();
            //console.log('servicios', serv);

            if (serv.error) {
                //error = true
                if(serv.message.status === 401){
                    logout();
                    navigate('/', {replace: true});
                }else{
                    setError(true);
                }
            } else {
                setFullDomains(serv.domains);

                const activeDomains = serv.domains.filter(s => s.status === 'Active');
                //const newactiveDomains = getDaysToExpiry(activeDomains);

                const expiredomains = serv.domains.filter(dt => dt.status === 'Expired' || dt.status === 'Grace' || dt.status === 'Redemption');
                setQtyExpired(expiredomains.length);

                if (activeDomains.length !== 0) {
                    setFilteredData(activeDomains);
                    setStatus('Active');
                } else {
                    setFilteredData(serv.domains);
                }

            }

        }
        getServices();

    }, []);

    useEffect(() => {

        if (status === 'all') {
            setFilteredData(fullDomains);
        }
        if (status === 'Active') {
            const filteredomain = fullDomains.filter(dt => dt.status === 'Active');
            const newfilteredomain = getDaysToExpiry(filteredomain);
            setFilteredData(newfilteredomain);
        }
        if (status === 'Pending') {
            const filteredomain = fullDomains.filter(dt => dt.status === 'Pending' || dt.status === 'Pending Registration');
            setFilteredData(filteredomain);
        }
        if (status === 'Expired') {
            const filteredomain = fullDomains.filter(dt => dt.status === 'Expired' || dt.status === 'Grace' || dt.status === 'Redemption');
            setFilteredData(filteredomain);
        }
        if (status === 'Pending Transfer') {
            const filteredomain = fullDomains.filter(dt => dt.status === 'Pending Transfer');
            setFilteredData(filteredomain);
        }
        if (status === 'Terminated') {
            const filteredomain = fullDomains.filter(dt => dt.status === 'Cancelled' || dt.status === 'Transferred Away');
            setFilteredData(filteredomain);
        }

    }, [status]);


    return (
        <>
            <Helmet>
                <title>Portal - Área de clientes de Neothek</title>
            </Helmet>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center'
                }}
            >

                {domains === null ?
                    <>

                        {error ?
                            <Container maxWidth="sm" variant='cont-nk'>

                                <Box sx={{ mb: 2, mt: 3 }}>
                                    <Typography
                                        color="textPrimary"
                                        variant="h2"
                                        align="center"
                                    >
                                        Lo sentimos,
                                    </Typography>

                                </Box>

                                <Box sx={{ pt: 1, pb: 2 }}>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="body1"
                                        align="center"
                                    >
                                        Ha existido una falla o aún no tienes dominios registrados bajo tu cuenta.
                                    </Typography>

                                </Box>

                            </Container>

                            :
                            <SpinnerLoad />

                        }

                    </>


                    :
                    <Container maxWidth="lg" variant='cont-nk'>

                        <Box>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box mt={3} mb={3} >
                                        <Typography variant='h4'>Mis Dominios</Typography>
                                        <Divider />
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>

                                    <TabContext value={status} component={Paper}>
                                        <Box sx={{ borderBottom: 1, borderColor: '#e8f2ff' }}>
                                            <TabList onChange={handleStatusChange} aria-label="tabs estados pagos">
                                                <Tab label="Todos" value="all" variant='btnTab-nk' />
                                                <Tab label="Activos" value="Active" variant='btnTab-nk' />
                                                <Tab label={"Expirados ("+qtyExpired+")"} value="Expired" variant='btnTab-nk' />
                                                <Tab label="Pendientes" value="Pending" variant='btnTab-nk' />
                                                <Tab label="Por Transferir" value="Pending Transfer" variant='btnTab-nk' />
                                                <Tab label="Terminados" value="Terminated" variant='btnTab-nk' />
                                            </TabList>
                                        </Box>
                                        <TabPanel value="all">
                                            <TableDomains domains={domains} page={page} limit={limit} handlePageChange={handlePageChange} handleLimitChange={handleLimitChange}></TableDomains>
                                        </TabPanel>
                                        <TabPanel value="Active">
                                            <TableDomains domains={domains} page={page} limit={limit} handlePageChange={handlePageChange} handleLimitChange={handleLimitChange}></TableDomains>
                                        </TabPanel>
                                        <TabPanel value="Expired">
                                            <TableDomains domains={domains} page={page} limit={limit} handlePageChange={handlePageChange} handleLimitChange={handleLimitChange}></TableDomains>
                                        </TabPanel>
                                        <TabPanel value="Pending">
                                            <TableDomains domains={domains} page={page} limit={limit} handlePageChange={handlePageChange} handleLimitChange={handleLimitChange}></TableDomains>
                                        </TabPanel>
                                        <TabPanel value="Pending Transfer">
                                            <TableDomains domains={domains} page={page} limit={limit} handlePageChange={handlePageChange} handleLimitChange={handleLimitChange}></TableDomains>
                                        </TabPanel>
                                        <TabPanel value="Terminated">
                                            <TableDomains domains={domains} page={page} limit={limit} handlePageChange={handlePageChange} handleLimitChange={handleLimitChange}></TableDomains>
                                        </TabPanel>
                                    </TabContext>

                                </Grid>



                            </Grid>

                        </Box>

                    </Container>
                }



            </Box>
        </>
    )
    /* }*/







}

export default Domains;