import {
    NavLink as RouterLink,
    matchPath,
    useLocation
  } from 'react-router-dom';
  import PropTypes from 'prop-types';
  import { Button, ListItem } from '@material-ui/core';
  
  const NavItemLogout = ({
    href,
    icon: Icon,
    title,
    ...rest
  }) => {
    const location = useLocation();
  
    const active = href ? !!matchPath({
      path: href,
      end: false
    }, location.pathname) : false;
  
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'flex',
          py: 0
        }}
        {...rest}
      >
        <Button
          component={RouterLink}
          sx={{
            color: '#545963',
            fontWeight: '600',
            fontSize: '1.35rem',
            justifyContent: 'flex-start',
            letterSpacing: 0,
            py: 1.25,
            px: 2,
            textTransform: 'none',
            width: '100%',
            ...(active && {
              backgroundColor: '#e8efff'
            }),
            '& svg': {
              mr: 1
            },
            '&:focus': {outline: 'none'}
          }}
          to={href}
        >
          {Icon && (
            <Icon size="20" />
          )}
          <span>
            {title}
          </span>
        </Button>
      </ListItem>
    );
  };
  
  NavItemLogout.propTypes = {
    href: PropTypes.string,
    icon: PropTypes.elementType,
    title: PropTypes.string
  };
  
  export default NavItemLogout;
  