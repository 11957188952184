import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Divider, Grid, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { activate2FA, register2FA, validatePassword } from '../../services/User';
import { getPublicIp } from '../../utils';
import { useNavigate } from 'react-router-dom';

function TwoStepVerification(props) {

    const navigate = useNavigate();

    /*start: 0 mensaje, 1 confirm pass, 2 create y generate QR */

    const [loadingStart, setLoadingStart] = useState(false);
    const [loadingPass, setLoadingPass] = useState(false);
    const [loadingActivate, setLoadingActivate] = useState(false);
    const [start, setStart] = useState(0);
    const [errValidPass, setErrValidPass] = useState(null);
    const [qr, setQr] = useState(null);
    const [id2fa, setId2fa] = useState(null);
    const [errActiveAuth, setErrActiveAuth] = useState(null);

    const handleStart2FA = () => {
        setLoadingStart(true);
        setStart(1);
        setLoadingStart(false);
    }

    //confirmar pass
    const confirmPassword = async (val, actions) => {
        setLoadingPass(true);
        //console.log("confirmar password", val, actions);

        const validation = await validatePassword(val.password);

        if (validation.error === false) {

            if (validation.ispass === true) {

                //create register 2FA
                const ip = await getPublicIp();
                const reg2fa = await register2FA(ip);

                if (reg2fa.error === false) {
                    //inicializar qr
                    setId2fa(reg2fa.id);
                    setQr(reg2fa.qr);
                    setStart(2);
                } else {
                    actions.resetForm();
                    setErrValidPass("Hubo un error, por favor contacte con soporte");
                }

                setLoadingPass(false);
            } else {
                actions.resetForm();
                setErrValidPass("Contraseña incorrecta");
                setLoadingPass(false);
            }

        } else {
            setErrValidPass("Contraseña incorrecta");
            setLoadingPass(false);
        }
    };

    const initialValues = {
        password: ''
    };
    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required("Contraseña es requerida")
    });

    //activacion 2fa
    const enable2FA = async (val, actions) => {
        setLoadingActivate(true);

        const ip = await getPublicIp();
        //cambiar activate to 1 para habilitar auth2FA
        const status2fa = await activate2FA(id2fa, val.code, ip);

        if (status2fa.error === false) {
            if (status2fa.active === 1) {
                navigate('/clientarea/account/', { replace: true });
            } else {
                actions.resetForm();
                setErrActiveAuth("Código inválido");
                setLoadingActivate(false);
            }
        } else {
            actions.resetForm();
            setErrActiveAuth("Código inválido");
            setLoadingActivate(false);
        }

    }

    const initialActivate = {
        code: ''
    };
    const validationActivate = Yup.object().shape({
        code: Yup.string()
            .matches(/^[0-9]+$/, 'Sólo se permite números')
            .required("Código es requerido")
    });


    return (
        <>
            <Helmet>
                <title>Portal - Área de clientes de Neothek</title>
            </Helmet>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center'
                }}
            >

                {start === 0 &&

                    <Container maxWidth="md" variant='cont-nk'>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box mt={3} mb={3} >
                                    <Typography variant='h4'>Verificación en 2 pasos</Typography>
                                    <Divider />
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container sx={{ border: "1px solid #dadce0", borderRadius: "5px", py: 3, px: 2, marginBottom: "3rem" }}>
                            <Grid item xs={12}>
                                <Typography >Evita que los hackers accedan a tu cuenta con una capa adicional de seguridad. Cuando inicias sesión, la verificación en dos pasos ayuda a garantizar que tu información personal se mantenga privada, segura y protegida.</Typography>

                                <Typography sx={{ fontWeight: 500, mt: 4, mb: 2 }}>Google Authenticator</Typography>
                                <Typography >Antes de proceder con la activación, descarga e instala la <span style={{fontWeight: "600"}}>App gratuita de Google Authenticator</span> en tu dispositivo móvil.</Typography>
                            </Grid>

                            <Grid container justifyContent="flex-end">
                                <LoadingButton loading={loadingStart} onClick={handleStart2FA} variant='contained' color='primary' sx={{ my: 2 }}>
                                    Comenzar
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Container>

                }

                {start === 1 &&

                    <Container maxWidth="sm" variant='cont-nk'>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box mt={3} mb={3} >
                                    <Typography variant='h4' sx={{ textAlign: "center" }}>Verificación en 2 pasos</Typography>
                                    <Divider />
                                </Box>
                            </Grid>

                            <Grid item xs={12} >
                                <Typography sx={{ fontSize: "1.33rem" }}>Debes verificar que eres tú para poder continuar</Typography>

                                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={confirmPassword}>
                                    {(props) => (

                                        <Form>
                                            <Grid container spacing={2} sx={{ pt: 4 }}>
                                                <Grid item xs={12}>

                                                    {errValidPass &&
                                                        <Box>
                                                            <Typography sx={{ color: 'red', paddingBottom: "1rem", fontSize: "1.2rem" }} >
                                                                {errValidPass}
                                                            </Typography>
                                                        </Box>
                                                    }
                                                    <Field as={TextField} variant="outlined" name='password' label='Ingresa tu contraseña' type="password" fullWidth
                                                        error={props.errors.password && props.touched.password}
                                                        helperText={<ErrorMessage name='password' />} required />
                                                </Grid>

                                            </Grid>

                                            <Grid container justifyContent="flex-end" sx={{ pb: 4 }}>
                                                <LoadingButton loading={loadingPass} type='submit' variant='contained' color='primary' sx={{ my: 2 }}>
                                                    Siguiente
                                                </LoadingButton>
                                            </Grid>

                                        </Form>

                                    )}
                                </Formik>

                            </Grid>

                        </Grid>
                    </Container>

                }

                {(start === 2 && qr !== null) &&
                    <Container maxWidth="sm" variant='cont-nk'>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box mt={3} mb={3} >
                                    <Typography variant='h4' sx={{ textAlign: "center" }}>Verificación en dos pasos</Typography>
                                    <Divider />
                                </Box>
                            </Grid>

                            <Grid item xs={12} >
                                <Typography sx={{mt: 1}}><b>1.</b> Escanea el QR con Google Authenticator para añadir tu cuenta.</Typography>

                                <Grid align='center' sx={{mt: 2, mb: 3}}>
                                    <img src={qr} alt='code qr' align='center'></img>
                                </Grid>

                                <Typography><b>2.</b> Ingresa tu código de verificación de 6 dígitos</Typography>

                                <Formik initialValues={initialActivate} validationSchema={validationActivate} onSubmit={enable2FA}>
                                    {(props) => (

                                        <Form>
                                            <Grid container spacing={2} sx={{ pt: 4 }}>
                                                <Grid item xs={12}>
                                                    {errActiveAuth &&
                                                        <Box>
                                                            <Typography sx={{ color: 'red', paddingBottom: "1rem", fontSize: "1.2rem" }} >
                                                                {errActiveAuth}
                                                            </Typography>
                                                        </Box>
                                                    }
                                                    <Field as={TextField} variant="outlined" name='code' label='Ingresa tu código' fullWidth
                                                        error={props.errors.code && props.touched.code}
                                                        helperText={<ErrorMessage name='code' />} required />
                                                </Grid>

                                            </Grid>

                                            <Grid container justifyContent="flex-end" sx={{ pb: 4 }}>
                                                <LoadingButton loading={loadingActivate} type='submit' variant='contained' color='primary' sx={{ my: 2 }}>
                                                    Confirmar
                                                </LoadingButton>
                                            </Grid>

                                        </Form>

                                    )}
                                </Formik>
                            </Grid>


                        </Grid>
                    </Container>
                }


            </Box>
        </>
    );
}

export default TwoStepVerification;