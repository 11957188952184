import { Grid, Typography, Container, Box, Link, Button, Divider, Checkbox, TextField, Select, MenuItem } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import StepperCart from '../components/FormsUI/StepperCart';
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import CheckBoxOutline from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormControl from '@material-ui/core/FormControl';
import NextIcon from '@material-ui/icons/ArrowForward';
import LanguageIcon from '@mui/icons-material/Language';
import SpinnerLoad from '../components/FormsUI/Spinner';
import { getDetailDomain } from '../services/Domain';

const ReviewDomainOrder = () => {
    const navigate = useNavigate();
    const location = useLocation();

    let domainname = null;
    let tld = null;
    let eventdomain = null;
    let epp = '';
    const freeDomain = null;

    if (location.state !== null) {
        domainname = location.state.domainName;
        tld = location.state.tld;
        eventdomain = location.state.eventDomain;
        epp = location.state.epp;
    }

    const [loaded, setLoaded] = useState(false);
    const [checkAddonHidden, setCheckAddonHidden] = useState('x');

    const [cycle, setCycle] = useState(1);
    const [anio, setAnio] = useState(1);
    const [domain, setDomain] = useState(null);
    const [domainAddons, setDomainAddons] = useState([]);

    const [priceDomain, setPriceDomain] = useState(0.00);
    const [priceRenewDomain, setPriceRenewDomain] = useState(0.00);
    const [discountRate, setDiscountRate] = useState(0);
    const [subtotal, setSubtotal] = useState(0.00);
    const [subTotalAdd, setSubTotalAdd] = useState(0.00);
    const [total, setTotal] = useState(0.00);

    //NS
    const [ns1, setns1] = useState('');
    const [ns2, setns2] = useState('');
    const [ns3, setns3] = useState('');
    const [ns4, setns4] = useState('');
    const [ns5, setns5] = useState('');


    const inicializarPrecios = (domain, addons) => {
        let total = 0;

        const pricedomain = domain.cycles[0].price;
        const priceaddons = getPricesDomainAddonsIni(addons);

        total = (pricedomain + priceaddons).toFixed(2);

        setSubTotalAdd(priceaddons);
        setSubtotal(total);
        setTotal(total);

    };

    const getPricesDomainAddonsIni = (addons) => {
        let priceaddons = 0;

        addons.map((addon) => {
            if (addon.check === true) {
                priceaddons = priceaddons + addon.price;
            }
        })

        return priceaddons;
    };

    const handleChangeCycle = (ev) => {
        //console.log('event cycle', ev.target.value);
        const anioselected = ev.target.value;

        const detailprice = searchDetailAnio(anioselected, 'pricedomain');
        const detailpricerenew = searchDetailAnio(anioselected, 'pricerenewdomain');
        //console.log('detail price', detailprice, detailprice);
        //console.log('detail price renew', detailpricerenew);

        //calcular total
        const priceaddons = getPricesDomainAddons(domainAddons, anioselected);
        const total = (detailprice.price + priceaddons).toFixed(2);


        setAnio(anioselected);
        setPriceDomain(detailprice.price);
        setCycle(detailprice.periodeng);
        setPriceRenewDomain(detailpricerenew.price);

        setSubTotalAdd(priceaddons);
        setSubtotal(total);
        setTotal(total);

    };

    const searchDetailAnio = (anio, type) => {
        let detail = [];
        if (type === 'pricedomain') {
            detail = domain.cycles.filter(cycle => cycle.id === anio);
        }
        if (type === 'pricerenewdomain') {
            detail = domain.renewprices.filter(renew => renew.id === anio);
        }
        return detail[0];
    };

    const handleCheckAddon = (ev, index) => {
        //console.log('check addon', ev.target.value, ev.target.checked);
        //console.log('index', index);

        const domainaddonsupdate = updateCheckDomainAddons(index, ev.target.checked);

        const priceaddons = getPricesDomainAddons(domainaddonsupdate, anio);
        const total = (priceDomain + priceaddons).toFixed(2);

        setDomainAddons(domainaddonsupdate);
        setCheckAddonHidden(`${index} ${ev.target.checked}`);

        setSubTotalAdd(priceaddons);
        setSubtotal(total);
        setTotal(total);

    };

    const getPricesDomainAddons = (addons, anio) => {
        let priceaddons = 0;

        addons.map((addon) => {
            if (addon.check === true) {
                const priceaddtot = addon.price * anio;
                priceaddons = priceaddons + priceaddtot;
            }
        })

        return priceaddons;
    };

    const updateCheckDomainAddons = (index, checked) => {
        let domainAddonsUpdate = domainAddons;
        domainAddonsUpdate[index].check = checked;

        return domainAddonsUpdate;
    }

    //ns
    const handleChangeNs1 = (ev) => {
        setns1(ev.target.value);
    }
    const handleChangeNs2 = (ev) => {
        setns2(ev.target.value);
    }
    const handleChangeNs3 = (ev) => {
        setns3(ev.target.value);
    }
    const handleChangeNs4 = (ev) => {
        setns4(ev.target.value);
    }
    const handleChangeNs5 = (ev) => {
        setns5(ev.target.value);
    }


    const passRegister = () => {
        const addons = getAddonsChecked();
        const nameservers = [ns1, ns2, ns3, ns4, ns5]
        const data = {
            billingcycle: cycle,
            domain: domainname,
            eventdomain: eventdomain,
            pricedomain: priceDomain,
            pricerenewdomain: priceRenewDomain,
            freedomain: freeDomain,
            subtotal: subtotal,
            subtotaladd: subTotalAdd,
            total: total,
            qtyanios: anio,
            domainaddons: addons,
            nameservers: nameservers,
            purchaserecordtype: 'singledomain',
            coupon: null,
            epp: epp
        }

        //console.log('data', data);

        navigate('/cart/domain/checkout/', { state: { data: data } });
    }

    const getAddonsChecked = () => {
        let addonschecked = [];

        domainAddons.map(addon => {

            if (addon.check === true) {
                let priceaddon = addon.price * anio;
                let infoaddon = {
                    id: addon.id,
                    name: addon.name,
                    price: priceaddon
                }
                addonschecked.push(infoaddon);
            }

        })

        return addonschecked;
    }

    useEffect(() => {

        if (tld !== null) {

            async function getDetailFullDomain() {
                const domain = await getDetailDomain(tld, eventdomain, 1);
                //console.log('detail domain', domain);

                if (domain.error === false) {
                    setDomain(domain);

                    //filtrar addons habilitados, value 1
                    const addonsAvailable = domain.addons.filter(addon => addon.display === 1);
                    setDomainAddons(addonsAvailable);

                    //poner cycle @nio
                    setAnio(domain.cycles[0].id);
                    setCycle(domain.cycles[0].periodeng);

                    //poner precios
                    const pricedom = domain.cycles[0].price;
                    const renewpricedom = domain.renewprices[0].price;

                    setPriceDomain(pricedom);
                    setPriceRenewDomain(renewpricedom);

                    //percentage discount
                    const percent = Math.round(100 - ((pricedom * 100) / renewpricedom));
                    setDiscountRate(percent);

                    //calcular total inicial
                    inicializarPrecios(domain, addonsAvailable); //suma precios inicial dominio y domainaddons checked

                    //inicializar ns
                    if (domain.nsdefault[0]) {
                        setns1(domain.nsdefault[0].value)
                    }
                    if (domain.nsdefault[1]) {
                        setns2(domain.nsdefault[1].value)
                    }
                    if (domain.nsdefault[2]) {
                        setns3(domain.nsdefault[2].value)
                    }
                    if (domain.nsdefault[3]) {
                        setns4(domain.nsdefault[3].value)
                    }
                    if (domain.nsdefault[4]) {
                        setns5(domain.nsdefault[4].value)
                    }

                    setLoaded(true);
                } else {
                    if (domain.error === true) {

                    }
                    setLoaded(true);
                }
            }

            getDetailFullDomain();
        }

    }, [])


    return (
        <>
            <Helmet>
                <title>Portal - Área de Clientes de Neothek - Revisar Pedido</title>
            </Helmet>

            {loaded === false ?
                <SpinnerLoad></SpinnerLoad>
                :
                <>
                    {domain !== null ?
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                justifyContent: 'center'
                            }}
                        >

                            <Container maxWidth="md">
                                <Grid item xs={12} md={12}>
                                    <StepperCart step={1} />
                                </Grid>
                            </Container>

                            <Container maxWidth="md" variant='cont-nk'>

                                <Container variant='cont-boxnk'>
                                    <Grid item xs={12}>
                                        <Box mt={2} mb={3} >
                                            {eventdomain === 'domainregister' && <Typography variant='h4'>Registro de Dominio</Typography>}
                                            {eventdomain === 'domaintransfer' && <Typography variant='h4'>Transferencia de Dominio</Typography>}
                                        </Box>
                                    </Grid>

                                    <Grid container>
                                        <Grid item xs={12} sm={4} mb={5}>
                                            <Typography sx={{ fontSize: '13px', color: '#6c757d', textAlign: 'center', pb: 2 }}>Dominio</Typography>
                                            <Grid container justifyContent="center">
                                                <LanguageIcon sx={{ fontSize: '40px', textAlign: 'center' }} color="primary" />
                                            </Grid>
                                            <Typography sx={{ fontSize: '13px', textAlign: 'center' }} color="colornk.bluedark"><strong>{domainname}</strong></Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={4} mb={5}>
                                            <Typography sx={{ fontSize: '13px', color: '#6c757d', textAlign: 'center', pb: 2 }}>Periodo</Typography>
                                            <Grid container justifyContent={"center"}>
                                                <FormControl variant="outlined" sx={{ align: 'center' }} >
                                                    <Select
                                                        value={anio}
                                                        displayEmpty
                                                        onChange={handleChangeCycle}
                                                        sx={{ '& .MuiSelect-root': { padding: '10px 14px' } }}
                                                    >
                                                        {domain.cycles.map(({ id, price, periodeng }) => (
                                                            <MenuItem key={id} value={id} >{id} Año(s)</MenuItem>
                                                        ))}

                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                        </Grid>

                                        <Grid item xs={12} sm={4} mb={5}>
                                            <Typography sx={{ fontSize: '13px', color: '#6c757d', textAlign: 'center', pb: 2 }}>Precio</Typography>
                                            <Grid container justifyContent="center" direction="column">
                                                <Typography sx={{ color: '#919091', fontSize: '13px', textDecoration: 'line-through', textAlign: 'center' }} >${domain.renewprices[0].price} /año</Typography>
                                                <Typography sx={{ textAlign: 'center' }} color="colornk.bluedark" variant="txtSummary-nk" ><strong>${domain.cycles[0].price} <span style={{ fontSize: '12px' }}>/1er año</span></strong></Typography>

                                                <Typography sx={{ textAlign: 'center', color: '#fff', fontSize: '14px', mt: '5px' }}> <strong><span style={{ backgroundColor: '#5f81ff', padding: '6px 6px' }}>{discountRate}% Descuento</span></strong></Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12} sx={{ marginBottom: '-5px' }}>
                                            <Typography sx={{ fontSize: '1.2rem', textAlign: 'center' }} color='colornk.grey3'>Precio de renovación @ US${domain.renewprices[0].price}/año</Typography>
                                        </Grid>

                                    </Grid>


                                    <Divider variant='div-nk' />

                                </Container>

                                {domainAddons.length !== 0 &&
                                    <Container variant="cont-boxnk">
                                        <Grid item xs={12}>
                                            <Box mt={2} mb={3} >
                                                <Typography variant='h4'>Complementos Disponibles</Typography>
                                            </Box>
                                        </Grid>

                                        <Grid container>
                                            <Typography hidden>{checkAddonHidden}</Typography>
                                            {domainAddons.map(({ id, name, description, price, check }, index) => (

                                                <Grid key={id} item xs={12} sm={12} sx={{ mb: 2 }}>
                                                    <Box sx={{ textAlign: 'center', backgroundColor: '#f6f9fc' }}>
                                                        <Box sx={{ paddingTop: '3px' }}>
                                                            {price === 0 ? 
                                                                <Typography variant='body2' color='colornk.bluedark2'><strong>{name}</strong>| <span style={{ fontSize: '13px' }} color="colornk.bluedark"> Gratis!</span> </Typography>
                                                            :
                                                                <Typography variant='body2' color='colornk.bluedark2'><strong>{name}</strong>| <span style={{ fontSize: '13px' }} color="colornk.bluedark"> US$ {price} / 1 Año(s)</span> </Typography>
                                                            }
                                                        </Box>

                                                        <Box>
                                                            <Grid container sx={{ py: 1 }} alignItems="center">
                                                                <Grid item xs={2} sm={1}>
                                                                    <Checkbox
                                                                        icon={<CheckBoxOutline />}
                                                                        checkedIcon={<CheckBoxIcon />}
                                                                        value={id}
                                                                        onChange={(e) => { handleCheckAddon(e, index) }}
                                                                        checked={check}
                                                                        color='primary'
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={10} sm={11} sx={{ textAlign: 'justify', paddingRight: '3%' }}>
                                                                    <Typography variant='body2' color="colornk.bluedark" sx={{ fontSize: '1.35rem' }}>{description}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Box>
                                                </Grid>

                                            ))}

                                        </Grid>
                                        <Divider variant='div-nk' />
                                    </Container>
                                }

                                {/* ns */}
                                <Container variant="cont-boxnk">
                                    <Grid item xs={12}>
                                        <Box mt={2} mb={3} >
                                            <Typography variant='h4'>Nameservers</Typography>
                                        </Box>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} mb={1}>
                                            <Typography color="colornk.grey3" variant="txtSummary-nk">Si quiere personalizar los DNS puede introducirlos a continuación. Por defecto, los nuevos dominios usarán nuestros DNS.</Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={4} >
                                            <TextField
                                                fullWidth
                                                label="Nameserver 1"
                                                value={ns1}
                                                variant="outlined"
                                                size="small"
                                                sx={{ '& .MuiOutlinedInput-root': { fontSize: '14px' } }}
                                                onChange={handleChangeNs1}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} >
                                            <TextField
                                                fullWidth
                                                label="Nameserver 2"
                                                value={ns2}
                                                variant="outlined"
                                                size="small"
                                                sx={{ '& .MuiOutlinedInput-root': { fontSize: '14px' } }}
                                                onChange={handleChangeNs2}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} >
                                            <TextField
                                                fullWidth
                                                label="Nameserver 3"
                                                value={ns3}
                                                variant="outlined"
                                                size="small"
                                                sx={{ '& .MuiOutlinedInput-root': { fontSize: '14px' } }}
                                                onChange={handleChangeNs3}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} >
                                            <TextField
                                                fullWidth
                                                label="Nameserver 4"
                                                value={ns4}
                                                variant="outlined"
                                                size="small"
                                                sx={{ '& .MuiOutlinedInput-root': { fontSize: '14px' } }}
                                                onChange={handleChangeNs4}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} >
                                            <TextField
                                                fullWidth
                                                label="Nameserver 5"
                                                value={ns5}
                                                variant="outlined"
                                                size="small"
                                                sx={{ '& .MuiOutlinedInput-root': { fontSize: '14px' } }}
                                                onChange={handleChangeNs5}
                                            />
                                        </Grid>

                                    </Grid>
                                    <Divider variant='div-nk' />
                                </Container>

                                {/* sumario pedido */}

                                <Container>
                                    <Grid item xs={12}>
                                        <Box mt={4} mb={2} >
                                            <Typography variant='h4'>Sumario de Pedido</Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} >
                                        <Container sx={{ backgroundColor: "#F6F9FC", borderRadius: "5px", p: 1, paddingTop: "4%" }}>
                                            <Grid container spacing={2} sx={{ mb: 2 }}>

                                                <Grid item sm={8} xs={9} style={{ paddingTop: '8px' }}>
                                                    {eventdomain === 'domainregister' ?
                                                        <Typography color="colornk.bluedark" variant="txtSummary-nk">Registro de Dominio - {domainname} / {anio} año(s)</Typography>
                                                        :
                                                        <Typography color="colornk.bluedark" variant="txtSummary-nk">Transferencia de Dominio - {domainname} / {anio} año(s)</Typography>
                                                    }

                                                </Grid>
                                                <Grid item sm={4} xs={3} style={{ paddingTop: '13px', textAlign: 'right' }}>
                                                    <Typography color="colornk.bluedark" variant="txtSummary-nk">US$ {priceDomain}</Typography>
                                                </Grid>
                                                <Grid item sm={12} style={{ paddingTop: 0 }}>
                                                    <Typography style={{ fontSize: '1.18rem' }} color='colornk.grey3'>Renovación ${priceRenewDomain} / {anio} año(s)</Typography>
                                                </Grid>

                                                <Grid item sm={8} xs={9} style={{ paddingTop: '13px' }}>
                                                    <Typography color="colornk.bluedark" variant="txtSummary-nk" >Complementos</Typography>
                                                </Grid>
                                                <Grid item sm={4} xs={3} style={{ paddingTop: '13px', textAlign: 'right' }}>
                                                    <Typography color="colornk.bluedark" variant="txtSummary-nk" >US$ {subTotalAdd}</Typography>
                                                </Grid>

                                                <Grid container spacing={2} sx={{ paddingLeft: 2, mt: 2 }}>

                                                    <Grid item xs={12} sx={{ borderTop: '1px solid #e6ebf1' }} style={{ paddingTop: '0px' }}></Grid>

                                                    <Grid item sm={8} xs={8}>
                                                        <Typography color="colornk.bluedark" variant="txtSummary-nk"><strong>Subtotal</strong></Typography>
                                                    </Grid>
                                                    <Grid item sm={4} xs={4} sx={{ textAlign: "right" }}>
                                                        <Typography color="colornk.bluedark" variant="txtSummary-nk"><strong>US$ {subtotal}</strong></Typography>
                                                    </Grid>

                                                    <Grid item sm={8} xs={8} style={{ paddingTop: '13px' }}>
                                                        <Typography color="colornk.bluedark" variant="txtSummary-nk"><strong>Total</strong></Typography>
                                                    </Grid>
                                                    <Grid item sm={4} xs={4} style={{ paddingTop: '13px', textAlign: 'right' }}>
                                                        <Typography color="colornk.bluedark" variant="txtSummary-nk"><strong>US$ {total}</strong></Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Container>
                                    </Grid>

                                </Container>

                                <Grid container justifyContent="flex-end" alignItems="center" >
                                    <Box sx={{ py: 3 }}>
                                        <Button variant='contained' color='success' onClick={passRegister} size='large' endIcon={<NextIcon />}>
                                            Continuar
                                        </Button>
                                    </Box>
                                </Grid>

                            </Container>

                        </Box >
                        :
                        <Container maxWidth="sm" variant='cont-nk'>

                            <Box sx={{ mb: 2, mt: 3 }}>
                                <Typography
                                    color="textPrimary"
                                    variant="h2"
                                    align="center"
                                >
                                    Lo sentimos,
                                </Typography>

                            </Box>

                            <Box sx={{ pt: 1, pb: 2 }}>
                                <Typography
                                    color="textSecondary"
                                    gutterBottom
                                    variant="body1"
                                    align="center"
                                >
                                    Para este paso necesita ingresar un dominio válido.
                                </Typography>

                                <Typography
                                    color="textSecondary"
                                    variant="body1"
                                    align='center'
                                >
                                    <Link
                                        component={RouterLink}
                                        to="/cart/domain/"
                                        variant="h6"
                                    >
                                        Regresar registrar dominio.
                                    </Link>
                                </Typography>
                            </Box>

                        </Container>
                    }
                </>

            }


        </>
    )
}

export default ReviewDomainOrder