import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import { createSessionCP, validate2FA } from '../../services/User';
import DialogOpenCpanel from './DialogOpenCpanel';

const ButtonCpanel = (props) => {
    const { idhost } = props;
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    //dialog
    const [openDialog, setOpenDialog] = useState(false);
    const [titleDialog, setTitleDialog] = useState("");
    const [contentDialog, setContentDialog] = useState("");
    const [styleDialog, setStyleDialog] = useState("");

    const [statusError, setStatusError] = useState(0);
    //0 no habilitado 2fa
    //1 error 2fa
    //2 error sessioncp

    const openCpanel = async () => {

        //console.log("prodid", idhost);

        setLoading(true);
        //verify twoauthentication
        const is2fa = await validate2FA();

        if (is2fa.error === false) {
            if (is2fa.security.active2FA === 1) {
                //console.log("entra 2fa 1")
                //conectar con api cpanel
                const sessioncp = await createSessionCP(idhost);
                //console.log("sessioncp", sessioncp);

                if(sessioncp.error === false){
                    if(sessioncp.result === 1){ //success
                        const url = sessioncp.url;

                        window.open(url, '_blank')
                    }
                    if(sessioncp.result === 0 || sessioncp.result === '-1'){ //error apicp
                        setOpenDialog(true);
                        setTitleDialog("Error");
                        setContentDialog(sessioncp.message);
                        setStyleDialog("error");
                        setStatusError(2);
                    }
                }else{
                    //mostrar dialog error
                    setOpenDialog(true);
                    setTitleDialog("Error");
                    setContentDialog("Error creando sessión, por favor intente nuevamente.");
                    setStyleDialog("error");
                    setStatusError(2);
                }

            } else {
                setOpenDialog(true);
                setTitleDialog("Acceso a cPanel");
                setContentDialog('Para acceder a esta opción primeramente debes habilitar la Verificación en dos Pasos en tu inicio de sesión. Puedes habilitar esta opción en la pestaña "Administrar Cuenta"');
                setStyleDialog("");
                setStatusError(0);
            }
        } else {
            //mandar dialog error
            setOpenDialog(true);
            setTitleDialog("Error");
            setContentDialog("Por favor verifique que la Verificación en dos pasos se encuentre activa.");
            setStyleDialog("error");
            setStatusError(1);
        }

        setLoading(false)
    }

    const actionDialog = (val) => {

        if (statusError === 0 || statusError === 1 ) {
            setOpenDialog(val);
            navigate('/clientarea/account/', { replace: true });
        }

        if(statusError === 2){
            setOpenDialog(val);
        }

    }

    return (
        <>
            {/*<LoadingButton loading={loading} onClick={openCpanel} sx={{padding: ".3rem 0rem", minWidth: "30px", borderRadius: "15px", "&:hover": {backgroundColor: "#cfcfcf"}}} > <Avatar sx={{ width: 24, height: 24, backgroundColor: "#F7692B", fontSize: "1.4rem" }} src="/static/images/logocpanel.jpg"></Avatar> </LoadingButton> */}
            <LoadingButton onClick={openCpanel} variant="contained" sx={{backgroundColor: "#f87a3c", "&:hover":{backgroundColor: "#e66526"}}} >Acceder a cPanel</LoadingButton>
            <DialogOpenCpanel openDialog={openDialog} titleDialog={titleDialog} contentDialog={contentDialog} styleDialog={styleDialog} actionDialog={actionDialog} />
        </>

    );
}

export default ButtonCpanel;