import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Divider, Grid, TextField, Typography } from '@mui/material';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useLocation, useNavigate } from 'react-router-dom';
import { updatePassword, validatePassword } from '../../services/User';
import { getPublicIp } from '../../utils';
import { logout } from '../../hooks/useAuth';
import DialogChangePass from './DialogChangePass';

function ChangePassword() {

    const location = useLocation();
    const navigate = useNavigate();

    let enablepass = 0;
    if (location.state && location.state.changepass === 1) {
        enablepass = location.state.changepass;
        //console.log("enable pass", enablepass);
    }

    const [enable, setEnable] = useState(enablepass);
    const [errValidPass, setErrValidPass] = useState(null);
    const [loadingPass, setLoadingPass] = useState(false);
    const [uiChangePass, setUIChangePass] = useState(0);
    const [loadingNewPass, setLoadingNewPass] = useState(false);

    const [openDialog, setOpenDialog] = useState(false);

    const confirmPassword = async (val, actions) => {
        setLoadingPass(true);

        const validation = await validatePassword(val.password);

        if (validation.error === false) {

            if (validation.ispass === true) {
                //console.log("is true");
                setEnable(2); //para esconder 
                setUIChangePass(1);
            } else {
                actions.resetForm();
                setErrValidPass("Contraseña incorrecta");
                setLoadingPass(false);
            }

        } else {
            setErrValidPass("Contraseña incorrecta");
            setLoadingPass(false);
        }
    };

    //para confirmar contraseña
    const initialValues = {
        password: ''
    };
    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required("Contraseña es requerida")
    });


    //para new password
    const initialValuesNew = {
        newpassword: '',
        newpassword2: ''
    };
    const validationSchemaNew = Yup.object().shape({
        newpassword: Yup.string().min(8, 'Usa 8 caracteres como mínimo').max(255).required('Contraseña es requerida'),
        newpassword2: Yup.string().oneOf([Yup.ref('newpassword'), null], 'La contraseña escrita no coincide'),
    });

    const handleChangePass = async (val, actions) => {
        setLoadingNewPass(true);
        //console.log("new pass", val);

        const ip = await getPublicIp();
        const updatepass = await updatePassword(ip, val.newpassword);

        setLoadingNewPass(false);
        if(updatepass.error === false ){
            logout();
            navigate('/login/', { replace: true });
        }else{
            setOpenDialog(true);
        }

    }

    return (
        <>
            <Helmet>
                <title>Portal - Área de clientes de Neothek</title>
            </Helmet>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center'
                }}
            >

                {enable === 1 &&

                    <Container maxWidth="sm" variant='cont-nk' sx={{mt: "3rem"}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box mt={3} mb={3} >
                                    <Typography variant='h4' sx={{ textAlign: "center" }}>Cambiar contraseña</Typography>
                                    <Divider />
                                </Box>
                            </Grid>

                            <Grid item xs={12} >
                                <Typography sx={{ fontSize: "1.33rem" }}>Debes verificar que eres tú para poder continuar</Typography>

                                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={confirmPassword}>
                                    {(props) => (

                                        <Form>
                                            <Grid container spacing={2} sx={{ pt: 4 }}>
                                                <Grid item xs={12}>
                                                    {errValidPass &&
                                                        <Box>
                                                            <Typography sx={{ color: 'red', paddingBottom: "1rem", fontSize: "1.2rem" }} >
                                                                {errValidPass}
                                                            </Typography>
                                                        </Box>
                                                    }
                                                    <Field as={TextField} variant="outlined" name='password' label='Ingresa tu contraseña' type="password" fullWidth
                                                        error={props.errors.password && props.touched.password}
                                                        helperText={<ErrorMessage name='password' />} required />
                                                </Grid>
                                            </Grid>

                                            <Grid container justifyContent="flex-end" sx={{ pb: 4 }}>
                                                <LoadingButton loading={loadingPass} type='submit' variant='contained' color='primary' sx={{ my: 2 }}>
                                                    Continuar
                                                </LoadingButton>
                                            </Grid>

                                        </Form>

                                    )}
                                </Formik>

                            </Grid>

                        </Grid>
                    </Container>

                }

                {enable === 0 &&
                    <Container maxWidth="sm" variant='cont-nk' sx={{mt: "3rem"}}>

                    <Box sx={{ mb: 2, mt: 3 }}>
                        <Typography
                            color="textPrimary"
                            variant="h2"
                            align="center"
                        >
                            Lo sentimos,
                        </Typography>

                    </Box>

                    <Box sx={{ pt: 1, pb: 2 }}>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            align="center"
                        >
                            Esta opción no se encuentra disponible, verifica las opciones en "Administrar Cuenta"
                        </Typography>

                    </Box>

                </Container>
                }

                {uiChangePass === 1 && 
                    <Container maxWidth="sm" variant='cont-nk' sx={{mt: "3rem"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box mt={3} mb={3} >
                                <Typography variant='h4' sx={{ textAlign: "center" }}>Contraseña</Typography>
                                <Divider />
                            </Box>
                        </Grid>

                        <Grid item xs={12} >
                            <Typography sx={{ fontSize: "1.33rem" }}>Una vez cambies tu contraseña, se cerrará la sesión en este equipo o dispositivo.</Typography>
                            <Typography sx={{ fontSize: "1.33rem", pt: 1 }}><span style={{fontWeight: 600}}>Seguridad: </span>Utiliza al menos 8 caracteres, elige una contraseña segura y no la utilices en otras cuentas</Typography>

                            <Formik initialValues={initialValuesNew} validationSchema={validationSchemaNew} onSubmit={handleChangePass}>
                                {(props) => (

                                    <Form>
                                        <Grid container spacing={2} sx={{ pt: 4 }}>
                                            <Grid item xs={12}>
                                                
                                                <Field as={TextField} variant="outlined" name='newpassword' label='Ingresa tu nueva contraseña' type="password" fullWidth
                                                    error={props.errors.newpassword && props.touched.newpassword}
                                                    helperText={<ErrorMessage name='newpassword' />} required />
                                            </Grid>

                                            <Grid item xs={12}>
                                                
                                                <Field as={TextField} variant="outlined" name='newpassword2' label='Confirma tu nueva contraseña' type="password" fullWidth
                                                    error={props.errors.newpassword2 && props.touched.newpassword2}
                                                    helperText={<ErrorMessage name='newpassword2' />} required />
                                            </Grid>
                                        </Grid>

                                        <Grid container justifyContent="flex-end" sx={{ pb: 4 }}>
                                            <LoadingButton loading={loadingNewPass} type='submit' variant='contained' color='primary' sx={{ my: 2 }}>
                                                Cambiar Contraseña
                                            </LoadingButton>
                                        </Grid>

                                    </Form>

                                )}
                            </Formik>

                        </Grid>

                    </Grid>
                </Container>
                }

            </Box>

            <DialogChangePass statusdialog = {openDialog}/>

        </>
    );
}

export default ChangePassword;