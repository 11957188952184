import axios from 'axios';
import appConfig from '../config/main.config';
const apiserv = appConfig.url_api;

export const getDetailDomain = (tld, type, currency) => {
    const api = `${apiserv}/domains/details/`;

    const data = {
        extension: tld,
        type: type,
        currency: currency
    }

    const domain = axios.post(api, data)
        .then(res => {
            return { ...res.data, ...{ error: false } };
        })
        .catch((error) => {
            return {error: true}
            /*if(error.response.status === 404){
                return { ...{message: error.message}, ...{error: true} }
            }else{
                return { ...error.response.data, ...{error: true} }
            }*/
    });

    return domain;
}

export const getRenewDomainInvoice = (datadomain) => {
    const api = `${apiserv}/domains/${datadomain.id}/renewinvoices/`;

    const data = {
        domain: datadomain
    }

    const invoice = axios.post(api, data)
        .then(res => {
            return { ...res.data, ...{ error: false } };
        })
        .catch((error) => {
            return {error: true}
    });

    return invoice;
}

export const verifyRenewDomain = (domainid, userid) => { //asegurar header
    const api = `${apiserv}/domains/${domainid}/invoices/`;

    const data = {
        domainid: domainid,
        userid: userid
    }

    const invoice = axios.post(api, data)
        .then(res => {
            return { ...res.data, ...{ error: false } };
        })
        .catch((error) => {
            return {error: true}
    });

    return invoice;
}