import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';
import blue from '@material-ui/core/colors/blue';
//import GorditaReg from '../fonts/Gordita-Regular.woff2';

/*const gordita = {
  fontFamily: 'Gordita',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Gordita'),
    local('Gordita Regular'),
    url(${GorditaReg}) format('woff2')
  `,
}; */

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#F4F6F8',
      paper: colors.common.white
    },
    primary: {
      contrastText: '#ffffff',
      main: '#5F81FF',
      light: '#98b0ff',
      dark: '#0b55cb'
    },
    secondary: {
      main: '#587bff',
      light: '#f0eefd'
    },
    success: {
      main: '#4caf50',
      light: '#5dd361',
      dark: '#4b9b4f',
      contrastText: '#fff'
    },
    text: {
      primary: '#525F7F',
    },
    gray: {
      cust1Nk: '#434c5e',
    },
    colornk: {
      grey1: '#8792a2',
      grey2: '#697386',
      grey3: '#8898aa',
      grey4: '#7f8084',
      bluedark: '#525f7f',
      bluedark2: '#73859d'
    }

  },
  shadows,
  typography,

  /*overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [gordita],
      },
    },
  }, */

  components: {
    MuiContainer: {
      variants: [{
        props: { variant: 'cont-nk' },
        style: {
          borderRadius: '8px',
          boxShadow: '0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)',
          marginBottom: '4%'
        }
      },
      {
        props: { variant: 'cont-boxnk' },
        style: {
          /*marginTop: "2%",
          border: "1px solid #eee",
          borderRadius: "5px",
          paddingBottom: "8px" */
          /*boxShadow: '0 0 2px 0 rgb(145 158 171 / 24%), 0 10px 20px -4px rgb(145 158 171 / 24%)',
          marginTop: '3%',
          paddingTop: '1px',
          paddingBottom: '8px',
          borderRadius: '5px' */
          /*borderBottom: '1px solid #e8ebf3',
          paddingTop: '1%',
          paddingBottom: '2.3%'*/
        }
      }]
    },
    MuiDivider: {
      variants: [{
        props: { variant: 'div-nk' },
        style: {
          borderColor: '#e8ebf3',
          marginTop: '2%',
          marginBottom: '2.2%'
        }
      }]
    },

    MuiIconButton: {
      variants: [{
        props: { variant: 'calButton' },
        style: {
          backgroundColor: '#ccf7f5',
        },
      },
      ]
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#adb5c3',
        },
      },
    },

    MuiTableContainer: {
      variants: [{
        props: { variant: 'tbLight-nk' },
        style: {
          boxShadow: 'none',
          '& .MuiTableHead-root': {
            '& .MuiTableCell-head': {
              fontSize: '1.33rem'
            }
          },
          '& .MuiTableBody-root': {
            '& .MuiTableCell-root': {
              fontSize: '1.33rem', //'0.9rem'
            }
          },
          '& .MuiTableRow-root.MuiTableRow-hover:hover': {
            backgroundColor: '#fcfcfc'
          }
        }
      },
      {
        props: { variant: 'tbLight2-nk' },
        style: {
          boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 15%)',
          '& .MuiTableHead-root': {
            '& .MuiTableCell-head': {
              fontSize: '1.33rem'
            }
          },
          '& .MuiTableBody-root': {
            '& .MuiTableCell-root': {
              fontSize: '1.33rem', //'0.9rem'
              borderBottom: '1px solid #ebebeb'
            }
          }
        }
      },
      {
        props: { variant: 'tbLight3-nk' },
        style: {
          boxShadow: 'none',
          '& .MuiTableHead-root': {
            '& .MuiTableCell-head': {
              fontSize: '1.33rem'
            }
          },
          '& .MuiTableBody-root': {
            '& .MuiTableCell-root': {
              fontSize: '1.37rem',
              padding: '12px 16px'
            }
          },
          '& .MuiTableRow-root.MuiTableRow-hover:hover': {
            backgroundColor: '#fcfcfc'
          }
        }
      },

      ]
    },

    MuiTypography: {
      variants: [{
        props: { variant: 'hightlightText-nk' },
        style: {
          fontSize: '1.3rem',
          fontWeight: 600
        }
      },
      {
        props: { variant: 'simpleText-nk' },
        style: {
          fontSize: '1.3rem',
        }
      },
      {
        props: { variant: 'txtSummary-nk' },
        style: {
          fontSize: '1.4rem'
        }
      },
      {
        props: { variant: 'txtConfig-nk' },
        style: {
          fontSize: '1.38rem'
        }
      },
      {
        props: { variant: 'txtButtonAttach-nk' },
        style: {
          fontSize: "1.35rem", border: "none", backgroundColor: "#fff", display: "block", "&:hover":{color: "#5F81FF"}
        }
      }


      ]
    },

    MuiTab: {
      variants: [{
        props: { variant: 'btnTab-nk' },
        style: {
          fontSize: '1.2rem',
          color: '#8a8c91',
          fontWeight: 600,
          minWidth: '100px !important',
          '&.css-9c18rc-MuiButtonBase-root-MuiTab-root': {
            minWidth: '100px'
          }
        }
      }]
    },

    MuiFormControlLabel: {
      variants: [{
        props: { variant: 'labelPay-nk' },
        style: {
          backgroundImage: "url(/static/images/payments/credit_card.webp)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "22% 80%",
          backgroundSize: "40%",
          height: "88px",
          display: "-webkit-inline-box",
          display: "inline-block",
          width: "45%",
          margin: "1.5%",
          paddingTop: "0.8%",
          borderRadius: "5px",
          border: "1px solid #dadce0",
          '&:focus-within': {
            border: "1px solid #5F81FF",
          },
          '& .MuiTypography-root': {
            fontSize: '1.4rem',
            fontWeight: 600,
          }
        }
      },

      {
        props: { variant: 'labelCycleNoCheck-nk' },
        style: {
          border: '1px solid #dadce0',
          borderRadius: '5px',
          padding: '0 40px 18px',
        }
      },

      {
        props: { variant: 'labelCycleCheck-nk' },
        style: {
          border: '1px solid #5f81ff',
          borderRadius: '5px',
          padding: '0 40px 18px',
          '& .MuiTypography-txtDiscount-nk': {
            backgroundColor: '#5f81ff',
            color: '#fff'
          },
        }
      }

      ]
    },

    MuiChip: {
      variants: [{
        props: { variant: 'chipfree-nk' },
        style: {
          borderRadius: 5, height: '20px', backgroundColor: '#f154de', color: '#fff'
        }
      }]
    },

    MuiPaper: {
      variants: [
        {
          props: { variant: 'homedomain-nk' },
          style: {
            /*backgroundColor: "#fff", borderRadius: "12px", color: "#fff", padding: "7.2% 4%", position: "relative", overflow: "hidden",*/
            overflow: "hidden",
            position: "relative",
            padding: "7.2% 4%",
            borderRadius: '12px',
            boxShadow: '0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)',
            '&:before': {
              content: "''",
              position: "absolute",
              width: "210px",
              height: "210px",
              //background: "rgb(69, 39, 160)",
              background: "rgb(194 176 249)",
              borderRadius: "50%",
              top: "-125px",
              right: "-15px",
              opacity: "0.5",
            },
            '&:after': {
              content: "''",
              position: "absolute",
              width: "210px",
              height: "210px",
              background: "rgb(186 163 255 / 32%)",
              //background: "rgb(69, 39, 160)",
              borderRadius: "50%",
              top: "-85px",
              right: "-95px",
            }
          }
        },
        {
          props: { variant: 'homehost-nk' },
          style: {
            /*backgroundColor: "#1F88E5", borderRadius: "12px", color: "#fff", padding: "7.2% 4%", position: "relative", overflow: "hidden",*/
            overflow: "hidden",
            position: "relative",
            padding: "7.2% 4%",
            borderRadius: '12px',
            boxShadow: '0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)',
            '&:before': {
              content: "''",
              position: "absolute",
              width: "210px",
              height: "210px",
              //background: "rgb(21, 101, 192)",
              background: "rgb(131 189 254)",
              borderRadius: "50%",
              top: "-125px",
              right: "-15px",
              opacity: "0.5",
            },
            '&:after': {
              content: "''",
              position: "absolute",
              width: "210px",
              height: "210px",
              //background: "rgb(21, 101, 192)",
              background: "rgb(84 164 255 / 37%)",
              borderRadius: "50%",
              top: "-85px",
              right: "-95px",
            }
          }
        },
        {
          props: { variant: 'homesupport-nk' },
          style: {
            /*backgroundColor: "#1F88E5", borderRadius: "12px", color: "#fff", padding: "3.5% 4%", position: "relative", overflow: "hidden",*/
            overflow: "hidden",
            position: "relative",
            padding: "3.5% 4%",
            borderRadius: '12px',
            boxShadow: '0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)',
            '&:before': {
              content: "''",
              position: "absolute",
              width: "210px",
              height: "210px",
              background: "linear-gradient(140.9deg, rgb(144, 202, 249) -14.02%, rgba(144, 202, 249, 0) 77.58%)",
              borderRadius: "50%",
              top: "-160px",
              right: "-130px"
            },
            '&:after': {
              content: "''",
              position: "absolute",
              width: "210px",
              height: "210px",
              background: "linear-gradient(210.04deg, rgb(144, 202, 249) -50.94%, rgba(144, 202, 249, 0) 83.49%)",
              borderRadius: "50%",
              top: "-30px",
              right: "-180px",
            }
          }
        },
        {
          props: { variant: 'homefaq-nk' },
          style: {
            /*backgroundColor: "#fff8e4", borderRadius: "12px", color: "#fff", padding: "3.5% 4%", position: "relative", overflow: "hidden",*/
            overflow: "hidden",
            position: "relative",
            padding: "3.5% 4%",
            borderRadius: '12px',
            boxShadow: '0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)',
            '&:before': {
              content: "''",
              position: "absolute",
              width: "210px",
              height: "210px",
              background: "linear-gradient(140.9deg, rgb(249 237 144) -14.02%, rgba(144, 202, 249, 0) 77.58%);",
              borderRadius: "50%",
              top: "-160px",
              right: "-130px"
            },
            '&:after': {
              content: "''",
              position: "absolute",
              width: "210px",
              height: "210px",
              background: "linear-gradient(210.04deg, rgb(249 219 144) -50.94%, rgba(144, 202, 249, 0) 83.49%)",
              borderRadius: "50%",
              top: "-30px",
              right: "-180px",
            }
          }
        },
        {
          props: { variant: 'homenotices-nk' },
          style: {
            backgroundColor: "rgb(255, 255, 255)",
            color: "rgb(33, 43, 54)",
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            backgroundImage: "none",
            overflow: "hidden",
            boxShadow: "rgb(145 158 171 / 20%) 0px 3px 1px -2px, rgb(145 158 171 / 14%) 0px 2px 2px 0px, rgb(145 158 171 / 12%) 0px 1px 5px 0px",
            borderRadius: "16px",
            position: "relative",
            zIndex: "0",
          }
        },
      ]
    },

    MuiTooltip: {
      variants: [{
        props: { variant: 'tooltipinv-nk' },
        style: {
          '& .MuiTooltip-tooltip':{
            backgroundColor: 'rgba(97, 97, 97, 0.92)',
            borderRadius: '4px',
            color: '#fff',
            padding: '4px 8px',
            fontSize: '1.1rem',
            maxWidth: '500px',
            margin: '0',
            wordWrap: 'break-word',
            fontWeight: '500',
            position: 'relative'
          }

        }
      }]
    },

    MuiDialogTitle: {
      variants: [{
        props: { variant: 'dialogTitle-nk'},
        style: {
          fontSize: '1.8rem'
        }
      }]
    }

    /*MuiButtonBase: {
      variants: [{
        props: {variant: 'btnSelected-nk'},
        style: {
          backgroundColor: '#e1e1fd'
        }
      }]
    }, */

    /*MuiTableRow: {
      variants: [{
        props: {variant: 'rowtbLight-nk'},
        style: {
          fontSize: '1.33rem'
        },
      }]
    },*/

    /*MuiTableCell: {
      variants: [{
        props: {variant: 'celltbLight-nk'},
        style: {
          fontSize: '1.33rem'
        }
      }]
    }, */

  }


});

export default theme;