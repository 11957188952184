import { Outlet } from 'react-router-dom';
import { experimentalStyled, Box, Container } from '@material-ui/core';
import MainNavbar from './MainNavbar';
import NeoNavbar from './NeoNavbar';

const MainLayoutRoot = experimentalStyled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    /*display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'*/
  })
);

const CartLayout = () => (
  <MainLayoutRoot>
    
    <NeoNavbar/>
      <MainNavbar />
      <Box>
        <Container style={{marginTop: '8%'}}>
          <Outlet />
        </Container>
      </Box>
  </MainLayoutRoot>
);

export default CartLayout;
