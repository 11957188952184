import axios from 'axios';
import appConfig from '../config/main.config';
const apiserv = appConfig.url_api;

//pago paypal
export const registerPayPalPayment = async (details) => {
    //console.log('data enviada a paaydout BD', details)

    const res = await axios.post(`${apiserv}/gateways/paypal`, details)
        .then(function (response) {
            //console.log(response)
            return { ...response.data, ...{ error: false } };
        })
        .catch(function (error) {
            //console.log('da error', error);
            return { error: true };
        });

    //const res = 'entro setPayout'

    return res
}

export const createOrderPaypal = async (details) => {
    const res = await axios.post(`${apiserv}/gateways/create-paypal-order`, details)
    .then(function (response){
        return { ...response.data, ...{error: false} }
    })
    .catch(function (error){
        return { error: true }
    });

    return res;
}

export const captureOrderPaypal = async(orderID) => {
    const res = await axios.post(`${apiserv}/gateways/capture-paypal-order/${orderID}`)
    .then(function (response){
        return response.data
    })
    .catch(function (error){
        console.log('error', error.response.data);
        return error.response.data
    })

    return res;
}

//pago 2Checkout
export const register2COReturnUrl = async (details) => {
    const data = {
        gateway: 'tco',
        data: details
    };

    const res = await axios.post(`${apiserv}/gateways/2checkout`, data)
        .then(function (response) {
            //console.log(response)
            return {...response.data, ...{error: false}};
        })
        .catch(function (error) {
            //console.log('da error', error);
            return { error: true };
        });

    return res
}

/*export const register2COPayment = async (details, invoice) => {
    //console.log('data enviada a paydout 2co BD', details, invoice)

    const data = {
        datagateway: details,
        datainvoice: invoice
    }

    const res = await axios.post(`${apiserv}/gateways/2checkout`, data)
        .then(function (response) {
            //console.log(response)
            return response.data;
        })
        .catch(function (error) {
            //console.log('da error', error);
        });

    return res
} */

export const verifyKey2CO = async (details) => {
    //console.log('data enviada a paaydout BD', details)

    const res = await axios.post(`${apiserv}/gateways/2checkout/verified-key/`, details)
        .then(function (response) {
            //console.log(response)
            return {...response.data, ...{error: false}};
        })
        .catch(function (error) {
            //console.log('da error', error);
            return { error: true };
        });

    //const res = 'entro setPayout'

    return res
}

//pago stripe
export const iniSessionStripe = async (details) => {
    //console.log('data enviada inisession Stripe BD', details)
    const data = {
        invoiceid: details.invoiceid,
        total: details.total,
        userid: details.client_id,
        client_email: details.client_email
    }

    const res = await axios.post(`${apiserv}/gateways/stripe/create-checkout-session`, data)
        .then(function (response) {
            //console.log('response dentro de ini', response)
            return response.data;
        })
        .catch(function (error) {
            //console.log('da error', error);
        });

    return res
}

export const registerStripePayment = async (detpay, invoice) => {

    const data = {
        datagateway: detpay,
        datainvoice: invoice
    }

    //console.log('data enviada a stripe BD', data)

    const res = await axios.post(`${apiserv}/gateways/stripe/`, data)
        .then(function (response) {
            //console.log(response)
            return { ...response.data, ...{ error: false } };
        })
        .catch(function (error) {
            //console.log('da error', error);
            return { error: true };
        });

    //const res = 'entro setPayout'

    return res
};

export const checkoutSessionStripe = async (sessionId) => {

    const response = await fetch(`${apiserv}/gateways/stripe/checkout-session?sessionId=${sessionId}`)
        .then(res => { return res })
        .catch(err => { /*console.log(err)*/ });
    const body = await response.json();

    return body;

}

//stripe elements
export const createStripePaymentIntent = async (details) => {
    const data = {
        invoiceid: details.invoiceid,
        total: details.total,
        userid: details.client_id,
        client_email: details.client_email
    }
    const endpoint = `${apiserv}/gateways/stripe/create-payment-intent/`;
    const res  =  await axios.post(endpoint, data)
        .then(function (response) {
            return {...response.data, ...{error: false}};
        })
        .catch(function (response){
            return {error: true}
        });

    return res;
};

export const createStripePaymentIntentTest = async (details) => {
    const data = {
        invoiceid: details.invoiceid,
        total: details.total,
        userid: details.client_id,
        client_email: details.client_email
    }
    const endpoint = `${apiserv}/gateways/stripe/test-create-payment-intent/`;
    const res  =  await axios.post(endpoint, data)
        .then(function (response) {
            return {...response.data, ...{error: false}};
        })
        .catch(function (response){
            return {error: true}
        });

    return res;
};

//fin stripe elements

//pago payu
export const registerPayuPayment = async (detpay, invoice) => {

    const data = {
        datagateway: detpay,
        datainvoice: invoice
    }

    //console.log('data enviada a payu BD', data)

    const res = await axios.post(`${apiserv}/gateways/payu/`, data)
        .then(function (response) {
            //console.log(response)
            return response.data;
        })
        .catch(function (error) {
            //console.log('da error', error);
        });

    return res
}
