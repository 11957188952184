import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import appConfig from '../../config/main.config'
import { hashString, number_format } from '../../utils/index'
import { registerPayuPayment } from '../../services/Payment';
import SpinnerLoad from '../FormsUI/Spinner';

function ReturnPayu() {
    const navigate = useNavigate();
    const { user_id, invoice_id } = useParams();
    const urlParams = new URLSearchParams(useLocation().search);

    const datainvoice = {
        userid: user_id,
        currency: 0,
        gateway: 'payu',
        description: 'Invoice Payment',
        invoiceid: invoice_id
    }

    useEffect(() => {

        if (urlParams.get('signature')) {

            const apiKey = appConfig.apikey_payu;
            let statusTx = ''

            if (urlParams.get('transactionState') === 4) {
                statusTx = "Transaction approved"
            }
            else if (urlParams.get('transactionState') === 6) {
                statusTx = "Transaction rejected"
            }
            else if (urlParams.get('transactionState') === 104) {
                statusTx = "Error"
            }
            else if (urlParams.get('transactionState') === 7) {
                statusTx = "Pending payment"
            }
            else {
                statusTx = urlParams.get('message')
            }

            //create hash
            const merchant_id = urlParams.get('merchantId')
            const reference_code = urlParams.get('referenceCode')
            const txValue = urlParams.get('TX_VALUE') //new_value: number_format($TX_VALUE, 1, '.', '')
            const nvalue = number_format(txValue, 1, '.', '');
            console.log('valor n pass format_number', nvalue)
            const ncurrency = urlParams.get('currency')
            const transaction_state = urlParams.get('transactionState')
            const signString = apiKey + '~' + merchant_id + '~' + reference_code + '~' + nvalue + '~' + ncurrency + '~' + transaction_state
            const signHashNew = hashString(signString);
            console.log('nuevo calor hash string', signHashNew)

            const newdata = {
                merchantId: merchant_id,
                referenceCode: reference_code,
                tx_value: txValue,
                new_value: nvalue,
                currency: ncurrency,
                transactionState: transaction_state,
                sign_string: signString,
                sign_create: signHashNew,
                signature: urlParams.get('signature'),
                reference_pol: urlParams.get('reference_pol'),
                cus: urlParams.get('cus'),
                description: urlParams.get('description'),
                pseBank: urlParams.get('pseBank'),
                lapPaymentMethod: urlParams.get('lapPaymentMethod'),
                transactionId: urlParams.get('transactionId'),
                status: statusTx,
                date: urlParams.get('processingDate'),
                merchant_name: urlParams.get('merchant_name'),
                merchant_address: urlParams.get('merchant_address'),
                telephone: urlParams.get('telephone'),
                merchant_url: urlParams.get('merchant_url'),
                lapTransactionState: urlParams.get('lapTransactionState'),
                message: urlParams.get('message'),
                trazabilityCode: urlParams.get('trazabilityCode'),
                orderLanguage: urlParams.get('orderLanguage'),
                extra1: urlParams.get('extra1'),
                extra2: urlParams.get('extra2'),
                extra3: urlParams.get('extra3'),
                polTransactionState: urlParams.get('polTransactionState'),
                polResponseCode: urlParams.get('polResponseCode'),
                lapResponseCode: urlParams.get('lapResponseCode'),
                risk: urlParams.get('risk'),
                polPaymentMethod: urlParams.get('polPaymentMethod'),
                polPaymentMethodType: urlParams.get('polPaymentMethodType'),
                lapPaymentMethodType: urlParams.get('lapPaymentMethodType'),
                installmentsNumber: urlParams.get('installmentsNumber'),
                tx_tax: urlParams.get('tx_tax'),
                lng: urlParams.get('lng'),
                pseCycle: urlParams.get('pseCycle'),
                buyerEmail: urlParams.get('buyerEmail'),
                pseReference1: urlParams.get('pseReference1'),
                pseReference2: urlParams.get('pseReference2'),
                pseReference3: urlParams.get('pseReference3'),
                authorizationCode: urlParams.get('authorizationCode'),
                tx_administrative_fee: urlParams.get('tx_administrative_fee'),
                tx_tax_administrative_fee: urlParams.get('tx_tax_administrative_fee'),
                tx_tax_administrative_fee_return_base: urlParams.get('tx_tax_administrative_fee_return_base'),
            }

            /*console.log('sign string', signString)
            console.log('number redondeado', nvalue)
            console.log('key generate', newdata.sign_create)
            console.log('key enviado', newdata.signature) */

            //save DB
            if (newdata.sign_create === newdata.signature) {
                (async function () {
                    const status = await registerPayuPayment(newdata, datainvoice);
                    //console.log('status paydout payu', status)
                    navigate('/clientarea/success-payment/');
                })()
            }

        }


    }, [])


    return (
        <Grid>
            <SpinnerLoad />
        </Grid>

    );
}

export default ReturnPayu;