import axios from 'axios';
import appConfig from '../config/main.config';
import { logout } from '../hooks/useAuth'
const apiserv = appConfig.url_api;

//datos de cliente con metodos de pago
export const getDataClient = () => {
    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/clients/data/`;

    const client = axios.get(api, { headers: { "Authorization": `Bearer ${token}` } })
        .then(res => {
            //console.log('res data client', res.data)
            return {...res.data, ...{error: false}};
        })
        .catch((error) => {
            //console.log('dio error client', error.response.data)
            return {error: true, message: error.response}
        });

    return client;

};

//datos cliente
export const getBasicDataClient = () => {
    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/clients/`;

    const client = axios.get(api, { headers: { "Authorization": `Bearer ${token}` } })
        .then(res => {
            //console.log('res data client', res.data)
            return { ...res.data, ...{ error: false } };
        })
        .catch((error) => {
            //console.log('dio error client', error.response.data)
            //console.log(error.response);
            /*if(error.response.status === 401){
                logout();
            }*/
            return {error: true, message: error.response}
            
        });

    return client;
}

//actualizar pgdefault client
/*export const updatePaymentClient = (val) => {
    //console.log('Ingresa a funcion pago')
    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    //const api = `/clients/pgdefault/${uid}`;//25474
    const api = `${apiserv}/clients/pgdefault/${val.uid}`;

    const payinvoice = axios.put(api, val, { headers: { "Authorization": `Bearer ${token}` } })
        .then(res => {
            //console.log('res data', res.data)
            return res.data;
        })
        .catch((error) => {

            //console.log(error.config);

        });

    return payinvoice;

} */

/*export default async function getDataClient(){
    const uid = JSON.parse(localStorage.getItem('_cli_storage_')).uid;
    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `/clients/${uid}`;

    const client = axios.get(api, { headers: { "Authorization": `Bearer ${token}` } })
            .then(res => {
                //console.log('res data', res.data)
                return res.data;
            })
            .catch((error) => {

                // Error
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log('response error');
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the
                    // browser and an instance of
                    // http.ClientRequest in node.js
                    console.log('request error')
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);

            });

    return client;

}*/