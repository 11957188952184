export default {
  fontSize:14,
  fontFamily: [
    //'Gordita',
    'Poppins',
    'Open Sans',
    'Arial',
    'Helvetica',
    'sans-serif',
  ].join(','),
    h1: {
      fontWeight: 500,
      fontSize: 32,
      letterSpacing: '-0.24px'
    },
    h2: {
      fontWeight: 500,
      fontSize: 29,
      letterSpacing: '-0.24px'
    },
    h3: {
      fontWeight: 500,
      fontSize: 24,
      letterSpacing: '-0.06px'
    },
    h4: {
      fontWeight: 500,
      fontSize: '1.7rem',
      //fontSize: 15,
      letterSpacing: '-0.06px',
      //fontWeight: 700,
      //textTransform: 'uppercase'
    },
    h5: {
      fontWeight: 600,
      fontSize: 14,
      letterSpacing: '-0.05px',
      textTransform: 'uppercase'
    },
    h6: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: '-0.05px'
    },
    htmlFontSize: 10,
    overline: {
      fontWeight: 500
    },
    body2: {
      fontSize: '1.4rem',
    },
    subtitule1: {
      fontSize: '1.6rem',
      fontWeight: 500
    }
  };
  