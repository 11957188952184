import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Container, Divider, FormControl, Grid, MenuItem, Select, Typography, Checkbox, Button } from '@material-ui/core';
import SpinnerLoad from '../../components/FormsUI/Spinner';
import LanguageIcon from '@mui/icons-material/Language';
import CheckBoxOutline from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import NextIcon from '@material-ui/icons/ArrowForward';
import { getDetailDomain, getRenewDomainInvoice } from '../../services/Domain';
import { getTldDomain } from '../../utils';

const RenewDomain = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [domain, setDomain] = useState(null);
    const [detailTld, setDetailTld] = useState(null);
    const [anio, setAnio] = useState(1);
    const [priceDomain, setPriceDomain] = useState(13.99);
    const [subtotalAdd, setSubtotalAdd] = useState(0.00);
    const [domainAddons, setDomainAddons] = useState([]);

    const [total, setTotal] = useState(13.99);

    const handleChangeCycle = (ev) => {
        //console.log('event cycle', ev.target.value);
        const anioselect = ev.target.value;
        //busca index de anio seleccionado
        const indexcycle = detailTld.cycles.findIndex(x => x.id === anioselect);

        //calcular precio total
        let subtotaladd = 0.00;
        if (domainAddons.length !== 0) {
            domainAddons.map((addon) => {
                subtotaladd = subtotaladd + (addon.price * anioselect);
                //console.log("subtotaladd", subtotaladd);
            })
        }
        const totalpay = (detailTld.cycles[indexcycle].price + subtotaladd).toFixed(2);

        setAnio(detailTld.cycles[indexcycle].id);
        setPriceDomain(detailTld.cycles[indexcycle].price);
        setTotal(totalpay);
    }

    const generateInvoice = async () => {
        const domainAddonsUpdate = getAddonsDomain();
        const datadomain = domain;
        datadomain["newregistrationperiod"] = anio;
        datadomain["domainaddons"] = domainAddonsUpdate;
        datadomain["total"] = total;

        const renewInvoice = await getRenewDomainInvoice(datadomain);

        if(renewInvoice.error === false){
            navigate(`/clientarea/invoices/${renewInvoice.id}/`, {replace: true});
        }

    }
    const getAddonsDomain = () => {
        const addons = [];

        domainAddons.map((addon) => {
            let priceaddon = addon.price * anio;
            let infoaddon = {
                id: addon.id,
                name: addon.name,
                price: priceaddon
            }
            addons.push(infoaddon);
        })

        return addons;
    }



    useEffect(() => {
        const getDomain = async () => {
            if (location.state && location.state.domain) {

                const domain = location.state.domain;

                const tld = getTldDomain(domain.domain);
                const type = "domainrenew";
                const currency = 1;
                const detailTld = await getDetailDomain(tld, type, currency);

                //inicializar newregistrationperiod
                domain.newregistrationperiod = domain.registrationperiod;

                //inicializar periodo y precio de dominio
                let aniodomain = domain.registrationperiod;
                let pricedomain = 13.99;
                const iscyclerenew = detailTld.cycles.find(e => e.id === aniodomain);
                if (!iscyclerenew) {
                    aniodomain = detailTld.cycles[detailTld.cycles.length - 1].id;
                    pricedomain = detailTld.cycles[detailTld.cycles.length - 1].price;
                } else {
                    pricedomain = iscyclerenew.price;
                }

                //filtrar addons
                const arrayaddons = [];
                if (domain.dnsmanagement === 1) {
                    const dnsaddon = detailTld.addons.find(addon => addon.id === "msetupfee");
                    arrayaddons.push(dnsaddon);
                }
                if (domain.emailforwarding === 1) {
                    const dnsaddon = detailTld.addons.find(addon => addon.id === "qsetupfee");
                    arrayaddons.push(dnsaddon);
                }
                if (domain.idprotection === 1) {
                    const dnsaddon = detailTld.addons.find(addon => addon.id === "ssetupfee");
                    arrayaddons.push(dnsaddon);
                }

                //calcular total dominio y addons
                let subtotaladd = 0.00;

                if (arrayaddons.length !== 0) {
                    arrayaddons.map((addon) => {
                        subtotaladd = subtotaladd + (addon.price * aniodomain);
                        //console.log("subtotaladd", subtotaladd);
                    })

                }

                const totalpay = (pricedomain + subtotaladd).toFixed(2);

                setDomainAddons(arrayaddons);
                setDetailTld(detailTld);
                setDomain(domain);
                setAnio(aniodomain);
                setPriceDomain(pricedomain);
                setTotal(totalpay);
            } else {
                navigate("/clientarea/domains/", { replace: true });
            }
        }

        getDomain();
    }, [])

    return (
        <>
            <Helmet>
                <title>Portal - Área de clientes de Neothek</title>
            </Helmet>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center'
                }}
            >
                {domain === null ?
                    <SpinnerLoad />
                    :

                    <Container maxWidth="md" variant='cont-nk'>

                        <Container variant='cont-boxnk'>
                            <Grid item xs={12}>
                                <Box mt={2} mb={3} >
                                    <Typography variant='h4'>Renovación de Dominio</Typography>
                                </Box>
                            </Grid>

                            <Grid container>
                                <Grid item xs={12} sm={4} mb={5}>
                                    <Typography sx={{ fontSize: '13px', color: '#6c757d', textAlign: 'center', pb: 2 }}>Dominio</Typography>
                                    <Grid container justifyContent="center">
                                        <LanguageIcon sx={{ fontSize: '40px', textAlign: 'center' }} color="primary" />
                                    </Grid>
                                    <Typography sx={{ fontSize: '13px', textAlign: 'center' }} color="colornk.bluedark"><strong>{domain.domain}</strong></Typography>
                                </Grid>

                                <Grid item xs={12} sm={4} mb={5}>
                                    <Typography sx={{ fontSize: '13px', color: '#6c757d', textAlign: 'center', pb: 2 }}>Periodo</Typography>
                                    <Grid container justifyContent={"center"}>
                                        <FormControl variant="outlined" sx={{ align: 'center' }} >
                                            <Select
                                                value={anio}
                                                displayEmpty
                                                onChange={handleChangeCycle}
                                                sx={{ '& .MuiSelect-root': { padding: '10px 14px' } }}
                                            >
                                                {detailTld.cycles.map(({ id }) => (
                                                    <MenuItem key={id} value={id} >{id} Año(s)</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} mb={5}>
                                    <Typography sx={{ fontSize: '13px', color: '#6c757d', textAlign: 'center', pb: 2 }}>Precio</Typography>
                                    <Grid container justifyContent="center" direction="column">
                                        <Typography sx={{ textAlign: 'center' }} color="colornk.bluedark" variant="txtSummary-nk" ><strong>US$ {priceDomain} <span style={{ fontSize: '12px' }}> /{anio} año(s)</span></strong></Typography>
                                    </Grid>
                                </Grid>

                                <Divider variant='div-nk' />
                            </Grid>

                        </Container>

                        {/* sumario pedido */}

                        <Container>
                            <Grid item xs={12}>
                                <Box mt={4} mb={2} >
                                    <Typography variant='h4'>Sumario de Pedido</Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} >
                                <Container sx={{ backgroundColor: "#F6F9FC", borderRadius: "5px", p: 1, paddingTop: "4%" }}>
                                    <Grid container spacing={2} sx={{ mb: 2 }}>
                                        <Grid item sm={8} xs={9} style={{ paddingTop: '8px' }}>
                                            <Typography color="colornk.bluedark" variant="txtSummary-nk">Renovación de Dominio - {domain.domain} / {anio} año(s)</Typography>
                                        </Grid>
                                        <Grid item sm={4} xs={3} style={{ paddingTop: '8px', textAlign: 'right' }}>
                                            <Typography color="colornk.bluedark" variant="txtSummary-nk">US$ {total}</Typography>
                                        </Grid>

                                        {domainAddons.length !== 0 &&
                                            <Grid item sm={12} xs={12} sx={{ paddingTop: "0px" }}>
                                                {domainAddons.map(({ id, name }) => (
                                                    <Typography key={id} color='colornk.grey3' variant="txtConfig-nk" sx={{ display: "block" }} >{`\xa0\xa0+ ${name}`}</Typography>
                                                ))}
                                            </Grid>
                                        }

                                        {/*<Grid item sm={4} xs={3} style={{ paddingTop: '13px', textAlign: 'right' }}>
                                            <Typography color="colornk.bluedark" variant="txtSummary-nk" >US$ {subTotalAdd}</Typography>
                                        </Grid>

                                        <Grid container spacing={2} sx={{ paddingLeft: 2, mt: 2 }}>

                                            <Grid item xs={12} sx={{ borderTop: '1px solid #e6ebf1' }} style={{ paddingTop: '0px' }}></Grid>

                                            <Grid item sm={8} xs={8}>
                                                <Typography color="colornk.bluedark" variant="txtSummary-nk"><strong>Subtotal</strong></Typography>
                                            </Grid>
                                            <Grid item sm={4} xs={4} sx={{ textAlign: "right" }}>
                                                <Typography color="colornk.bluedark" variant="txtSummary-nk"><strong>US$ {subtotal}</strong></Typography>
                                            </Grid>

                                            <Grid item sm={8} xs={8} style={{ paddingTop: '13px' }}>
                                                <Typography color="colornk.bluedark" variant="txtSummary-nk"><strong>Total</strong></Typography>
                                            </Grid>
                                            <Grid item sm={4} xs={4} style={{ paddingTop: '13px', textAlign: 'right' }}>
                                                <Typography color="colornk.bluedark" variant="txtSummary-nk"><strong>US$ {total}</strong></Typography>
                                            </Grid>
                                        </Grid> */}
                                    </Grid>
                                </Container>
                            </Grid>

                        </Container>

                        <Grid container justifyContent="flex-end" alignItems="center" >
                            <Box sx={{ py: 3 }}>
                                <Button variant='contained' color='success' onClick={generateInvoice} size='large' endIcon={<NextIcon />}>
                                    Continuar
                                </Button>
                            </Box>
                        </Grid>


                    </Container>

                }
            </Box>

        </>

    );
}

export default RenewDomain;