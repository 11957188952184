import { Navigate } from 'react-router-dom';
import MainLayout from './components/MainLayout';
import DashboardLayout from './components/DashboardLayout'
import CartLayout from './components/CartLayout';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login/Login';
import Register from './pages/Register';
import SearchDomain from './pages/SearchDomain';
import Store from './pages/Store';
import ReviewOrder from './pages/ReviewOrder/ReviewOrder';
import Checkout from './pages/Checkout/Checkout';
import Invoice from './pages/Invoice';
import SuccessPayment from './components/payments/SuccessPayment';
import Return2CO from './components/payments/Return2CO';
import ReturnStripe from './components/payments/ReturnStripe';
import ReturnPayu from './components/payments/ReturnPayu';
import Services from './pages/Products/Services';
import Domains from './pages/Domains/Domains';
import Invoices from './pages/Invoices/Invoices';
import Notfound from './pages/NotFound';
import Addons from './pages/Addons'
import ForgetPassword from './pages/ResetPassword/ForgetPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import CheckoutClient from './pages/Checkout/CheckoutClient';
import Return2COtemp from './components/payments/Return2COtemp';
import ReturnPayutemp from './components/payments/ReturnPayutemp';
import RoutesNavigation from './components/RoutesNavigation';
import SearchDomainOrder from './pages/SearchDomainOrder';
import ReviewDomainOrder from './pages/ReviewDomainOrder';
import DomainCheckout from './pages/DomainCheckout/DomainCheckout';
import DomainCheckoutClient from './pages/DomainCheckout/DomainCheckoutClient';
import StripePaymentStatus from './components/payments/StripeReturnElement';
import StripePaymentElementTest from './pages/test-stripe-button';
import StripePaymentStatusTest from './pages/test-stripe-status';
import StripePaymentElements from './components/payments/StripePaymentElements';
import ReviewOrderExpress from './newplanes/pages/ReviewOrderExpress';
import CheckoutClientExpress from './newplanes/pages/Checkout/CheckoutClientExpress';
import CheckoutExpress from './newplanes/pages/Checkout/CheckoutExpress';
import Home from './pages/Home';
import RenewDomain from './pages/Domains/RenewDomain';
import Tickets from './pages/Soporte/Tickets';
import Ticket from './pages/Soporte/Ticket';
import OpenTicket from './pages/Soporte/OpenTicket';
import TwoStepVerification from './pages/2FA/TwoStepVerification';
import ChangeTwoStepAuth from './pages/2FA/ChangeTwoStepAuth';
import Login2FA from './pages/Login/Login2FA';
import LoginAccountClient from './pages/Login/LoginAccountClient';
import ChangePassword from './pages/Account/ChangePassword';
import Account from './pages/Account/Account';
import Announcement from './pages/Announcements/Announcement';
import Announcements from './pages/Announcements/Announcements';
import Product from './pages/Products/Product';
//import Login2FA from './pages/Login/Login2FATest';
//import Login from './pages/Login/LoginTest';

const routes = (isAuth) => [
    {
      path: '/',
      //element: <MainLayout />,
      element: isAuth ? <DashboardLayout /> : <MainLayout/>,
      children: [
        //{ path: '/', element: <Navigate to="/login/" /> },
        //{ path: '/', element: <Navigate to="/store" /> },
        { path: '/', element: <Home /> },
        { path: 'announcements', element: <Announcements/> },
        { path: 'announcements/:id', element: <Announcement/> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    /*{
      path: '/store',
      element: !isAuth ? <MainLayout /> : <Navigate to="/clientarea/dashboard" />,
      children:[
        { path: '/', element: <Store /> }
      ]
    },*/
    {
      path: '/404',
      element: isAuth ? <DashboardLayout /> : <MainLayout/>,
      children: [
        { path: '/', element: <Notfound /> },
      ]
    },
    {
      path: '/cart',
      element: isAuth ? <DashboardLayout /> : <MainLayout/>,
      children:[
        { path: 'product/:pid', element: <SearchDomain /> },
        { path: 'product/:pid/billingcycle/:cycle', element: <SearchDomain /> },
        { path: 'product/revorder', element: <ReviewOrder />},
        //{ path: 'product/regorder', element: <RegisterOrder /> },
        { path: 'product/checkout', element: isAuth ? <CheckoutClient /> : <Checkout /> },
        { path: 'domain', element: <SearchDomainOrder /> },
        { path: 'domain/revorder', element: <ReviewDomainOrder /> },
        { path: 'domain/checkout', element: isAuth ? <DomainCheckoutClient /> : <DomainCheckout /> },

        { path: 'plan/:pid', element: <SearchDomain /> },
        { path: 'plan/:pid/billingcycle/:cycle', element: <SearchDomain /> },
        { path: 'plan/revorder', element: <ReviewOrderExpress />},
        { path: 'plan/checkout', element: isAuth ? <CheckoutClientExpress /> : <CheckoutExpress />  },
        //{ path: 'stripe/payment-element', element: <StripePaymentElementTest />}, //test
        //{ path: 'stripe/payment-element/status', element: <StripePaymentStatusTest />}, //test
      ]
    },
    {
      path: '/clientarea',
      //element: isAuth ? <DashboardLayout /> : <Navigate to="/login/" />,
      element: isAuth ? <DashboardLayout /> : <RoutesNavigation />,
      children:[
        //{ path: 'dashboard', element: <Dashboard/> },
        { path: 'dashboard', element:  <Navigate to="/clientarea/products/" /> },
        { path: 'invoices/:id', element: <Invoice/> },
        { path: 'return-2co', element: <Return2COtemp/>},
        /*{ path: 'return-2co', element: <Return2CO/>}, */
        { path: 'return-stripe/:session_id/:user_id/:invoice_id', element: <ReturnStripe/> },
        { path: 'stripe-payment-elements', element: <StripePaymentElements/> }, //return Payment Element
        { path: 'return-stripe/status', element: <StripePaymentStatus/> }, //return Payment Element
        { path: 'response-payu/:user_id/:invoice_id', element: <ReturnPayutemp />},
        { path: 'confirmation-payu/:user_id/:invoice_id', element: <ReturnPayutemp />},
        /*{ path: 'response-payu/:user_id/:invoice_id', element: <ReturnPayu/>},
        { path: 'confirmation-payu/:user_id/:invoice_id', element: <ReturnPayu/>}, */
        { path: 'success-payment', element: <SuccessPayment/> },
        { path: 'products/addons/:id', element: <Addons /> },
        { path: 'products', element: <Services/> },
        { path: 'products/:pid', element: <Product />},
        { path: 'domains', element: <Domains/> },
        { path: 'invoices', element: <Invoices/> },
        { path: 'tickets', element: <Tickets/> },
        { path: 'tickets/open/', element: <OpenTicket />},
        { path: 'tickets/:ticket_id/c/:ticket_c', element: <Ticket />},
        { path: 'renewdomain', element: <RenewDomain /> },

        { path: 'account', element: <Account /> },
        { path: 'signingoptions/two-step-verification', element: <TwoStepVerification /> },
        { path: 'signingoptions/change-two-step-verification', element: <ChangeTwoStepAuth /> },
        { path: 'signingoptions/password', element: <ChangePassword /> }

      ]
    },
    {
      path: '/',
      //element: <MainLayout />,
      element: !isAuth ? <MainLayout /> : <Navigate to="/clientarea/dashboard" />,
      children: [
        { path: 'home', element: <Home /> },
        { path: 'login', element: <Login /> }, //descomentar arriba
        { path: 'login/2fa', element: <Login2FA /> }, //descomentar también arriba
        { path: 'login/accounts', element: <LoginAccountClient /> },
        { path: 'register', element: <Register /> },
        { path: 'reset', element: <ForgetPassword /> },
        { path: 'reset/password', element: <ResetPassword />},
      ]
    },
  ];
  
export default routes;

/*import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Account from 'src/pages/Account';
import CustomerList from 'src/pages/CustomerList';
import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import ProductList from 'src/pages/ProductList';
import Register from 'src/pages/Register';
import Settings from 'src/pages/Settings';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'customers', element: <CustomerList /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'products', element: <ProductList /> },
      { path: 'settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes; */
