import { Grid, Typography, Container, Box, Button, Divider, Checkbox, TextField, Select, Radio, Slider, makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import StepperCart from '../../components/FormsUI/StepperCart';
import { useNavigate, useLocation } from "react-router-dom";
import CheckBoxOutline from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { getCycleProduct, getProduct, getAddons, getMinAddons, getValIniProd, getIsFreeDomain } from '../../services/Product';
import getPriceTld from '../../services/Pricing';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { searchCoupon, calculateDiscount } from '../../services/Promotion';
import CycleRadio from './components/CycleRadio.component';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import NextIcon from '@material-ui/icons/ArrowForward';
import SpinnerLoad from '../../components/FormsUI/Spinner';

const useStyles = makeStyles((theme) => ({
    labelNameProduct:{
        fontSize: "1.35rem", backgroundColor: "#cbf4c9", color: "#268717",padding: "0rem 0.6rem", borderRadius: "8px", marginLeft: "1rem", display: "inline"
    },
    [theme.breakpoints.down('sm')]: {
       labelNameProduct: {
            display: "table",
            marginLeft: "0rem"
        }
    }
}));

const ReviewOrder = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    let idProduct = '';
    let dominio = '';
    let billingcycle = '';
    let tld = '';
    let eventDomain = 'domainregister';
    let confopt = null;
    let subopt = null;

    if (location.state !== null) {
        idProduct = location.state.product;
        dominio = location.state.dominio;
        billingcycle = location.state.billingcycle;
        tld = location.state.tld;
        eventDomain = location.state.eventDomain;

        if (location.state.confopt !== null && location.state.subopt !== null) {
            confopt = parseInt(location.state.confopt);
            subopt = parseInt(location.state.subopt);
        }
    }
    if (billingcycle === '') billingcycle = 'annually'

    const currency = 1; //permitir cambio moneda

    const [product, setProduct] = useState({});
    const [cycleProduct, setCycleProduct] = useState([]);
    const [addons, setAddons] = useState([]);
    const [minAddons, setMinAddons] = useState([]);
    const [priceProdCurrent, setPriceProdCurrent] = useState(0.00);
    const [cycleProdCurrent, setCycleProdCurrent] = useState(billingcycle);
    const [priceDomain, setPriceDomain] = useState(0.00);
    const [priceRenewDomain, setPriceRenewDomain] = useState(0.00);
    const [freeDomain, setFreeDomain] = useState(null);
    const [pricingTld, setPricingTld] = useState({});
    const [pricingRenewTld, setPricingRenewTld] = useState({});
    const [subTotalAdd, setSubTotalAdd] = useState(0.00);
    const [total, setTotal] = useState(0.00);
    const [subtotal, setSubtotal] = useState(0.00);


    //const [confOption, setConfOpt] = useState(confopt);
    const [subOptionHidden, setSubOptHidden] = useState(0);

    //console.log('location', location.state.product)

    //style calButton
    //const [variantCal, setVariantCal] = useState('');

    //coupon
    const [coupon, setCoupon] = useState(null);
    const [validCoupon, setValidCoupon] = useState(null);
    const [discount, setDiscount] = useState(0.00);
    const couponValue = {
        coupon: ''
    };
    const couponValidation = Yup.object().shape({
        coupon: Yup.string()
            .matches(/^[aA-zZ0-9-]+$/, 'Únicamente se permite letras y números sin espacios'),
    });


    const inicializarConfigOption = (product) => {
        //poner valores default price e id para configoption
        const cycle = billingcycle;
        for (let i = 0; i < product.configoptions.length; i++) {//llena precios de primera opcion por default
            let newprice = product.configoptions[i].suboptions[0][cycle];
            product.configoptions[i].pricebase = product.configoptions[i].suboptions[0][cycle];

            if (product.configoptions[i].optiontype === "4") { //precio de ciclo por la cantidad de minimo (se almaceno en campo defaultqty)
                newprice = newprice * product.configoptions[i].defaultqty;
                product.configoptions[i]["marks"] = [{ value: product.configoptions[i].qtyminimum, label: `${product.configoptions[i].qtyminimum}` },
                { value: product.configoptions[i].qtymaximum, label: `${product.configoptions[i].qtymaximum}` }]
            }

            if (product.configoptions[i].optiontype === "3") {
                if (product.configoptions[i].defaultchecked === false) {
                    newprice = 0;
                }
            }
            product.configoptions[i].defaultpriceoption = newprice;
        }

        if (confopt !== null && subopt !== null) { //para radio y select
            for (let j = 0; j < product.configoptions.length; j++) {

                if (product.configoptions[j].idconfopt === confopt) {
                    const suboptions = product.configoptions[j].suboptions;

                    for (let k = 0; k < suboptions.length; k++) {

                        if (suboptions[k].idsubopt === subopt) {
                            product.configoptions[j].defaultidoption = subopt;
                            product.configoptions[j].defaultnameoption = suboptions[k].suboptionname;
                            product.configoptions[j].pricebase = suboptions[k][cycle];
                            product.configoptions[j].defaultpriceoption = suboptions[k][cycle];

                            k = suboptions.length;
                        }
                    }

                    j = product.configoptions.length;
                }

            }
        }

    };

    /* cambio para configuracion caso radio y select*/
    const handleChangeConfigOptions = (ev, indexconf) => {

        const idoption = ev.target.value;

        const updatedproduct = updateDefaultConfigOption(indexconf, idoption);
        //updateDefaultConfigOption(indexconf, idoption);

        //let priceOption = searchPriceConfOption(indexconf, idoption);
        const priceOptions = searchPriceOptions(updatedproduct);

        //suma precio producto + addons + domain + configOption
        /*console.log('precio product', priceProdCurrent);
        console.log('subtotal add', subTotalAdd);
        console.log('price domain', priceDomain); */

        let newtotal = (priceProdCurrent + subTotalAdd + priceDomain + priceOptions).toFixed(2);

        //console.log('new product', updatedproduct);

        setSubtotal(newtotal);
        setTotal((newtotal - discount).toFixed(2));

        setProduct(updatedproduct);
        setSubOptHidden(idoption);

    };

    /**opcion configuracion caso select y radio */
    const updateDefaultConfigOption = (indexconf, idopt) => { //actualiza price, option, idoption default in product
        const cycle = cycleProdCurrent;

        let newproduct = product;

        newproduct.configoptions[indexconf].defaultidoption = parseInt(idopt);

        const suboptions = newproduct.configoptions[indexconf].suboptions;

        for (let j = 0; j < suboptions.length; j++) {
            if (suboptions[j].idsubopt === parseInt(idopt)) {

                const newprice = suboptions[j][cycle];
                newproduct.configoptions[indexconf].defaultpriceoption = newprice;
                newproduct.configoptions[indexconf].pricebase = newprice;
                newproduct.configoptions[indexconf].defaultnameoption = suboptions[j].suboptionname;
                j = suboptions.length;
            }
        };

        //console.log('new product', newproduct);
        return newproduct;
        //setProduct(newproduct);
        //setSubOptHidden(idopt);

    };

    /*opcion configuraciones de cantidad slider */
    const handleChangeSlider = (ev, indexconf) => {
        const slidevalue = ev.target.value;
        const updatedproduct = updateConfigOptionQuantity(indexconf, slidevalue);
        const priceOptions = searchPriceOptions(updatedproduct);

        //console.log('price options', priceOptions);

        let newtotal = (priceProdCurrent + subTotalAdd + priceDomain + priceOptions).toFixed(2);

        setSubtotal(newtotal);
        setTotal((newtotal - discount).toFixed(2));

        setProduct(updatedproduct);
        setSubOptHidden(slidevalue);
    };

    const updateConfigOptionQuantity = (indexconf, value) => {
        //actualiza price, option, idoption default in product, considera el newvalue como default

        const cycle = cycleProdCurrent;

        let newproduct = product;

        newproduct.configoptions[indexconf].defaultqty = parseInt(value);

        const suboptions = newproduct.configoptions[indexconf].suboptions;
        const newprice = suboptions[0][cycle] * value;
        newproduct.configoptions[indexconf].pricebase = suboptions[0][cycle];
        newproduct.configoptions[indexconf].defaultpriceoption = newprice;

        return newproduct;

    };

    const handleCheckConfig = (ev, indexconf) => {
        const checked = ev.target.checked;

        const updatedproduct = updateConfigOptionCheck(indexconf, checked);
        const priceOptions = searchPriceOptions(updatedproduct);

        //console.log('price options', priceOptions);

        let newtotal = (priceProdCurrent + subTotalAdd + priceDomain + priceOptions).toFixed(2);

        setSubtotal(newtotal);
        setTotal((newtotal - discount).toFixed(2));

        setProduct(updatedproduct);

        let valuecheck = 0;
        if (checked) {
            valuecheck = 1;
        }
        setSubOptHidden(valuecheck);

    }

    const updateConfigOptionCheck = (indexconf, checked) => {
        //actualiza pricedefault,  checkeddefault in product
        const cycle = cycleProdCurrent;

        let newproduct = product;

        newproduct.configoptions[indexconf].defaultchecked = checked;

        const suboptions = newproduct.configoptions[indexconf].suboptions;
        newproduct.configoptions[indexconf].pricebase = suboptions[0][cycle];

        if (checked) {
            const newprice = suboptions[0][cycle];
            newproduct.configoptions[indexconf].defaultpriceoption = newprice;
            newproduct.configoptions[indexconf].defaultqty = 1;
        } else {
            newproduct.configoptions[indexconf].defaultpriceoption = 0;
            newproduct.configoptions[indexconf].defaultqty = 0;
        }

        return newproduct;
    }

    const searchPriceOptions = (product) => {
        const configs = product.configoptions;
        let priceoptions = 0;

        for (let i = 0; i < configs.length; i++) {
            priceoptions = priceoptions + configs[i].defaultpriceoption;
        }

        return priceoptions;
    };

    const onCouponSubmit = async (values, props) => {
        //console.log('dio click', values.coupon);
        const coupon = await searchCoupon(values.coupon);
        //console.log('coupon', coupon);
        props.resetForm();
        if (coupon.valid === true) {
            setCoupon(coupon);
            const descuento = calculateDiscount(coupon, idProduct, priceProdCurrent, cycleProdCurrent);
            setDiscount(descuento);
            setValidCoupon(true);

            //actualizar total

            setTotal((subtotal - descuento).toFixed(2));

        } else {
            setValidCoupon(false)
        }

        //props.resetForm();
    };

    const handleDeleteCoupon = () => {
        setDiscount(0.00);
        setCoupon(null);
        setTotal(subtotal);
    };

    const searchPriceDomain = (cycle) => {
        let priceDomain = 0.00;

        if (eventDomain === 'domainregister' || eventDomain === 'domaintransfer') {
            const typeFreeDomain = getIsFreeDomain(tld, cycle, product);
            setFreeDomain(typeFreeDomain.type);

            if (typeFreeDomain.isFree) {
                setPriceDomain(0.00);
                priceDomain = 0.00;
            } else {
                setPriceDomain(pricingTld.annually);
                priceDomain = pricingTld.annually;
            }
            if (typeFreeDomain.type === 'on') {
                setPriceRenewDomain(0.00)
            } else { //es once o void
                setPriceRenewDomain(pricingRenewTld.annually);
            }
        }

        return priceDomain;
    };

    const inicializarPrecios = (price, cycle, pricedomain, addons, minAddons, product) => {
        setPriceProdCurrent(parseFloat(price));
        setCycleProdCurrent(cycle);

        let newtotal = 0;
        const periodsel = cycle;
        let newprice = (parseFloat(price));
        const priceconfigs = searchPriceOptions(product);

        let newsubtotaladd = getPriceAllAddonCheckIni(periodsel, addons, minAddons);

        //se suma precio product + addons check + dominio
        newtotal = (newprice + newsubtotaladd + pricedomain + priceconfigs).toFixed(2);

        setSubTotalAdd(newsubtotaladd);
        setSubtotal(newtotal);
        setTotal((newtotal - discount).toFixed(2));
    };

    const getPriceAllAddonCheckIni = (period, addons, minAddons) => {

        let priceadd = 0;
        let newsubtadd = 0;
        addons.map((addon) => {
            if (addon.ischeck === true) {
                priceadd = addon[period];

                if (priceadd === -1 || priceadd === 0) { //buscar en los precios min addon, caso 0 para como Kaspersky donde solo tienen un cycle unico
                    minAddons.map((add) => {
                        if (add.id === addon.id) {
                            priceadd = add.price;
                        }
                    });
                }

                newsubtadd = newsubtadd + priceadd;

            }

        });
        return newsubtadd;
    };

    //params @precioProducto @bilingcycle
    const handleSelectCycle = (price, cycle) => {
        setPriceProdCurrent(parseFloat(price));
        setCycleProdCurrent(cycle);

        const priceDom = searchPriceDomain(cycle);

        let newtotal = 0;
        const periodsel = cycle;
        let newprice = (parseFloat(price));

        let newsubtotaladd = getPriceAllAddonCheck(periodsel);

        newsubtotaladd = parseFloat(newsubtotaladd.toFixed(2));

        //console.log('price domain', priceDomain)
        //se suma precio product + addons check + dominio + configoptions
        const productupdate = updateProductConfigChangeCycle(periodsel);
        const priceoptions = searchPriceOptions(productupdate);
        newtotal = (newprice + newsubtotaladd + priceDom + priceoptions).toFixed(2);
        //newtotal = newprice + subtotaladd;

        setProduct(productupdate);

        setSubTotalAdd(newsubtotaladd);
        setSubtotal(newtotal);

        //reset coupon
        setCoupon(null);
        setDiscount(0.00);
        setTotal(newtotal);
    };

    const updateProductConfigChangeCycle = (period) => {
        const updatedproduct = product;
        const configs = updatedproduct.configoptions;
        for (let i = 0; i < configs.length; i++) {

            if (configs[i].optiontype === "4") {
                const price = configs[i].suboptions[0][period] * configs[i].defaultqty;
                configs[i].pricebase = configs[i].suboptions[0][period];
                configs[i].defaultpriceoption = price;

            } else {
                if (configs[i].optiontype === "3") {
                    let newprice = configs[i].suboptions[0][period];
                    configs[i].pricebase = newprice;
                    if (configs[i].defaultchecked === false) {
                        newprice = 0;
                    }
                    configs[i].defaultpriceoption = newprice;

                } else {
                    const suboptions = configs[i].suboptions;
                    const idoption = configs[i].defaultidoption;

                    for (let j = 0; j < suboptions.length; j++) {
                        if (idoption === suboptions[j].idsubopt) {
                            const price = suboptions[j][period];
                            configs[i].pricebase = price;
                            configs[i].defaultpriceoption = price;
                            j = suboptions.length;
                        }

                    }
                }

            }

        }

        return updatedproduct;

    }

    const getPriceAllAddonCheck = (period) => {

        let priceadd = 0;
        let newsubtadd = 0;
        addons.map((addon) => {
            if (addon.ischeck === true) {
                priceadd = addon[period];

                if (priceadd === -1 || priceadd === 0) { //buscar en los precios min addon, caso 0 para casos addon antiguos como Kaspersky
                    minAddons.map((add) => {
                        if (add.id === addon.id) {
                            priceadd = add.price;
                        }
                    });
                }

                newsubtadd = newsubtadd + priceadd;

            }

        });
        //console.log('new subtadd check all', newsubtadd);
        return newsubtadd;
    }

    const handleCheckAddon = (ev) => {
        updateCheckAddon(ev.target.value, ev.target.checked);

        let priceadd = 0;
        let idaddcheck = ev.target.value;
        let ischeck = ev.target.checked;
        let newsubtotaladd = 0;

        priceadd = getPriceAddonCheck(idaddcheck, ischeck);

        parseFloat(priceadd);

        if (ev.target.checked) {
            newsubtotaladd = priceadd + subTotalAdd;
        } else {
            newsubtotaladd = subTotalAdd - priceadd;
        }

        newsubtotaladd = parseFloat(newsubtotaladd.toFixed(2));
        setSubTotalAdd(newsubtotaladd);

        //se suma precio product + addons check + dominio + configoptions
        const priceoptions = searchPriceOptions(product);
        let newtotal = (priceProdCurrent + newsubtotaladd + priceDomain + priceoptions).toFixed(2);

        setSubtotal(newtotal);
        setTotal((newtotal - discount).toFixed(2));

    }

    const updateCheckAddon = (idaddon, checked) => {
        const arrayminaddons = [];
        for (let i = 0; i < minAddons.length; i++) {
            if (minAddons[i].id === parseInt(idaddon)) {
                minAddons[i].checked = checked;
            }
            arrayminaddons.push(minAddons[i]);
        }
        setMinAddons(arrayminaddons);
    }
    const getPriceAddonCheck = (idaddonp, state) => {

        let priceadd = 0;
        let ischecknew = state;
        let idaddon = parseInt(idaddonp);
        addons.map((addon) => {
            if (addon.id === idaddon) {
                //console.log('cycleselect value: ', cycleselect);
                priceadd = addon[cycleProdCurrent];
                addon.ischeck = ischecknew;

                if (priceadd === -1 || priceadd === 0) { //buscar en los precios min addon, caso 0 para casos como Kaspersky donde solo tienen un ciclo activo

                    minAddons.map((add) => {
                        if (add.id === idaddon) {
                            priceadd = add.price;
                        }
                    });

                }

            }
        });

        return priceadd;
    }

    const passRegister = () => {

        let cycleProduct = cycleProdCurrent;
        if (product.paytype === 'onetime' || product.paytype === 'free') {
            cycleProduct = product.paytype;
        }

        const listaddons = getListAddonsCheck();
        const addons = {
            addons: listaddons
        }

        const orderdata = {
            idproduct: idProduct,
            priceproduct: priceProdCurrent,
            billingcycle: cycleProduct,
            //billingcycle: cycleProdCurrent,
            subtotaladd: subTotalAdd,
            subtotal: subtotal,
            total: total,
            discount: discount,
            coupon: coupon,
            nameproduct: product.name,
            domain: dominio,
            pricedomain: priceDomain,
            pricerenewdomain: priceRenewDomain,
            eventdomain: eventDomain,
            freedomain: freeDomain,
            configoptions: product.configoptions
        }
        const orderdatafull = { ...orderdata, ...addons }

        //console.log("orderdatafull", orderdatafull);

        navigate('/cart/product/checkout/', { state: { data: orderdatafull } });

    }

    function getListAddonsCheck() {

        const listaddons = [];
        const period = cycleProdCurrent; //con cicle select de producto

        addons.map((addon) => {
            if (addon.ischeck === true) {
                let priceadd = addon[period];
                let periodeng = period;
                let billingcycleadd = addon.billingcycle;

                //console.log('valor de billingcycle add check es', billingcycleadd)

                if (billingcycleadd === 'Monthly' || billingcycleadd === 'Quarterly' || billingcycleadd === 'Semi-Annually' || billingcycleadd === 'Annually' || billingcycleadd === 'Biennially' || billingcycleadd === 'Triennially') {
                    //console.log('entra a cambiar valor')
                    billingcycleadd = 'recurring'
                }

                if (priceadd === -1 || priceadd === 0) { //buscar en los precios min addon, caso 0 para casos como Kaspersky donde solo tienen un periodo activo
                    minAddons.map((add) => {
                        if (add.id === addon.id) {
                            priceadd = add.price;
                            periodeng = add.periodeng;
                        }
                    });
                }

                let nameadd = addon.name
                let idadd = addon.id
                let addondetail = {
                    id: idadd,
                    name: nameadd,
                    price: priceadd,
                    billingcycle: billingcycleadd,
                    periodeng: periodeng
                }

                listaddons.push(addondetail);

            }

        });

        //console.log('lista addons check', listaddons);

        return listaddons;
    }

    useEffect(() => {

        if (idProduct !== '') {

            (async function () {
                try {

                    const prod = await getProduct(idProduct);
                    //console.log('producto', prod);
                    inicializarConfigOption(prod);  //pone valores para config option
                    setProduct(prod);

                    const cycleprod = getCycleProduct(prod);
                    setCycleProduct(cycleprod);
                    //console.log('cycleprod', cycleprod);

                    const addons = await getAddons(idProduct);
                    //console.log('addons', addons);
                    setAddons(addons);

                    if (addons.length === 0 && cycleprod.length === 1) {
                        setPriceProdCurrent(cycleprod[0].price);
                        setCycleProdCurrent(cycleprod[0].periodeng);

                        /* precios registro transferencia renovacion dominio* */
                        if (eventDomain === 'domainregister' || eventDomain === 'domaintransfer') {
                            const pricingTld = await getPriceTld(tld, eventDomain, currency);
                            setPricingTld(pricingTld);

                            const priceRenewTld = await getPriceTld(tld, 'domainrenew', currency);
                            setPricingRenewTld(priceRenewTld);

                            const typeFreeDomain = getIsFreeDomain(tld, cycleprod[0].periodeng, prod);
                            setFreeDomain(typeFreeDomain.type);

                            if (typeFreeDomain.isFree) {
                                setPriceDomain(0.00);
                                inicializarPrecios(cycleprod[0].price, cycleprod[0].periodeng, 0.00, [], [], prod);
                            } else {
                                //get price según extension
                                setPriceDomain(pricingTld.annually); //el precio registro y transferencia solo anual para pedido
                                inicializarPrecios(cycleprod[0].price, cycleprod[0].periodeng, pricingTld.annually, [], [], prod);
                            }
                            if (typeFreeDomain.type === 'on') {
                                setPriceRenewDomain(0.00)
                            } else { //es once o void
                                setPriceRenewDomain(priceRenewTld.annually);
                            }

                        } else {
                            setPriceDomain(0.00);
                            inicializarPrecios(cycleprod[0].price, cycleprod[0].periodeng, 0.00, [], [], prod);
                        }


                    } else {
                        const minaddons = await getMinAddons(addons);
                        setMinAddons(minaddons);

                        const priceCycleIni = getValIniProd(cycleprod, cycleProdCurrent);

                        /* precios registro transferencia renovacion dominio* */
                        if (eventDomain === 'domainregister' || eventDomain === 'domaintransfer') {
                            const pricingTld = await getPriceTld(tld, eventDomain, currency);
                            //console.log('pricing tld', pricingTld);
                            setPricingTld(pricingTld);

                            const priceRenewTld = await getPriceTld(tld, 'domainrenew', currency);
                            setPricingRenewTld(priceRenewTld);

                            const typeFreeDomain = getIsFreeDomain(tld, priceCycleIni.billingcycle, prod);
                            setFreeDomain(typeFreeDomain.type);

                            if (typeFreeDomain.isFree) {
                                setPriceDomain(0.00);
                                inicializarPrecios(priceCycleIni.price, priceCycleIni.billingcycle, 0.00, addons, minaddons, prod);
                            } else {
                                //get price según extension
                                setPriceDomain(pricingTld.annually); //el precio registro y transferencia solo anual para pedido
                                inicializarPrecios(priceCycleIni.price, priceCycleIni.billingcycle, pricingTld.annually, addons, minaddons, prod);
                            }
                            if (typeFreeDomain.type === 'on') {
                                setPriceRenewDomain(0.00)
                            } else { //es once o void
                                setPriceRenewDomain(priceRenewTld.annually);
                            }

                        } else {
                            setPriceDomain(0.00);
                            inicializarPrecios(priceCycleIni.price, priceCycleIni.billingcycle, 0.00, addons, minaddons, prod);
                        }
                    }

                } catch (e) {

                }
            })()

        } else {
            navigate('/login/')
        }



    }, [])

    if (idProduct === '') {
        return (
            <SpinnerLoad />
        )
    }
    else {
        return (
            <>
                <Helmet>
                    <title>Portal - Área de Clientes de Neothek - Revisar Pedido</title>
                </Helmet>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        justifyContent: 'center'
                    }}
                >

                    <Container maxWidth="md">
                        <Grid item xs={12} md={12}>
                            <StepperCart step={1} />
                        </Grid>
                    </Container>

                    <Container maxWidth="md" variant='cont-nk'>

                        {product.paytype &&
                            <>
                                {(product.paytype !== 'onetime' && product.paytype !== 'free') &&

                                    <Container variant='cont-boxnk'>

                                        <Grid item xs={12}>
                                            <Box mt={2} mb={3} >
                                                <Typography variant='h4' sx={{display: "inline"}}>Ciclo de Facturación</Typography>
                                                <Typography className={classes.labelNameProduct}>{product.name}</Typography>
                                            </Box>


                                            <Grid container alignItems="center" justifyContent="center" spacing={6} mt={0}>

                                                <FormControl component="fieldset">
                                                    <RadioGroup row aria-label="billingcycle" name="billingcycle" defaultValue={billingcycle}>
                                                        {cycleProduct.map(({ period, price, periodeng }) => (
                                                            <FormControlLabel
                                                                key={periodeng}
                                                                value={periodeng}
                                                                control={<CycleRadio periodeng={periodeng} price={price} period={period} onClick={handleSelectCycle} />}
                                                                label={<><Typography variant='body2' sx={{ textAlign: 'center' }}><strong>US$ {price}</strong></Typography> <Typography variant='body2' color="textSecondary" sx={{ textAlign: 'center' }}>{period}</Typography></>}
                                                                labelPlacement="bottom"
                                                            />
                                                        ))}

                                                    </RadioGroup>
                                                </FormControl>

                                            </Grid>

                                            <Typography hidden>{subOptionHidden}</Typography>

                                            <Divider variant='div-nk' />

                                        </Grid>

                                    </Container>

                                }
                            </>
                        }


                        {product.configoptions &&
                            <>
                                {product.configoptions.length !== 0 &&
                                    <Container variant="cont-boxnk">
                                        <Grid item xs={12}>
                                            <Box mt={1} >
                                                <Typography variant='h4'>Opciones Configurables</Typography>
                                            </Box>
                                        </Grid>

                                        <Grid container spacing={2}>

                                            {product.configoptions.map((config, index) => (

                                                <Grid item xs={12} sm={6} key={config.idconfopt}>
                                                    {/*<Box mt={1} sx={{boxShadow: "0 0 2px 0 rgb(145 158 171 / 24%), 0 2px 8px -4px rgb(145 158 171 / 24%)", padding: "2% 5%"}} >*/}
                                                    <Box mt={1} >
                                                        <Typography color="colornk.grey3" sx={{ fontSize: '1.36rem' }} mb={1}><strong>{config.optionname}</strong></Typography>

                                                        {config.optiontype === "1" &&
                                                            <FormControl variant="outlined" sx={{ width: '60%' }}>
                                                                <Select
                                                                    native
                                                                    value={config.defaultidoption}
                                                                    onChange={(e) => { handleChangeConfigOptions(e, index) }}
                                                                    sx={{ '.MuiOutlinedInput-input': { padding: '7px 14px', fontSize: '1.51rem', color: "#525f7f" } }}
                                                                >
                                                                    {
                                                                        config.suboptions.map(option => (
                                                                            <option key={option.idsubopt} value={option.idsubopt} >{option.suboptionname}</option>
                                                                        ))
                                                                    }

                                                                </Select>

                                                            </FormControl>
                                                        }

                                                        {config.optiontype === "2" &&
                                                            <FormControl component="fieldset">
                                                                <RadioGroup aria-label="option product" value={config.defaultidoption} onChange={(e) => { handleChangeConfigOptions(e, index) }} sx={{ '.css-v91xfs-MuiTypography-root': { fontSize: '1.36rem' } }}>
                                                                    {
                                                                        config.suboptions.map(option => (
                                                                            <FormControlLabel key={option.idsubopt} value={option.idsubopt} control={<Radio sx={{ padding: '1px 9px' }} color='primary' />} label={option.suboptionname} sx={{ color: "#525f7f" }} />
                                                                        ))
                                                                    }
                                                                </RadioGroup>
                                                            </FormControl>
                                                        }
                                                        {config.optiontype === "3" &&
                                                            <FormControlLabel control={<Checkbox checked={config.defaultchecked} onChange={(e) => { handleCheckConfig(e, index) }} sx={{ padding: '1px 9px' }} color='primary' />} label={config.defaultnameoption} sx={{ color: "#525f7f" }} />
                                                        }

                                                        {config.optiontype === "4" &&
                                                            <Box pt={4}>
                                                                <Slider
                                                                    defaultValue={config.defaultqty}
                                                                    aria-labelledby="discrete-slider"
                                                                    valueLabelDisplay="on"
                                                                    value={config.defaultqty}
                                                                    onChange={(ev) => handleChangeSlider(ev, index)}
                                                                    step={1}
                                                                    marks={config.marks}
                                                                    min={config.qtyminimum}
                                                                    max={config.qtymaximum}
                                                                />
                                                            </Box>

                                                        }

                                                    </Box>

                                                </Grid>

                                            ))}
                                        </Grid>

                                        <Divider variant='div-nk' />

                                    </Container>
                                }
                            </>
                        }


                        {addons.length !== 0 &&
                            <Container variant="cont-boxnk">
                                <Grid item xs={12}>
                                    <Box mt={2} mb={3} >
                                        <Typography variant='h4'>Complementos Disponibles</Typography>
                                    </Box>
                                </Grid>

                                <Grid container spacing={2}>
                                    {minAddons.map(({ id, name, description, price, period, checked }) => (
                                        <Grid key={id} item xs={12} sm={6}>
                                            <Box sx={{ textAlign: 'center', backgroundColor: '#f6f9fc' }}>
                                                <Box sx={{ paddingTop: '3px' }}>
                                                    <Typography variant='body2' color='colornk.bluedark2'><strong>{name}</strong></Typography>
                                                </Box>

                                                <Box>
                                                    <Grid container spacing={2} sx={{ py: 1 }}>
                                                        <Grid item xs={2} sm={2}>
                                                            <Checkbox
                                                                icon={<CheckBoxOutline />}
                                                                checkedIcon={<CheckBoxIcon />}
                                                                value={id}
                                                                onChange={handleCheckAddon}
                                                                checked={checked}
                                                                color='primary'
                                                            />
                                                        </Grid>
                                                        <Grid item xs={10} sm={10} sx={{ textAlign: 'justify', paddingRight: '3%' }}>
                                                            <Typography variant='body2' color="colornk.bluedark" sx={{ fontSize: '1.35rem' }}>{description}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} sx={{ textAlign: 'right', paddingRight: '3%' }}>
                                                            <Typography variant='body2' color="colornk.bluedark" sx={{ fontWeight: '500' }}>US$ {price} <span>{period}</span></Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                                <Divider variant='div-nk' />
                            </Container>
                        }



                        {/* sumario pedido */}

                        <Container>
                            <Grid item xs={12}>
                                <Box mt={4} mb={2} >
                                    <Typography variant='h4'>Sumario de Pedido</Typography>
                                    {/*<Divider />*/}
                                </Box>
                            </Grid>

                            <Grid item xs={12} >
                                <Container sx={{ backgroundColor: "#F6F9FC", borderRadius: "5px", p: 1, paddingTop: "4%" }}>
                                    <Grid container spacing={2} sx={{ mb: 2 }}>
                                        <Grid item sm={8} xs={9} style={{ paddingTop: '8px' }}>
                                            {dominio !== '' ?
                                                <Typography color="colornk.bluedark" variant="txtSummary-nk">{product.name} - {dominio}</Typography>
                                                :
                                                <Typography color="colornk.bluedark" variant="txtSummary-nk">{product.name}</Typography>
                                            }

                                        </Grid>
                                        <Grid item sm={4} xs={3} style={{ paddingTop: '8px', textAlign: "right" }}>
                                            <Typography color="colornk.bluedark" variant="txtSummary-nk">US$ {priceProdCurrent}</Typography>
                                        </Grid>

                                        {product.configoptions &&
                                            <>
                                                {product.configoptions.length !== 0 &&
                                                    <>
                                                        {product.configoptions.map(({ idconfopt, optionname, defaultqty, defaultnameoption, defaultpriceoption, defaultchecked, optiontype }) => (
                                                            <Grid container spacing={2} key={idconfopt} sx={{ paddingLeft: 2, marginTop: '1px' }} >
                                                                {optiontype === "4" ?
                                                                    <Grid item sm={8} xs={9} style={{ paddingTop: '13px' }}>
                                                                        <Typography color='colornk.grey3' variant="txtConfig-nk" >  {`\xa0${optionname} :`} {defaultqty}</Typography>
                                                                    </Grid>
                                                                    :
                                                                    <>
                                                                        {optiontype === "3" ?
                                                                            <Grid item sm={8} xs={9} style={{ paddingTop: '13px' }}>
                                                                                <Typography color='colornk.grey3' variant="txtConfig-nk" > {`\xa0${optionname} :`} <>{defaultchecked ? <span>Sí</span> : <span>No</span>}</></Typography>
                                                                            </Grid>
                                                                            :
                                                                            <Grid item sm={8} xs={9} style={{ paddingTop: '13px' }}>
                                                                                <Typography color='colornk.grey3' variant="txtConfig-nk" >  {`\xa0${optionname} :`} {defaultnameoption}</Typography>
                                                                            </Grid>
                                                                        }
                                                                    </>
                                                                }

                                                                <Grid item sm={4} xs={3} style={{ paddingTop: '13px', textAlign: "right" }}>
                                                                    <Typography color="colornk.bluedark" variant="txtSummary-nk">US$ {defaultpriceoption}</Typography>
                                                                </Grid>

                                                            </Grid>
                                                        ))}

                                                    </>
                                                }
                                            </>
                                        }

                                        {(eventDomain !== 'domainown' && eventDomain !== 'nodomain') &&
                                            <>
                                                <Grid item sm={8} xs={9}>
                                                    {eventDomain === 'domainregister' ?
                                                        <Typography color="colornk.bluedark" variant="txtSummary-nk">Registro de Dominio - {dominio} (1 año)</Typography>
                                                        :
                                                        <Typography color="colornk.bluedark" variant="txtSummary-nk">Transferencia de Dominio - {dominio} (1 año)</Typography>
                                                    }

                                                </Grid>
                                                <Grid item sm={4} xs={3} style={{ paddingTop: '13px', textAlign: 'right' }}>
                                                    <Typography color="colornk.bluedark" variant="txtSummary-nk">US$ {priceDomain}</Typography>
                                                </Grid>
                                                {/*<Grid item sm={12} style={{ paddingTop: 0 }}>
                                                    <Typography style={{ fontSize: '1.2rem' }} color='colornk.grey3'>Renovación ${priceRenewDomain} /año</Typography>
                                                </Grid> */}
                                            </>
                                        }

                                        <Grid item sm={8} xs={9} style={{ paddingTop: '13px' }}>
                                            <Typography color="colornk.bluedark" variant="txtSummary-nk" >Complementos</Typography>
                                        </Grid>
                                        <Grid item sm={4} xs={3} style={{ paddingTop: '13px', textAlign: 'right' }}>
                                            <Typography color="colornk.bluedark" variant="txtSummary-nk" >US$ {subTotalAdd}</Typography>
                                        </Grid>

                                        <Grid container spacing={2} sx={{ paddingLeft: 2, mt: 2 }}>

                                            <Grid item xs={12} sx={{ borderTop: '1px solid #e6ebf1' }} style={{ paddingTop: '0px' }}></Grid>

                                            <Grid item sm={8} xs={8}>
                                                <Typography color="colornk.bluedark" variant="txtSummary-nk"><strong>Subtotal</strong></Typography>
                                            </Grid>
                                            <Grid item sm={4} xs={4} sx={{ textAlign: "right" }}>
                                                <Typography color="colornk.bluedark" variant="txtSummary-nk"><strong>US$ {subtotal}</strong></Typography>
                                            </Grid>

                                            {(coupon && discount !== 0.00) &&
                                                <>
                                                    <Grid item sm={8} xs={8} style={{ paddingTop: '13px' }}>
                                                        <Typography color="colornk.bluedark" variant="txtSummary-nk">Cupón: {coupon.code}</Typography>
                                                    </Grid>
                                                    <Grid item sm={4} xs={4} style={{ paddingTop: '13px', textAlign: 'right' }}>
                                                        <Typography color="colornk.bluedark" variant="txtSummary-nk">US$ -{discount}</Typography>
                                                    </Grid>
                                                </>
                                            }

                                            <Grid item sm={8} xs={8} style={{ paddingTop: '13px' }}>
                                                <Typography color="colornk.bluedark" variant="txtSummary-nk"><strong>Total</strong></Typography>
                                            </Grid>
                                            <Grid item sm={4} xs={4} style={{ paddingTop: '13px', textAlign: 'right' }}>
                                                <Typography color="colornk.bluedark" variant="txtSummary-nk"><strong>US$ {total}</strong></Typography>
                                            </Grid>

                                        </Grid>


                                    </Grid>
                                </Container>

                                {/*<Divider /> */}
                            </Grid>

                            {(coupon === null && discount === 0.00) ?
                                <Grid item xs={12} sx={{ pt: 2 }}>
                                    <Formik initialValues={couponValue} validationSchema={couponValidation} onSubmit={onCouponSubmit}>
                                        {(props) => (

                                            <Form>
                                                <Grid container spacing={2} sx={{ mb: 2 }} justifyContent="flex-end" >
                                                    <Grid item sm={6} xs={12}>
                                                        <Field as={TextField}
                                                            variant="outlined"
                                                            name='coupon'
                                                            label='Código de Cupón'
                                                            fullWidth
                                                            size='small'
                                                            placeholder="Introduzca un código promocional, si dispone de uno"
                                                            dir="ltr"
                                                            error={props.errors.coupon && props.touched.coupon}
                                                            helperText={<ErrorMessage name='coupon' />} required />

                                                        {validCoupon === false && <Typography variant='body2' color='secondary'>Cupón no valido</Typography>}
                                                    </Grid>
                                                    <Grid item sm={3} xs={12}>
                                                        <Button type='submit' variant='outlined' color='primary' fullWidth>
                                                            Aplicar Cupón
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Form>

                                        )}
                                    </Formik>
                                </Grid>

                                :

                                <Grid item xs={12}>
                                    <Grid container justifyContent="flex-end">
                                        <Grid item sm={3}>
                                            <Button variant='outlined' fullWidth onClick={handleDeleteCoupon}>
                                                Eliminar Cupón
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Container>

                        <Grid container justifyContent="flex-end" alignItems="center" >
                            <Box sx={{ py: 3 }}>
                                <Button variant='contained' color='success' onClick={passRegister} size='large' endIcon={<NextIcon />}>
                                    Continuar
                                </Button>
                            </Box>
                        </Grid>

                    </Container>

                </Box >
            </>
        )
    }

}

export default ReviewOrder