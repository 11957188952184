import React from 'react';
import Alert from '@material-ui/core/Alert';
import { Box } from '@material-ui/core'
import DominioNoDisponible from './DominioNoDisponible';
import AvailableDomain from './AvailableDomain';

function VerifyDomainOnly(props) {
    const status = props.domainstatus;
    const domain = props.inputdomain;
    const tld = props.tld;
    const eventDomain = props.eventDomain;
    const epp = props.epp;

    if (eventDomain === 'domainregister') {
        if (status === 'available') {
            return <AvailableDomain dominio={domain} tld={tld} eventDomain={eventDomain} epp={epp} />;
        }
        if (status === 'regthroughus' || status === 'regthroughothers' || status === 'unknown') {
            return <DominioNoDisponible dominio={domain} eventDomain={eventDomain} />;
        }
    }
    if (eventDomain === 'domaintransfer') {
        if (status === 'regthroughothers') {
            return <AvailableDomain dominio={domain} tld={tld} eventDomain={eventDomain} epp={epp} />;
        }
        if (status === 'available' || status === 'regthroughus' || status === 'unknown') {
            return <DominioNoDisponible dominio={domain} eventDomain={eventDomain} />;
        }
    }

    return (
        <Box mt={3}>
            <Alert severity="info">Ingresa el dominio y verificaremos su disponibilidad</Alert>
        </Box>
    )
}

export default VerifyDomainOnly;