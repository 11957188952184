import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Box, Container, Link, Typography } from '@mui/material';
import SpinnerLoad from '../../components/FormsUI/Spinner';
import { NavLink as RouterLink } from 'react-router-dom';
import { getAnnouncement } from '../../services/Announcement';

const Announcement = () => {

    const { id } = useParams();
    const [notice, setNotice] = useState(null);

    useEffect(() => {
        const getAd = async () => {
            const ad = await getAnnouncement(id);

            setNotice(ad);
        }
        getAd();
    }, [])

    return (
        <>
            <Helmet>
                <title>Portal Neothek - Área de clientes de Neothek</title>
            </Helmet>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center'
                }}
            >

                {id ?
                    <>
                        {notice ?
                            <>
                                {notice.error === false ?
                                    <> 
                                        <Container maxWidth="lg" sx={{pb: '5px', mt: 5}}>
                                            <Link component={RouterLink} to={`/`} >{'Portal'}</Link> <span style={{color: '#5f81ff'}}>{'  >  '}</span>
                                            <Link component={RouterLink} to={`/announcements/`} >{'Anuncios'}</Link> <span style={{color: '#5f81ff'}}>{'  >  '}</span>
                                            <Link   sx={{textDecoration: 'none', color: '#23527c'}}>{notice.title}</Link>
                                        </Container>
                                        
                                        <Container maxWidth="lg" variant='cont-nk'>
                                            <Box sx={{ mb: 2, mt: 5 }}>
                                                <Typography variant='h1' sx={{ fontSize: "2.25rem", color: "#06363c", textAlign: "center" }}>{notice.title}</Typography>
                                                <Typography sx={{ mt: 5, mb: 3, fontSize: "1.2rem", color: "#959595" }}>Anunciado el {notice.date}</Typography>
                                                <div id="container" dangerouslySetInnerHTML={{ __html: notice.announcement }} style={{ paddingBottom: "3rem" }}></div>
                                            </Box>
                                        </Container>
                                    </>
                                    :
                                    <Container maxWidth="sm" variant='cont-nk'>

                                        <Box sx={{ mb: 2, mt: 3 }}>
                                            <Typography
                                                color="textPrimary"
                                                variant="h2"
                                                align="center"
                                            >
                                                Lo sentimos,
                                            </Typography>

                                        </Box>

                                        <Box sx={{ pt: 1, pb: 2 }}>
                                            <Typography
                                                color="textSecondary"
                                                gutterBottom
                                                variant="body1"
                                                align="center"
                                            >
                                                El anuncio al cual trata de acceder no existe, o ya no se encuentra disponible.
                                            </Typography>
                                        </Box>

                                    </Container>

                                }
                            </>
                            :
                            <SpinnerLoad />
                        }
                    </>

                    :
                    <Container maxWidth="sm" variant='cont-nk'>

                        <Box sx={{ mb: 2, mt: 3 }}>
                            <Typography
                                color="textPrimary"
                                variant="h2"
                                align="center"
                            >
                                Lo sentimos,
                            </Typography>

                        </Box>

                        <Box sx={{ pt: 1, pb: 2 }}>
                            <Typography
                                color="textSecondary"
                                gutterBottom
                                variant="body1"
                                align="center"
                            >
                                La ruta a la cual trata de acceder No existe.
                            </Typography>
                        </Box>

                    </Container>
                }


            </Box>

        </>

    );
}

export default Announcement;