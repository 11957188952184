import React from 'react';
import { Box, Chip, Paper, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, TablePagination } from '@material-ui/core';
import ButtonRenewDomain from './ButtonRenewDomain';
import TimeIcon from '@material-ui/icons/AccessTimeFilled'

const TableDomains = (props) => {
    
    const { domains, page, limit, handlePageChange, handleLimitChange } = props;

    return (
        <Box mt={1} mb={3} >
            <TableContainer component={Paper} variant='tbLight-nk'>
                <Table aria-label="domains table" sx={{ minWidth: 650 }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Nombre de Dominio</strong></TableCell>
                            <TableCell align="left"><strong>Estado</strong></TableCell>
                            <TableCell align="left"><strong>Fecha de Registro</strong></TableCell>
                            <TableCell align="left"><strong>Vencimiento</strong></TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {domains.slice(page * limit, page * limit + limit).map((domain) => (
                            <TableRow hover key={domain.id}>
                                <TableCell component="th" scope="row">
                                    {domain.name}
                                </TableCell>
                                <TableCell align="left">
                                    {(domain.status === 'Pending' || domain.status === 'Pending Registration') &&
                                        <Chip color="secondary" size="small" label={domain.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#E3E8EE', color: '#53566B' }} />
                                    }
                                    {domain.status === 'Active' &&
                                        <Chip size="small" label={domain.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#cbf4c9', color: '#268717' }} />
                                    }
                                    {domain.status === 'Pending Transfer' &&
                                        <Chip size="small" label={domain.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#c0ddff', color: '#6773c9' }} />
                                    }
                                    {(domain.status === 'Grace' || domain.status === 'Redemption' || domain.status === 'Expired' || domain.status === 'Cancelled' || domain.status === 'Transferred Away') &&
                                        <Chip size="small" label={domain.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#F8DADB', color: '#B72136' }} />
                                    }
                                </TableCell>
                                <TableCell >{domain.registrationdate}</TableCell>

                                {domain.expirydate === '00/00/0000' ?
                                    <TableCell >{domain.registrationdate}</TableCell>
                                    :
                                    <TableCell >{domain.expirydate}</TableCell>
                                }
                                {(domain.expirydate === '00/00/0000' || domain.status === "Cancelled" || domain.status === "Transferred Away") ?
                                    <TableCell ></TableCell>
                                    :
                                    <>
                                        {domain.status === 'Active' ? 
                                        <TableCell> 
                                            {domain.expirydays < 30 ? 
                                                <Box sx={{display: "inline-table", width: "9rem", fontSize: "1.3rem", color: "#ff5a5a"}}> 
                                                <TimeIcon sx={{fontSize: "1.6rem",verticalAlign:"text-top",marginTop:"0.1rem",marginRight:"0.2rem"}} /> {domain.expirydays} días
                                                </Box>
                                            :
                                                <Box sx={{display: "inline-table", width: "9rem", fontSize: "1.3rem"}}> 
                                                <TimeIcon sx={{fontSize: "1.6rem",verticalAlign:"text-top",marginTop:"0.1rem",marginRight:"0.2rem"}} /> {domain.expirydays} días
                                                </Box>
                                            }
                                            
                                            <ButtonRenewDomain domainid={domain.id} userid={domain.userid} />
                                        </TableCell>
                                            :
                                        <TableCell> <Box sx={{display: "inline-table", width: "9rem" }} />  <ButtonRenewDomain domainid={domain.id} userid={domain.userid} /></TableCell>
                                        }
                                    </>
                                    
                                    
                                }

                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={domains.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25]}
            />

        </Box>

    );
}

export default TableDomains;