import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Divider, Grid, TextField, Typography } from '@mui/material';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useLocation, useNavigate } from 'react-router-dom';
import { disable2FA, validatePassword } from '../../services/User';
import { getPublicIp } from '../../utils';

function ChangeTwoStepAuth() {

    let enable = 0;
    const location = useLocation();
    const navigate = useNavigate();

    if (location.state && location.state.enable === 1) {
        enable = 1;
    }

    const [errValidPass, setErrValidPass] = useState(null);
    const [loadingPass, setLoadingPass] = useState(false);

    const confirmPassword = async (val, actions) => {
        setLoadingPass(true);

        const validation = await validatePassword(val.password);

        if (validation.error === false) {

            if (validation.ispass === true) {

                //create register 2FA
                const ip = await getPublicIp();
                const reg2fa = await disable2FA(ip);

                //console.log("registro reg2fa", reg2fa);

                if (reg2fa.error === false) {
                    navigate('/clientarea/account/', { replace: true });
                } else {
                    actions.resetForm();
                    setErrValidPass("Hubo un error, por favor contacte con soporte");
                    setLoadingPass(false);
                }

            } else {
                actions.resetForm();
                setErrValidPass("Contraseña incorrecta");
                setLoadingPass(false);
            }

        } else {
            setErrValidPass("Contraseña incorrecta");
            setLoadingPass(false);
        }
    };

    const initialValues = {
        password: ''
    };
    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required("Contraseña es requerida")
    });

    return (
        <>
            <Helmet>
                <title>Portal - Área de clientes de Neothek</title>
            </Helmet>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center'
                }}
            >

                {enable === 1 ?

                    <Container maxWidth="sm" variant='cont-nk' sx={{mt: "3rem"}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box mt={3} mb={3} >
                                    <Typography variant='h4' sx={{ textAlign: "center" }}>Deshabilitar Verificación en 2 pasos</Typography>
                                    <Divider />
                                </Box>
                            </Grid>

                            <Grid item xs={12} >
                                <Typography sx={{ fontSize: "1.33rem" }}>Debes verificar que eres tú para poder continuar</Typography>

                                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={confirmPassword}>
                                    {(props) => (

                                        <Form>
                                            <Grid container spacing={2} sx={{ pt: 4 }}>
                                                <Grid item xs={12}>
                                                    {errValidPass &&
                                                        <Box>
                                                            <Typography sx={{ color: 'red', paddingBottom: "1rem", fontSize: "1.2rem" }} >
                                                                {errValidPass}
                                                            </Typography>
                                                        </Box>
                                                    }
                                                    <Field as={TextField} variant="outlined" name='password' label='Ingresa tu contraseña' type="password" fullWidth
                                                        error={props.errors.password && props.touched.password}
                                                        helperText={<ErrorMessage name='password' />} required />
                                                </Grid>
                                            </Grid>

                                            <Grid container justifyContent="flex-end" sx={{ pb: 4 }}>
                                                <LoadingButton loading={loadingPass} type='submit' variant='contained' color='primary' sx={{ my: 2 }}>
                                                    Deshabilitar
                                                </LoadingButton>
                                            </Grid>

                                        </Form>

                                    )}
                                </Formik>

                            </Grid>

                        </Grid>
                    </Container>

                    :

                    <Container maxWidth="sm" variant='cont-nk' sx={{mt: "3rem"}}>

                        <Box sx={{ mb: 2, mt: 3 }}>
                            <Typography
                                color="textPrimary"
                                variant="h2"
                                align="center"
                            >
                                Lo sentimos,
                            </Typography>

                        </Box>

                        <Box sx={{ pt: 1, pb: 2 }}>
                            <Typography
                                color="textSecondary"
                                gutterBottom
                                variant="body1"
                                align="center"
                            >
                                Esta opción no se encuentra disponible, verifica las opciones en "Administrar Cuenta"
                            </Typography>

                        </Box>

                    </Container>

                }

            </Box>

        </>
    );
}

export default ChangeTwoStepAuth;