import axios from 'axios';
import appConfig from '../config/main.config';
import { logout } from '../hooks/useAuth';
const apiserv = appConfig.url_api;

export const getServicesClient = () => {

    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/orders/`;

    const client = axios.get(api, { headers: { "Authorization": `Bearer ${token}` } })
        .then(res => {
            //console.log('res data services', res.data)
            return { ...res.data, ...{ error: false } };
        })
        .catch((error) => {
            //console.log(error.config);
            /*if(error.response.status === 401){
                logout();
            }*/
            return {error: true, message: error.response}

        });

    return client;

}