import axios from 'axios';
import appConfig from '../config/main.config';
const apiserv = appConfig.url_api;

export const getAnnouncements = () => {

    const api = `${apiserv}/announcements/`;

    const announcements = axios.get(api)
        .then(res => {
            return { announcements: res.data, error: false}
        })
        .catch((error) => {
            return  {error: true};
        });

    return announcements;

};

export const getLatestAnnouncements = () => {

    const api = `${apiserv}/announcements/latest/`;

    const announcements = axios.get(api)
        .then(res => {
            return { announcements: res.data, error: false}
        })
        .catch((error) => {
            return  {error: true};

        });

    return announcements;

};

export const getAnnouncement = (idad) => {
    const api = `${apiserv}/announcements/${idad}`;

    const announcement = axios.get(api)
        .then(res => {
            return { ...res.data, ... {error: false}}
        })
        .catch((error) => {
            return  {error: true};
        });

    return announcement;
}