import axios from 'axios';
import appConfig from '../config/main.config';
const apiserv = appConfig.url_api;

/*export const authUser = async (email, pass, ip) => {
    //console.log('auth user')

    const datasend = {
        email: email,
        password: pass,
        ip: ip
    }

    const res = await axios.post(`${apiserv}/users/login/`, datasend)
        .then(function (response) {
            return { ...response.data, ...{ error: false } };
        })
        .catch(function (error) {
            return { ...error.response.data, ...{error: true} }
        });

    return res
};*/

export const createUser = async (data) => {
    //console.log('datos enviados crear usuario', data)
    const res = await axios.post(`${apiserv}/users`, data)
        .then(resp => {
            return { status: true } 
        })
        .catch(err => {
             return { status: false } 
        })

    return res;
};

export const validateUser = (email) => {
    const api = `${apiserv}/users/${email}/`;

    const user = axios.get(api)
        .then(res => {
            return { ...res.data, ...{ error: false } };
        })
        .catch((error) => {
            return {error: true};
        });

    return user;
};

export const validate2FA = () => {
    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/users/validate-2fa/`;

    const factor2fa = axios.get(api, { headers: { "Authorization": `Bearer ${token}` } })
        .then(res => {
            return { ...res.data, ...{error: false}}
        })
        .catch((error) => {
            return {error: true, message: error.response}
        });

    return factor2fa;
};

export const validatePassword = (password) => {
    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/users/confirmpass/`;

    const senddata = {
        pass: password
    }

    const validate = axios.post(api, senddata, { headers: { "Authorization": `Bearer ${token}` } } )
        .then(res => {
            return { ...res.data, ...{error: false}}
        })
        .catch((error) => {
            return {error: true, message: error.response}
        });

    return validate;

};

export const register2FA = (ipaddress) => {
    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/users/register-2fa/`;

    const senddata = {
        ip: ipaddress
    }

    const record = axios.post(api, senddata, { headers: { "Authorization": `Bearer ${token}` } } )
        .then(res => {
            return { ...res.data, ...{error: false}}
        })
        .catch((error) => {
            return {error: true}
        });

    return record;
};

export const activate2FA = (idauth, codeauth, ip) => {
    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/users/active-2fa/`;

    const senddata = {
        ip: ip,
        idauth: idauth,
        codeauth: codeauth
    }

    const record = axios.post(api, senddata, { headers: { "Authorization": `Bearer ${token}` } } )
        .then(res => {
            return { ...res.data, ...{error: false}}
        })
        .catch((error) => {
            return {error: true}
        });

    return record;
};

/*export const disable2FA = (ipaddress) => {
    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/users/disable-2fa/`;

    const senddata = {
        ip: ipaddress
    }

    const record = axios.post(api, senddata, { headers: { "Authorization": `Bearer ${token}` } } )
        .then(res => {
            return { ...res.data, ...{error: false}}
        })
        .catch((error) => {
            return {error: true}
        });

    return record;
};

export const verifyCodeAuth2fa = (idauth, codeauth, ip) => {
    const api = `${apiserv}/users/login-2fa/`;

    const senddata = {
        idauth: idauth,
        codeauth: codeauth,
        ip: ip
    }

    const iscode = axios.post(api, senddata)
        .then(res => {
            return { ...res.data, ...{error: false}}
        })
        .catch((error) => {
            return {error: true}
        });

    return iscode;
};*/

export const loginAccountClient = (uid, cid, language, ip) => {
    const api = `${apiserv}/users/login-clientaccount/`;

    const senddata = {
        uid: uid,
        cid: cid,
        language: language,
        ip: ip
    }

    const logclient = axios.post(api, senddata)
        .then(res => {
            return { ...res.data, ...{error: false}}
        })
        .catch((error) => {
            return {error: true}
        });

    return logclient;
};

export const updatePassword = (ip, password) => {
    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/users/password-update/`;

    const senddata = {
        ip: ip,
        password: password
    }

    const record = axios.put(api, senddata, { headers: { "Authorization": `Bearer ${token}` } } )
        .then(res => {
            return { ...res.data, ...{error: false}}
        })
        .catch((error) => {
            return {error: true}
        });

    return record;
};

export const createSessionCP = (hostid) => {
    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/users/create-session-cpanel/`;

    const senddata = {
        hostid: hostid
    }

    const session = axios.post(api, senddata, { headers: { "Authorization": `Bearer ${token}` } } )
        .then(res => {
            return { ...res.data, ...{error: false}}
        })
        .catch((error) => {
            return {error: true}
        });

    return session;
};

//TESTEAR
export const authUser = async (email, pass, ip) => {
    //console.log('auth user')

    const datasend = {
        email: email,
        password: pass,
        ip: ip
    }

    const res = await axios.post(`${apiserv}/users/login/`, datasend)
        .then(function (response) {
            return { ...response.data, ...{ error: false } };
        })
        .catch(function (error) {
            return { ...error.response.data, ...{error: true} }
        });

    return res
};
export const verifyCodeAuth2fa = (idauth, codeauth, ip, remember) => {
    const api = `${apiserv}/users/login-2fa/`;

    const senddata = {
        idauth: idauth,
        codeauth: codeauth,
        ip: ip,
        remember: remember
    }

    const iscode = axios.post(api, senddata)
        .then(res => {
            return { ...res.data, ...{error: false}}
        })
        .catch((error) => {
            return {error: true}
        });

    return iscode;
};
export const disable2FA = (ipaddress) => {
    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/users/disable-2fa/`;

    const senddata = {
        ip: ipaddress
    }

    const record = axios.post(api, senddata, { headers: { "Authorization": `Bearer ${token}` } } )
        .then(res => {
            return { ...res.data, ...{error: false}}
        })
        .catch((error) => {
            return {error: true}
        });

    return record;
};



