import React from 'react';
import { Box, Link, Paper, TableContainer, Table, TableRow, TableBody, TableCell, TablePagination } from '@mui/material'
import { NavLink as RouterLink } from 'react-router-dom';

const TableAnnouncements = (props) => {
    const { announcements, page, limit, handlePageChange, handleLimitChange } = props;

    return (
        <Box mt={1} mb={3} >
            <TableContainer component={Paper} variant='tbLight3-nk'>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="announcements table">
                    
                    <TableBody>

                        {announcements.slice(page * limit, page * limit + limit).map((ad) => (
                            <TableRow hover key={ad.id}>
                                <TableCell component="th" scope="row">
                                    <Link component={RouterLink} to={`/announcements/${ad.id}/`} >{ad.title}</Link>
                                </TableCell>
                                <TableCell align="right">{ad.date}</TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={announcements.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[10, 15, 25]}
            />

        </Box>

    );
}

export default TableAnnouncements;