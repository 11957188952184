import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';

const DialogRenewProduct = (props) => {

    //const openDialog = props.openDialog;
    const openDialog = props.openDialog;
    const titleDialog = props.titleDialog;
    const contentDialog = props.contentDialog;


    const handleCloseDialog = () => {
        //setOpenDialog(false);
        //navigate('/clientarea/account/', { replace: true });
        const caso = 0; //cerrar o cancell
        props.actionDialog(false, caso);
    };
    const handleAgreeDialog = () => {
        const caso = 1; //continuar para renovar
        props.actionDialog(false, caso);
    }

    return (
        <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle variant="dialogTitle-nk" id="alert-dialog-title">
                {titleDialog}
            </DialogTitle>
            <DialogContent>
                <Typography>{contentDialog}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} autoFocus variant='outlined' size="small">
                    Cancelar
                </Button>
                <Button onClick={handleAgreeDialog} autoFocus variant='contained' size="small">
                    Continuar
                </Button>
            </DialogActions>

        </Dialog>
    );
}

export default DialogRenewProduct;