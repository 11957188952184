import React from 'react';
import Alert from '@material-ui/core/Alert';
import { Box, Button, Grid } from '@material-ui/core'
import { useNavigate } from 'react-router-dom';
import NextIcon from '@material-ui/icons/ArrowForward';

function AvailableDomain(props) {
    const navigate = useNavigate();

    const dominio = props.dominio;
    const tld = props.tld;
    const eventDomain = props.eventDomain;
    const epp = props.epp;

    function handleRoute() {
        navigate('/cart/domain/revorder/', { state: { domainName: dominio, tld: tld, eventDomain: eventDomain, epp: epp } });
    }

    return (
        <Grid>
            <Box mt={3}>
                {eventDomain === 'domainregister' && <Alert severity="success"><strong>{dominio}:</strong> Está disponible para registro</Alert>}
                {eventDomain === 'domaintransfer'
                    && <Alert severity="success"><strong>{dominio}:</strong> Su dominio es elegible para transferencia <br></br> Por favor, asegúrese de haber desbloqueado su dominio en el actual registrador antes de continuar.</Alert>}
            </Box>

            <Grid container dir="rtl">
                <Box mt={3}>
                    <Button color='success' variant='contained' startIcon={<NextIcon sx={{marginRight: '-8px', marginLeft: '6px'}} />}  onClick={handleRoute}>
                        Siguiente
                    </Button>
                </Box>

            </Grid>

        </Grid>


    )
}

export default AvailableDomain;