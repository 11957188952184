const appConfig = {
        /*url_domain: 'http://localhost:3000', //local
        url_api: 'http://localhost:4000', //local
        sitekey_captcha: '6LcPPYAbAAAAANzdHcxtpyxdTK-YIDG21c3yxEhK', //local
        pk_stripe: 'pk_test_51J33QqHR8NMwG6qnwfqjvrKUfnNTMAF3qCOdLu1PD4wbnuefU0CrEy8cubcLdvwfMTd0jj8Tq9DVTygzOeiyRIOm00Lj6HAG73', //local
        merchantid_payu: 508029, //local
        apikey_payu: '4Vj8eK4rloUd272L48hsrarnUA', //local
        url_payu:'https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/', //local
        sid_2co: 251181713859, //local */
        sid_2co: 1310877,  //online
        url_domain: 'https://portal.neothek.com', //online
        url_api: 'https://webservice-s1.portal.neothek.com/cli', //online
        sitekey_captcha: '6LfDEj8eAAAAAJ3cHVi2mIr6aD84uREgRaixoxn9', //online
        pk_stripe: 'pk_live_NqSWDeswjQ1T3tg8s5hPx5Ha', //online
        merchantid_payu: 500598, //online
        apikey_payu: '6q4lm6q1rb8e9kd4fc3f6484ec', //online
        url_payu:'https://checkout.payulatam.com/ppp-web-gateway-payu', //online
}

export default appConfig
