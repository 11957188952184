import React, { useMemo, useState, useRef } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Checkbox, Container, FormHelperText, Link, TextField, Typography, Grid, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import countryList from 'react-select-country-list';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { getPublicIp } from '../utils/index'
import { authUser, createUser } from '../services/User';
import { saveAuth } from '../hooks/useAuth';
import ReCAPTCHA from 'react-google-recaptcha';
import appConfig from '../config/main.config';


const Register = () => {
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(true);

  /* captcha */
  const captcha = useRef(null);
  const [validCaptcha, setValidCaptcha] = useState(null);

  const onChangeCaptcha = () => {
    if (captcha.current.getValue()) {
      setValidCaptcha(true);
      setButtonDisabled(false);
    } else {
      setValidCaptcha(false)
      setButtonDisabled(true);
    }
  }
  /*fin captcha */

  const inputPhone = useRef();
  const options = useMemo(() => countryList().getData(), [])

  const [codcountryphone, setcodcountryphone] = useState('us');
  const [codcountry, setcodcountry] = useState('US');

  const [phonenumber, setPhone] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const [errorPhone, setErrorPhone] = useState(false);

  const [statusUser, setStatusUser] = useState(null);

  const handleChangeCountry = ev => {
    const value = ev.target.value;
    const cc = value.toLowerCase();

    setcodcountry(value);
    setcodcountryphone(cc);

    setPhone(null);
  }

  const handlePhoneNumber = ev => {

    const statePhone = inputPhone.current.state;

    setErrorPhone(false);
    setPhone(statePhone.formattedNumber);
    setCountryCode(statePhone.selectedCountry.countryCode);

  }


  return (
    <>
      <Helmet>
        <title>Portal Neothek - Registrar</title>
      </Helmet>
      <Box>

        {statusUser === true &&
          <>Mensaje exitoso</>
        }

        {statusUser === false &&
          <Container maxWidth="sm" variant='cont-nk'>

            <Box sx={{ mb: 2, mt: 3 }}>
              <Typography
                color="textPrimary"
                variant="h2"
                align="center"
              >
                Lo sentimos,
              </Typography>

            </Box>

            <Box sx={{ pt: 1, pb: 2 }}>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body1"
                align="center"
              >
                No se ha logrado crear su cuenta de cliente. <br></br> Puede cargar nuevamente la página para volver a intentarlo.
              </Typography>

            </Box>

          </Container>
        }

        {statusUser === null &&

          <Container maxWidth="md" variant='cont-nk'>
            <Formik
              initialValues={{
                email: '',
                firstname: '',
                lastname: '',
                country: 'US',
                city: '',
                state: '',
                address1: '',
                address2: '',
                companyname: '',
                postcode: '',
                phonenumber: '',
                password: '',
                password2: '',
                policy: false
              }}
              validationSchema={
                Yup.object().shape({
                  firstname: Yup.string().max(255).required('Nombre es requerido'),
                  lastname: Yup.string().max(255).required('Apellido es requerido'),
                  email: Yup.string().email('Ingrese un e-mail válido').max(255).required('E-mail es requerido'),
                  city: Yup.string().required('Ciudad es requerida'),
                  state: Yup.string().required('Provincia/Estado es requerido'),
                  address1: Yup.string().required('Dirección 1 es requerido'),
                  address2: Yup.string(),
                  companyname: Yup.string(),
                  postcode: Yup.string()
                    .matches(/^[0-9A-Za-z- ]+$/, 'Por favor ingrese un código postal válido')
                    .min(4, 'Se acepta mínimo 4 dígitos')
                    .max(10, 'Se acepta máximo 10 dígitos')
                    .required('Código Postal es requerido'),
                  //phonenumber: Yup.number().required('Número de teléfono es requerido'),
                  password: Yup.string().min(8, 'Usa 8 caracteres como mínimo').max(255).required('Contraseña es requerida'),
                  password2: Yup.string().oneOf([Yup.ref('password'), null], 'La contraseña escrita no coincide'),
                  policy: Yup.boolean().oneOf([true], 'Este campo debe estar marcado')
                })
              }
              onSubmit={async (val, actions) => {
                if (phonenumber === null || phonenumber === '') {
                  setErrorPhone(true);
                } else {
                  //console.log('register', val);
                  const ip = await getPublicIp();
                  val.country = codcountry;
                  val.phonenumber = phonenumber;
                  val['countrycode'] = countryCode;
                  val['ip'] = ip;
                  //console.log('register new', val);
                  const res = await createUser(val);

                  actions.resetForm();

                  if (res.status) {
                    //setStatusUser(true);

                    //loguear usuario a su cuenta
                    const auth = await authUser(val.email, val.password); //verificar error en authuser

                    if (auth.error === false) {
                      saveAuth(auth);

                      navigate('/clientarea/dashboard/');

                    } else {
                      setStatusUser(false);
                    }


                  } else {
                    setStatusUser(false);
                  }
                  //navigate('/clientarea/dashboard/', { replace: true });
                }

              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (


                <form onSubmit={handleSubmit}>

                  <Grid container spacing={1} sx={{ pl: 3, pr: 3 }}>
                    <Grid item md={12}>
                      <Box sx={{ mt: 3, mb: 2 }}>
                        <Typography
                          color="textPrimary"
                          variant="h2"
                          align='center'
                        >
                          Crear una nueva Cuenta
                        </Typography>

                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        error={Boolean(touched.firstname && errors.firstname)}
                        fullWidth
                        helperText={touched.firstname && errors.firstname}
                        label="Nombre"
                        margin="normal"
                        name="firstname"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstname}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={Boolean(touched.lastname && errors.lastname)}
                        fullWidth
                        helperText={touched.lastname && errors.lastname}
                        label="Apellido"
                        margin="normal"
                        name="lastname"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastname}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="Dirección E-mail"
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                        size="small"
                      />

                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        error={Boolean(touched.companyname && errors.companyname)}
                        fullWidth
                        helperText={touched.companyname && errors.companyname}
                        label="Compañía (Opcional)"
                        margin="normal"
                        name="companyname"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.companyname}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl sx={{ mt: 2, minWidth: '100%' }}>
                        <InputLabel id="labelCountry" sx={{ ml: 1.7, mt: -1 }}>País</InputLabel>
                        <Select
                          labelId="labelCountry"
                          error={Boolean(touched.country && errors.country)}
                          fullWidth
                          //helperText={touched.country && errors.country}
                          label="País"
                          name="country"
                          onBlur={handleBlur}
                          onChange={handleChangeCountry}
                          value={codcountry}
                          variant="outlined"
                          size="small"
                        >

                          {options.map(({ value, label }) => (
                            <MenuItem key={value} value={value}>
                              {label}
                            </MenuItem>
                          ))}


                        </Select>
                      </FormControl>

                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        error={Boolean(touched.state && errors.state)}
                        fullWidth
                        helperText={touched.state && errors.state}
                        label="Provincia/Estado"
                        margin="normal"
                        name="state"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.state}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        error={Boolean(touched.city && errors.city)}
                        fullWidth
                        helperText={touched.city && errors.city}
                        label="Ciudad"
                        margin="normal"
                        name="city"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.city}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} md={6} sx={{ mt: 2 }}>
                      <PhoneInput
                        error={Boolean(touched.phonenumber && errors.phonenumber)}
                        fullWidth
                        helperText={touched.phonenumber && errors.phonenumber}
                        country={codcountryphone}
                        specialLabel='Teléfono'
                        name="phonenumber"
                        onBlur={handleBlur}
                        onChange={handlePhoneNumber}
                        value={values.phonenumber}
                        ref={inputPhone}
                      />
                      {errorPhone && <Typography sx={{ color: '#f44336', fontSize: '1.2rem', paddingLeft: '3%' }}>Teléfono es requerido</Typography>}

                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextField
                        error={Boolean(touched.address1 && errors.address1)}
                        fullWidth
                        helperText={touched.address1 && errors.address1}
                        label="Dirección 1"
                        margin="normal"
                        name="address1"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.address1}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        error={Boolean(touched.address2 && errors.address2)}
                        fullWidth
                        helperText={touched.address2 && errors.address2}
                        label="Dirección 2"
                        margin="normal"
                        name="address2"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.address2}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextField
                        error={Boolean(touched.postcode && errors.postcode)}
                        fullWidth
                        helperText={touched.postcode && errors.postcode}
                        label="Código Postal"
                        margin="normal"
                        name="postcode"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.postcode}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password || 'Utiliza 8 caracteres como mínimo: letras, números y símbolos'}
                        label="Contraseña"
                        margin="normal"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={Boolean(touched.password2 && errors.password2)}
                        fullWidth
                        helperText={touched.password2 && errors.password2}
                        label="Confirmar contraseña"
                        margin="normal"
                        name="password2"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.password2}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>

                  </Grid>


                  <Box sx={{ pl: 3, pr: 3 }}>

                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        ml: -1
                      }}
                    >
                      <Checkbox
                        checked={values.policy}
                        name="policy"
                        onChange={handleChange}
                      />
                      <Typography
                        color="textSecondary"
                        variant="body2"
                      >
                        Estoy de acuerdo con los
                        {' '}
                        <Link
                          color="primary"
                          component="a"
                          href="http://www.neothek.com/legal/"
                          underline="always"
                          variant="body2"
                        >
                          Términos y condiciones de Uso
                        </Link>
                      </Typography>
                    </Box>
                    {Boolean(touched.policy && errors.policy) && (
                      <FormHelperText error>
                        {errors.policy}
                      </FormHelperText>
                    )}


                    <Box align='center' sx={{ py: 2 }}>
                      <ReCAPTCHA
                        ref={captcha}
                        sitekey={appConfig.sitekey_captcha}
                        onChange={onChangeCaptcha}
                      />
                      {validCaptcha === false &&
                        <Typography sx={{ color: 'red' }} variant="h6">
                          Por favor acepte el capctha
                        </Typography>
                      }

                    </Box>

                    <Box sx={{ py: 2 }}>
                      <Button
                        color="primary"
                        disabled={buttonDisabled}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Registrarse ahora
                      </Button>
                    </Box>
                    <Box sx={{ pb: 3 }}>
                      <Typography
                        color="textSecondary"
                        variant="body1"
                      >
                        ¿Tienes una cuenta?
                        {' '}
                        <Link
                          component={RouterLink}
                          to="/login"
                          variant="h6"
                        >
                          Ingresar
                        </Link>
                      </Typography>
                    </Box>

                  </Box>

                </form>

              )}
            </Formik>
          </Container>

        }


      </Box>
    </>
  );
};

export default Register;
