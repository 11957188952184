import React, { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { registerStripePayment } from '../../services/Payment';
import appConfig from '../../config/main.config'
import { getPublicIp } from '../../utils';
import SpinnerLoad from '../FormsUI/Spinner';
const apiserv = appConfig.url_api;
//const urlParams = new URLSearchParams(window.location.search);
//const sessionId = urlParams.get('session_id');

function ReturnStripe(props) {
    const [dataStripe, setDataStripe] = useState('');
    const {session_id, user_id, invoice_id} = useParams();
    const sessionId = session_id;

    /*const searchIp = async () => {
      const ip = await getPublicIp();
      return ip;
    } */

    const datainvoice = {
        userid: user_id,
        currency: 0,
        gateway: 'stripe',
        description: 'Invoice Payment',
        invoiceid: invoice_id
      }


    useEffect(() => {
        if (sessionId) {
            fetch(`${apiserv}/gateways/stripe/checkout-session?sessionId=${sessionId}`)
              .then(function (result) {
                  //console.log('result stripe', result);
                return result.json();
              })
              .then(async function (session) {
                const sessionJSON = JSON.stringify(session, null, 2);
                //console.log('session stripe', session);
                setDataStripe(sessionJSON);

                datainvoice['ipclient'] = await getPublicIp();
                const paymentstripe =  await registerStripePayment(session, datainvoice);
                //console.log('pago stripe status', status);

                if(paymentstripe.error === false){
                  //navigate('/clientarea/success-payment/');
                  window.open(`/clientarea/invoices/${invoice_id}/`,"_self");
                }


              })
              .catch(function (err) {
                //console.log('Error when fetching Checkout session', err);
              });
        }
    }, [])

    return (
        <div>
            {dataStripe ? (
                <Container>
                  <SpinnerLoad />
                </Container>
            ) : (
                <Container>
                    
                </Container>
            )}
        </div>
    );
}

export default ReturnStripe;