import React from 'react';
import Alert from '@material-ui/core/Alert';
import { Box } from '@material-ui/core'

function DominioNoDisponible({ dominio, eventDomain }) {

  return (
    <Box mt={3}>
      {eventDomain === 'domainregister' && <Alert severity="error"><strong>{dominio}:</strong> No está disponible para registro</Alert>}
      {eventDomain === 'domaintransfer' &&
        <Alert severity="error"><strong>{dominio}:</strong> No elegible para transferencia <br></br>El dominio que ha especificado no parece estar registrado.<br></br> Si el dominio fue registrado recientemente, es posible que tenga que probar de nuevo más tarde <br></br> Alternativamente, puede realizar una búsqueda para registrar este dominio. </Alert>}

    </Box>
  )
}

export default DominioNoDisponible;