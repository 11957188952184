import { useState, useEffect } from 'react';
import { useLocation, NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, Hidden, List } from '@material-ui/core';
import NavItem from './NavItem';
import NavItemLogout from './NavItemLogout';
import { logout } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@material-ui/icons/Logout';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PublicIcon from '@material-ui/icons/Public';
import DnsIcon from '@material-ui/icons/Dns';
import InvoicesIcon from '@material-ui/icons/DateRange';
import AccountIcon from '@mui/icons-material/Portrait';
import ManageIcon from '@mui/icons-material/ManageAccounts';
import Logo from './Logo';

const items = [
    {
        href: '/clientarea/dashboard/',
        icon: DashboardIcon,
        title: 'Dashboard'
    },
    {
        href: '/clientarea/domains/',
        icon: PublicIcon,
        title: 'Dominios'
    },
    {
        href: '/clientarea/products/',
        icon: DnsIcon,
        title: 'Servicios'
    },
    {
        href: '/clientarea/invoices/',
        icon: InvoicesIcon,
        title: 'Pagos'
    },
    {
        href: '/clientarea/account/',
        icon: AccountIcon,
        title: 'Administrar Cuenta'
    },
    {
        href: '/clientarea/tickets/',
        icon: ManageIcon,
        title: 'Soporte'
    },
    /*{
        href: '/clientarea/account/',
        icon: ManageAccountsIcon,
        title: 'Cuenta'
    }*/
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
    const location = useLocation();
    const navigate = useNavigate();


    const handleLogout = () => {
        logout();
        navigate('/')
    }

    /*const user = {
        avatar: '',
        jobTitle: '',
        name: 'Katarina Smith'
    };*/

    const [user, setUser] = useState({ avatar: '', jobTitle: '', name: '- -' });


    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
    }, [location.pathname]);

    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
        >
            <Hidden lgUp>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    p: '0.75rem',
                    backgroundColor: '#f8f9fb'
                }}
            >

                <RouterLink to="/">
                    <Logo />
                </RouterLink>
            </Box>
            </Hidden>
            {/*  */}

            <Box sx={{ p: 2, pr: 0, pl: 0 }}>
                <List>
                    {items.map((item) => (
                        <NavItem
                            href={item.href}
                            key={item.title}
                            title={item.title}
                            icon={item.icon}
                        />
                    ))}
                    <NavItemLogout href="/logout" key="Salir" title="Salir" icon={LogoutIcon} onClick={handleLogout} />
                </List>
            </Box>

        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                    PaperProps={{
                        sx: {
                            width: 256
                        }
                    }}
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden lgDown>
                <Drawer
                    anchor="left"
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: 256,
                            top: 127, //64
                            height: 'calc(100% - 64px)',
                            borderRight: '1px solid #f8f9fb'
                        }
                    }}
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

DashboardSidebar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
    onMobileClose: () => { },
    openMobile: false
};

export default DashboardSidebar;
