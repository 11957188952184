import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Container, Grid, Typography, Paper } from '@material-ui/core';
import { CalendarToday, HelpOutline, Language, SupportAgent } from '@material-ui/icons';
import CarouselNotices from '../components/CarouselNotices';
import { getLatestAnnouncements } from '../services/Announcement';

const Home = () => {

    const [announcements, setAnnouncements] = useState([]);

    useEffect(() => {
        const getAds = async() => {
            const ads = await getLatestAnnouncements();

            if(ads.error === false){
                setAnnouncements(ads.announcements);
            }
        }
        getAds();
    }, [])

    return (
        <>
            <Helmet>
                <title>Portal Neothek - Área de clientes de Neothek</title>
            </Helmet>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center'
                }}
            >
                {/* <Container maxWidth="lg" variant='cont-nk'>*/}

                <Container sx={{ paddingTop: "4%" }}>

                    <Grid container spacing={3}>
                        <Grid item md={4} sm={6} xs={12} component={RouterLink} to="/cart/domain/" >
                            <Paper variant="homedomain-nk">
                                <Language sx={{ backgroundColor: "#9278de", fontSize: "42px", padding: "3% 3%", borderRadius: "8px", color: "#fff" }}></Language>
                                <Typography sx={{ fontSize: "2.1rem", paddingTop: "2%" }}> <b>Comprar Dominio</b> </Typography>
                                <Typography sx={{ color: "#927bdc" }}>Pedir Ahora</Typography>
                            </Paper>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                            <a href="https://www.neothek.com/web-hosting/">
                            <Paper variant="homehost-nk">
                                <CalendarToday sx={{ backgroundColor: "#3da3ff", fontSize: "42px", padding: "3% 3%", borderRadius: "8px", color: "#fff" }}></CalendarToday>
                                <Typography sx={{ fontSize: "2.1rem", paddingTop: "2%" }}> <b>Comprar Hosting</b> </Typography>
                                <Typography sx={{ color: "#44a5fc" }}>Ver Planes</Typography>
                            </Paper>
                            </a>
                        </Grid>

                        <Grid item md={4} sm={12} xs={12}>
                            
                            <Paper variant="homesupport-nk" sx={{marginBottom: "3%"}}>
                                <a href="https://www.neothek.com/contacto/">
                                <Grid container spacing={2}>
                                    <Grid item sm={2}>
                                        <SupportAgent sx={{ backgroundColor: "#5da8fc", fontSize: "40px", padding: "21% 21%", borderRadius: "8px", color: "#fff" }} />
                                    </Grid>
                                    <Grid item sm={8}>
                                        <Typography sx={{ fontSize: "1.5rem", paddingTop: "2%" }}> <b>Soporte Técnico</b> </Typography>
                                        <Typography sx={{ color: "#61aaf9", fontSize: "14px" }}>Enviar ticket de consulta</Typography>
                                    </Grid>
                                </Grid>
                                </a>
                            </Paper>
                            
                            <Paper variant="homefaq-nk" sx={{marginBottom: "3%"}}>
                                <a href="https://www.neothek.com/soporte/">
                                <Grid container spacing={2}>
                                    <Grid item sm={2}>
                                        <HelpOutline sx={{ backgroundColor: "#fdd66c", color: "#fff", fontSize: "40px", padding: "21% 21%", borderRadius: "8px" }} />
                                    </Grid>
                                    <Grid item sm={8}>
                                        <Typography sx={{ fontSize: "1.5rem", paddingTop: "2%", color: "#494848" }}> <b>Preguntas Frecuentes</b> </Typography>
                                        <Typography sx={{ color: "#9e9e9e", fontSize: "14px" }}>Encuentra respuestas</Typography>
                                    </Grid>
                                </Grid>
                                </a>
                            </Paper>

                        </Grid>
                    </Grid>

                    <Grid container sx={{marginTop: "4%", marginBottom: "12%"}} >
                        <Typography variant='h3' color="text.primary">Nuestras últimas Noticias</Typography>

                        <Grid item sm={12} xs={12}>
                            <Paper variant="homenotices-nk" sx={{padding: "2%", marginTop: "2%"}}>
                                <CarouselNotices announcements={announcements}></CarouselNotices>
                            </Paper>
                            
                        </Grid>
                        
                    </Grid>

                </Container>


            </Box>
        </>
    );
};

export default Home;
