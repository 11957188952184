import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Box, Container, Grid, Typography, MenuItem, TextField, Tab, Divider, makeStyles } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import StepperCart from '../components/FormsUI/StepperCart'
import axios from 'axios'
import * as Yup from 'yup'
import { useLocation } from 'react-router-dom'
import { getDomainTlds } from '../services/Product'
import { getPublicIp, getTimeNow } from '../utils/index'
import appConfig from '../config/main.config'
import VerifyDomainOnly from '../components/verify/VerifyDomainOnly';
const api = appConfig.url_api;

const useStyles = makeStyles((theme) => ({
    [theme.breakpoints.down('sm')]: {
        contDomainReg: {
            padding: '0px'
        }
    }
}));

const SearchDomainOrder = (props) => {
    //const rrr= new URLSearchParams(props.location.search).get("pid")
    //this.props.match.params.id
    //console.log('res', this.props)
    const classes = useStyles();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    //const confopt = urlParams.get('confopt');
    let tabIni = '1';
    let sld = '';
    let tld = '.com';
    let actionDomain = urlParams.get('action');
    
    if(actionDomain === 'transfer') {
        tabIni = '2';
    }
    if(urlParams.get('sld') !== null && urlParams.get('sld') !== ''){
        sld = urlParams.get('sld');
    }
    if(urlParams.get('tld') !== null && urlParams.get('tld') !== ''){
        tld = urlParams.get('tld');
    }

    const [domainRegister, setDomainRegister] = useState('');
    const [domainRegStatus, setDomainRegStatus] = useState('');
    const [tldRegister, setTldRegister] = useState('.com');
    const [loadingButtonReg, setLoadingButtonReg] = useState(false);

    const [domainTransfer, setDomainTransfer] = useState('');
    const [domainTraStatus, setDomainTraStatus] = useState('');
    const [tldTransfer, setTldTransfer] = useState('.com');
    const [loadingButtonTransf, setLoadingButtonTransf] = useState(false);

    const [epp, setEpp] = useState('');

    //selectDomain
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState([
        { label: "Cargando...", value: ".com" }
    ])

    //tabs
    const [valueTab, setValueTab] = useState(tabIni);
    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    }

    async function handleWhoisDomain(dom, ext) {
        const ipv4 = await getPublicIp()
        const today = getTimeNow('yyyy-mm-dd H:MM:ss')
        const domain = dom + ext

        const data = {
            domain: domain,
            date: today,
            ip: ipv4
        };

        axios.post(`${api}/logs/whois/`, data)
            .then(function (response) {
                //console.log(response);
            })
            .catch(function (error) {
                //console.log(error);
            });

    }

    const handleTransferDomain = (dom, ext, epp) => {
        const nombredominio = {
            domain: dom,
            extension: ext
        };
        const domext = dom + ext;

        axios.post(`${api}/domain/isavailable/`, nombredominio)
            .then(function (response) {
                //console.log(response);
                setEpp(epp);
                setDomainTransfer(domext);
                setDomainTraStatus(response.data[domext]["status"]);
                setTldTransfer(ext);
                setLoadingButtonTransf(false);

            })
            .catch(function (error) {
                //console.log(error);
        });

        /*setEpp(epp);
        setDomainTransfer(domext);
        setDomainTraStatus('regthroughothers');
        setTldTransfer(ext);*/

    };

    const handleVerifyDomain = (dom, ext) => {
        const nombredominio = {
            domain: dom,
            extension: ext
        };
        const domext = dom + ext;

        //no olvidar cambiar
        axios.post(`${api}/domain/isavailable/`, nombredominio)
            .then(function (response) {
                //console.log(response);
                setEpp('');
                setDomainRegister(domext);
                setDomainRegStatus(response.data[domext]["status"]);
                setTldRegister(ext);
                setLoadingButtonReg(false);

            })
            .catch(function (error) {
                //console.log(error);
        });

        /*setEpp('');
        setDomainRegister(domext);
        //setDomainRegStatus('regthroughus');
        setDomainRegStatus('available');
        setTldRegister(ext); */

    };

    //Formik
    const initialValues = {
        dominioform: sld,
        extensionform: tld,
        eppform: ''
    }
    const validationSchema = Yup.object().shape({
        dominioform: Yup.string()
            .required("Requerido")
            .matches(/^[aA-zZ0-9-]+$/, 'Únicamente se permite letras y números sin espacios')
    })
    const onSubmitReg = (values, props) => {
        const dom = values.dominioform.toLowerCase();
        const ext = values.extensionform;
        setLoadingButtonReg(true);
        handleVerifyDomain(dom, ext);
        handleWhoisDomain(dom, ext);
        //props.resetForm()
    }
    const onSubmitTransfer = (values, props) => {
        const dom = values.dominioform.toLowerCase();
        const ext = values.extensionform;
        const epp = values.eppform;
        setLoadingButtonReg(true);
        handleTransferDomain(dom, ext, epp);
        handleWhoisDomain(dom, ext);
    }


    useEffect(() => {
        let unmounted = false;
        async function getTLDs() {
            const body = await getDomainTlds();
            //console.log('tlds', body);

            if (!unmounted) {
                setItems(body.map((domain) => ({ label: domain.extension, value: domain.extension })))
                setLoading(false)
            }
            //const existProd = await getProductAvailable(pid);
            //console.log('salio a buscar product', existProd);
        }

        getTLDs();
        return () => {
            unmounted = true;
        }
    }, [])


    return (
        <>
            <Helmet>
                <title>Portal - Área de Clientes de Neothek - Registrar Dominio</title>
            </Helmet>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center'
                }}
            >
                <Container maxWidth="md">
                    <Grid item xs={12} md={12}>
                        <Box mt={3} mb={3} >
                            <Typography variant='h4'>Registro de Dominio</Typography>
                            <Divider />
                        </Box>
                        <StepperCart step={0} />
                    </Grid>
                </Container>

                <Container maxWidth="md" variant='cont-nk'>
                    <Grid container>
                        {/* tabs */}
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={valueTab}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                                        <Tab label="Registrar un nuevo dominio" value="1" sx={{ fontSize: '11px' }} />
                                        <Tab label="Transferir Dominio" value="2" sx={{ fontSize: '11px' }} />
                                    </TabList>
                                </Box>
                                <TabPanel value="1" className={classes.contDomainReg}>
                                    {/* registrar dominio */}
                                    <Box sx={{ py: 4 }} >
                                        <Grid item xs={12} md={12}>
                                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitReg}>
                                                {(props) => (

                                                    <Form>
                                                        <Grid container spacing={2}>

                                                            <Grid item sm={8} xs={7}>
                                                                <Field as={TextField} variant="outlined" name='dominioform' label='Dominio' fullWidth
                                                                    error={props.errors.dominioform && props.touched.dominioform}
                                                                    helperText={<ErrorMessage name='dominioform' />} required />
                                                            </Grid>
                                                            <Grid item sm={4} xs={5}>
                                                                <Field as={TextField} variant="outlined" name='extensionform' label='Extensión' select fullWidth>

                                                                    {items.map(({ label, value }) => (
                                                                        <MenuItem key={value} value={value}>
                                                                            {label}
                                                                        </MenuItem>
                                                                    ))}

                                                                </Field>

                                                            </Grid>

                                                            <Grid container justifyContent="flex-end">
                                                                <LoadingButton loading={loadingButtonReg} type='submit' variant='contained' color='primary' sx={{ my: 2 }} > Comprobar </LoadingButton>
                                                            </Grid>

                                                        </Grid>
                                                    </Form>

                                                )}
                                            </Formik>

                                            <VerifyDomainOnly domainstatus={domainRegStatus} inputdomain={domainRegister} tld={tldRegister} eventDomain='domainregister' epp={epp} ></VerifyDomainOnly>
                                        </Grid>
                                    </Box>
                                    {/* registrar dominio */}
                                </TabPanel>
                                <TabPanel value="2" className={classes.contDomainReg}>
                                    {/* transferir dominio */}
                                    <Box sx={{ py: 4 }}>
                                        <Grid item xs={12} md={12}>
                                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitTransfer}>
                                                {(props) => (

                                                    <Form>
                                                        <Grid container spacing={2}>

                                                            <Grid item xs={8}>
                                                                <Field as={TextField} variant="outlined" name='dominioform' label='Dominio' fullWidth
                                                                    error={props.errors.dominioform && props.touched.dominioform}
                                                                    helperText={<ErrorMessage name='dominioform' />} required />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Field as={TextField} variant="outlined" name='extensionform' label='Extensión' select fullWidth>

                                                                    {items.map(({ label, value }) => (
                                                                        <MenuItem key={value} value={value}>
                                                                            {label}
                                                                        </MenuItem>
                                                                    ))}

                                                                </Field>

                                                            </Grid>

                                                            <Grid item xs={12} mt={2}>
                                                                <Typography color="colornk.grey3" variant="txtSummary-nk">Si aún no cuenta con el Código EPP o de Authorización podrá indicarnos posteriormente.</Typography>
                                                            </Grid>

                                                            <Grid item xs={12}>

                                                                <Field as={TextField} variant="outlined" name='eppform' label='EPP Code / Auth Code' size="small" fullWidth
                                                                    error={props.errors.eppform && props.touched.eppform}
                                                                    helperText={<ErrorMessage name='eppform' />} />
                                                            </Grid>

                                                            <Grid container justifyContent="flex-end">
                                                                <LoadingButton loading={loadingButtonTransf} type='submit' variant='contained' color='primary' sx={{ my: 2 }}>
                                                                    Transferir
                                                                </LoadingButton>
                                                            </Grid>

                                                        </Grid>
                                                    </Form>

                                                )}
                                            </Formik>

                                            <VerifyDomainOnly domainstatus={domainTraStatus} inputdomain={domainTransfer} tld={tldTransfer} eventDomain='domaintransfer' epp={epp} ></VerifyDomainOnly>
                                        </Grid>
                                    </Box>
                                    {/* transferir dominio */}
                                </TabPanel>
                            </TabContext>
                        </Box>

                    </Grid>

                </Container>
            </Box>
        </>
    )
}

export default SearchDomainOrder;
