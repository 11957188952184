import {
    NavLink as RouterLink,
    matchPath,
    useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ListItem } from '@material-ui/core';

const NavItem = ({
    href,
    icon: Icon,
    title,
    ...rest
}) => {
    const location = useLocation();

    const active = href ? !!matchPath({
        path: href,
        end: false
    }, location.pathname) : false;

    return (
        <ListItem
            disableGutters
            sx={{
                display: 'flex',
                py: 0,
                outlineWidth: '0px'
            }}
            {...rest}
        >
            <Button
          component={RouterLink}
          sx={{
            //color: '#99a2b4',
            color: '#545963',
            //fontWeight: '500',
            fontWeight: '600',
            fontSize: '1.35rem',
            justifyContent: 'flex-start',
            letterSpacing: 0,
            py: 1.25,
            px: 2,
            //textTransform: 'uppercase',
            textTransform: 'none',
            width: '100%',
            borderRadius: '0px',
            ...(active && {
              /*color: 'primary.main',
              backgroundColor: 'secondary.light'*/
              backgroundColor: '#edf3ff',
              borderLeft: '5px solid #4687ff'

            }),
            '& svg': {
              mr: 1
            },
            //'&:hover': {color: 'primary.main'},
            //'&:focus': {color: 'primary.main', outline: 'none'}
            '&:focus': {outline: 'none'}

          }}
          to={href}
        >
          {Icon && (
            <Icon size="20" />
          )}
          <span>
            {/*<strong> {title} </strong>*/}
            {title}
          </span>
        </Button>
        </ListItem>
    );
};

NavItem.propTypes = {
    href: PropTypes.string,
    icon: PropTypes.elementType,
    title: PropTypes.string
};

export default NavItem;
