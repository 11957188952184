import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import AccountIcon from '@mui/icons-material/AccountCircle';
import TableClientAccounts from './TableClientAccounts';

function LoginAccountClient(props) {
    const location = useLocation();

    let accounts = [];
    let uid = null;
    let language = "";

    if(location.state && location.state.accounts && location.state.uid){
        accounts = location.state.accounts;
        uid = location.state.uid;
        language = location.state.language
    }
    
    return (
        <>
            <Helmet>
                <title>Portal - Área de clientes de Neothek</title>
            </Helmet>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center'
                }}
            >

                {(accounts.length !== 0 && uid !== null) ?

                    <Container maxWidth="sm" variant='cont-nk' sx={{mt: "3rem"}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box mt={3} mb={3} >
                                    <Typography variant='h4' sx={{ textAlign: "center" }}>Seleccionar cuenta</Typography>
                                    <Divider />
                                </Box>
                            </Grid>

                            <Grid item xs={12} sx={{ textAlign: "center" }}>
                                <AccountIcon color="primary" sx={{ fontSize: "8rem" }} />
                            </Grid>

                            <Grid item xs={12} >
                                <Typography sx={{ fontSize: "1.33rem", mb:"2rem" }}>Elija la cuenta para iniciar sesión y administrar </Typography>

                                <TableClientAccounts accounts = {accounts} uid = {uid} language = {language} />

                            </Grid>

                        </Grid>
                    </Container>

                    :

                    <Container maxWidth="sm" variant='cont-nk'>

                        <Box sx={{ mb: 2, mt: 3 }}>
                            <Typography
                                color="textPrimary"
                                variant="h2"
                                align="center"
                            >
                                Lo sentimos,
                            </Typography>

                        </Box>

                        <Box sx={{ pt: 1, pb: 2 }}>
                            <Typography
                                color="textSecondary"
                                gutterBottom
                                variant="body1"
                                align="center"
                            >
                                Esta opción no se encuentra disponible, por favor intente nuevamente.
                            </Typography>

                        </Box>

                    </Container>

                }

            </Box>

        </>
    );

}

export default LoginAccountClient;