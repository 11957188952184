import axios from 'axios';
import appConfig from '../config/main.config';
const apiserv = appConfig.url_api;

export const getTicketsClient = () => {

    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/tickets/`;

    const tickets = axios.get(api, { headers: { "Authorization": `Bearer ${token}` } })
        .then(res => {
            return { ...res.data, ...{error: false}}
        })
        .catch((error) => {
            return {error: true, message: error.response}
        });

    return tickets;

};

export const getTicketReplies = (tid, c) => {

    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/tickets/${tid}/c/${c}/`;

    const replies = axios.get(api, { headers: { "Authorization": `Bearer ${token}` } })
        .then(res => {
            return { ...res.data, ...{error: false}}
        })
        .catch((error) => {
            return {error: true, message: error.response}
        });

    return replies;

};

export const setTicketReply = (tid, c, ticketid, message, attachfiles) => {
    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/tickets/${tid}/c/${c}/ticketreplies/`;

    const formData = new FormData();
    formData.append("ticketid", ticketid);
    formData.append("message", message);
    attachfiles.map(file => {
        formData.append("attachments", file);
    })

    const reply = axios.post(api, formData, { headers: { "Authorization": `Bearer ${token}` } } )
        .then(res => {
            return { ...res.data, ...{error: false}}
        })
        .catch((error) => {
            //console.log(error, error.response);
            return {error: true, message: error.response}
        });

    return reply;

}

export const getOptionsTicket = () => {
    const api = `${apiserv}/tickets/options/`;

    const options = axios.get(api)
        .then(res => {
            return {...res.data, ...{error: false}};
        })
        .catch((error) => {
            //console.log('dio error options', error.response.data)
            return {error: true, message: error.response}
        });

    return options;

};

export const openTicket = (data, attachfiles) => {
    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/tickets/`;

    const formData = new FormData();
    formData.append("subject", data.subject);
    formData.append("deptid", data.deptid);
    formData.append("urgency", data.urgency);
    formData.append("message", data.message);
    formData.append("ip", data.ip);
    attachfiles.map(file => {
        formData.append("attachments", file);
    })

    const ticket = axios.post(api, formData, { headers: { "Authorization": `Bearer ${token}` } } )
        .then(res => {
            return { ...res.data, ...{error: false}}
        })
        .catch((error) => {
            //console.log(error, error.response);
            return {error: true, message: error.response}
        });

    return ticket;

}

export const updateStatusTicket = (data) => {
    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/tickets/status`;

    const ticket = axios.put(api, data, { headers: { "Authorization": `Bearer ${token}` } } )
        .then(res => {
            return { ...res.data, ...{error: false}}
        })
        .catch((error) => {
            //console.log(error, error.response);
            return {error: true, message: error.response}
        });

    return ticket;
}

export const downloadFile = (ticket_id, ticket_c, filename) => {
    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/tickets/${ticket_id}/c/${ticket_c}/files/${filename}`;
    const newfilename = filename.substring(7); 

    const resp = axios.get(api, { headers: { "Authorization": `Bearer ${token}` } , responseType: 'arraybuffer'})
        .then(res => {
            //console.log('respuesta ', res)
            const downloadfile = new Blob([res.data]);

            const url = window.URL.createObjectURL(downloadfile);
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', newfilename);
            document.body.appendChild(link);
            link.click();

            return {error: false}
        })
        .catch(error => {
            return {error: true, message: "El archivo no puede descargarse"}
        })

    return resp;
}

//only test
/*export const uploadFiles = (attachfiles) => {
    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/tickets/upload-files`;

    const formData = new FormData();
    //formData.append("ticket", blobticket );
    formData.append("subject", "Asunto del mensaje");
    formData.append("deptid", 1);
    formData.append("urgency", 2);
    formData.append("message", "Hola cómo estás ?");
    formData.append("ip", "200.58.82.47");
    attachfiles.map(file => {
        const filename = file.name.replace(/ /g,"_");
        console.log("nombre de archivo", filename);
        formData.append("attachments", file, filename);
    })

    console.log("data files", formData);

    const ticket = axios.post(api, formData, { headers: { "Authorization": `Bearer ${token}`, "content-type": "multipart/form-data" } } )
        .then(res => {
            return { ...res.data, ...{error: false}}
        })
        .catch((error) => {
            //console.log(error, error.response);
            return {error: true, message: error.response}
        });

    return ticket;
}*/

