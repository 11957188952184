import axios from 'axios';
import appConfig from '../config/main.config';
const apiserv = appConfig.url_api;

export const forgetPass = async (email, ip) => {
  const data = { email: email, ip: ip };

  const res = await axios.post(`${apiserv}/users/forget-password/`, data)
    .then(function (response) {
      //console.log(response)
      return response.data;
    })
    .catch(function (error) {
      return { res: 'continue' }
    });

  return res
};

export const verifyToken = async (token) => {
  const data = { token: token };

  const res = await axios.post(`${apiserv}/users/verify-resettoken/`, data)
    .then((resp) => { return resp.data })
    .catch((err) => { return { valid: false } })

  return res
}

export const resetPassword = async (token, pass, ip) => {
  const data = { token: token, password: pass, ip: ip };

  const res = await axios.post(`${apiserv}/users/password-reset/`, data)
    .then(function (response) {
      //console.log('respuesta reset', response)
      return response.data;
    })
    .catch(function (error) {
      /* console.log('error', error);
       console.log('error mensaje', error.message);
       console.log(error.response.data);
 console.log(error.response.status);
 console.log(error.response.headers); */
    });

  return res;

}