import React, { useState } from 'react';
import { Box, Button, Container, Grid, Paper, Typography, TableContainer, Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material';
import AccountIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import { getPublicIp } from '../../utils';
import { loginAccountClient } from '../../services/User';

function LoginAccountsCheckout(props) {
    const { accounts, uid, language, selectProfile } = props;

    const [errLoginClient, setErrLoginClient] = useState(null);
    const navigate = useNavigate();

    const handleClientSession = async (cid) => {
        const ip = await getPublicIp();
        const res = await loginAccountClient(uid, cid, language, ip);

        //console.log("clientsession", res);

        if (res.error === false) {
            selectProfile(res);
        } else {
            setErrLoginClient("Ha existido un error, por favor intente nuevamente");
        }
    }

    return (
        <Container maxWidth="sm" sx={{ mt: "3rem" }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                    <AccountIcon color="primary" sx={{ fontSize: "8rem" }} />
                </Grid>

                <Grid item xs={12} >
                    <Typography sx={{ fontSize: "1.33rem", mb: "2rem" }}>Elija la cuenta para completar su compra </Typography>

                    {/** table accounts */}

                    <Box mt={1} mb={3} >
                        <TableContainer component={Paper} variant='tbLight-nk'>
                            {errLoginClient &&
                                <Box>
                                    <Typography sx={{ color: 'red', paddingBottom: "1rem", fontSize: "1.2rem" }} >
                                        {errLoginClient}
                                    </Typography>
                                </Box>
                            }
                            <Table size="small" aria-label="invoices table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><strong>Cuentas</strong></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {accounts.map((account) => (
                                        <TableRow hover key={account.cid}>
                                            <TableCell>{account.fullname} ({account.companyname})</TableCell>
                                            <TableCell><Button variant="outlined" onClick={() => handleClientSession(account.cid)}>Continuar</Button> </TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Box>

                </Grid>

            </Grid>
        </Container>
    );
}

export default LoginAccountsCheckout;