import React, { useState } from 'react'
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Container, Link, TextField, Typography } from '@material-ui/core';
import { getPublicIp } from '../../utils/index';
import { resetPassword } from '../../services/ForgetPass';

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  let token = null;

  if (location.state !== null) {
    if (location.state.token !== undefined) {
      token = location.state.token;
      //console.log('token', token)
    }
  }

  const [errorReset, setErrorReset] = useState(null);

  const handleLogin = () => {
    navigate('/login/');
  }


  return (
    <>
      <Helmet>
        <title>Portal - Área de clientes de Neothek - Restablecer contraseña</title>
      </Helmet>
      <Box
        sx={{
          /*backgroundColor: 'background.default',*/
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >


        {(token !== null && errorReset === null) &&

          <Container maxWidth="sm" variant='cont-nk'>
            <Formik
              initialValues={{
                password: '',
                password2: ''
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string().min(8, 'Usa 8 caracteres como mínimo').max(255).required('Contraseña es requerida'),
                password2: Yup.string().oneOf([Yup.ref('password'), null], 'La contraseña escrita no coincide').required('Confirmación de contraseña es requerida'),
              })}

              onSubmit={async (val, actions) => {
                //console.log('valuest', val);
                //actions.resetForm();

                const ip = await getPublicIp();
                const reset = await resetPassword(token, val.password, ip);

                //console.log('respuesta reset', reset);

                if (reset) {
                  //console.log('no hay errror')
                  setErrorReset(false);
                } else {
                  //console.log('error')
                  setErrorReset(true);
                }


              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box sx={{ mb: 2, mt: 3 }}>
                    <Typography
                      color="textPrimary"
                      variant="h2"
                      align="center"
                    >
                      Ingresa tu nueva contraseña
                    </Typography>

                  </Box>

                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password || 'Utiliza 8 caracteres como mínimo: letras, números y símbolos'}
                    label="Contraseña nueva"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />



                  <TextField
                    error={Boolean(touched.password2 && errors.password2)}
                    fullWidth
                    helperText={touched.password2 && errors.password2}
                    label="Confirma tu contraseña nueva"
                    margin="normal"
                    name="password2"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password2}
                    variant="outlined"
                  />


                  <Box sx={{ py: 3 }}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Continuar
                    </Button>
                  </Box>

                </form>
              )}
            </Formik>
          </Container>

        }

        {(token === null && errorReset === null) &&
          <Container maxWidth="sm" variant='cont-nk'>

            <Box sx={{ mb: 2, mt: 3 }}>
              <Typography
                color="textPrimary"
                variant="h2"
                align="center"
              >
                Lo sentimos,
              </Typography>

            </Box>

            <Box sx={{ pt: 1, pb: 2 }}>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body1"
                align="center"
              >
                No se ha logrado procesar su solicitud.
              </Typography>

              <Typography
                color="textSecondary"
                variant="body1"
                align='center'
              >
                <Link
                  component={RouterLink}
                  to="/reset/"
                  variant="h6"
                >
                  Volver a intentarlo.
                </Link>
              </Typography>
            </Box>

          </Container>

        }

        {(token !== null && errorReset === true) &&
          <Container maxWidth="sm" variant='cont-nk'>

            <Box sx={{ mb: 2, mt: 3 }}>
              <Typography
                color="textPrimary"
                variant="h2"
                align="center"
              >
                Lo sentimos,
              </Typography>

            </Box>

            <Box sx={{ pt: 1, pb: 2 }}>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body1"
                align="center"
              >
                No se ha logrado procesar su solicitud, es posible que el token se encuentre expirado.
              </Typography>

              <Typography
                color="textSecondary"
                variant="body1"
                align='center'
              >
                <Link
                  component={RouterLink}
                  to="/reset/"
                  variant="h6"
                >
                  Volver a intentarlo.
                </Link>
              </Typography>
            </Box>

          </Container>

        }

        {(token !== null && errorReset === false) &&
          <Container maxWidth="sm" variant='cont-nk'>

            <Box sx={{ mb: 2, mt: 3 }}>
              <Typography
                color="textPrimary"
                variant="h2"
                align="center"
              >
                Contraseña actualizada
              </Typography>

            </Box>

            <Box sx={{ pt: 1, pb: 2 }}>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body1"
                align="center"
              >
                Su contraseña ha sido cambiada exitosamente. Puede ingresar con los nuevos accesos.
              </Typography>

            </Box>

            <Box sx={{ paddingBottom: 3 }}>

              <Button variant='contained' fullWidth onClick={handleLogin}>
                Ir a Login
              </Button>

            </Box>

          </Container>
        }


      </Box>
    </>
  );
};

export default ResetPassword;
