import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Checkbox, Divider, FormControl, FormControlLabel, Grid, Link, Radio, RadioGroup, TextField, Typography, FormHelperText, makeStyles } from '@material-ui/core';
import { Formik } from 'formik';
import StepperCart from '../../components/FormsUI/StepperCart';
import { getDataClient } from '../../services/Client';
import SpinnerLoad from '../../components/FormsUI/Spinner';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPublicIp } from '../../utils';
import { createDomainRecord } from '../../services/Invoice';
import { logout } from '../../hooks/useAuth';

const useStyles = makeStyles((theme) => ({
    [theme.breakpoints.down('sm')]: {
      radioPay: {
        height: "75px",
        width: "100%",
      }
    },
}));

const DomainCheckoutClient = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const classes = useStyles();
    const [client, setClient] = useState(null);
    const [payment, setPayment] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState(null);

    const [withProduct, setWithProduct] = useState(null);

    const [order, setOrder] = useState(null);

    const handlePayment = (ev) => {
        setPayment(ev.target.value);
    }

    useEffect(() => {
        const getDataUser = async () => {
            const client = await getDataClient();
            //console.log('data user', client);

            if (client.error === false) {
                setClient(client);
                setPaymentMethods(client.payments);
                setPayment(client.pgdefault.gateway);
                setOrder(location.state.data);
                setWithProduct(true);
            }
        }

        //console.log('location state', location.state);

        if (location.state && location.state.data) {
            getDataUser();
        } else {
            //navegar a store
            //navigate('/store/');
            setWithProduct(false);
        }


    }, [])

    return (
        <>
            <Container maxWidth="md">
                <Grid item xs={12} md={12}>
                    <StepperCart step={2} />
                </Grid>
            </Container>
            <Container maxWidth="md" variant='cont-nk'>
                <Grid container>

                    {withProduct === null ?
                        <SpinnerLoad />
                        :

                        <>

                            {withProduct === false &&
                                <Box sx={{ py: 1 }}>
                                    <Typography>Por favor seleccione un producto para iniciar el proceso de compra</Typography>
                                </Box>
                            }

                            {withProduct === true &&
                                <Box sx={{ py: 1 }}>
                                    <Grid item xs={12} md={12}>
                                        {client === null ?
                                            <SpinnerLoad />
                                            :
                                            <>
                                                <Grid container spacing={1}>

                                                    <Grid item xs={12}>
                                                        <Box mt={2} mb={3} >
                                                            <Typography variant='h4'>Datos de cliente</Typography>
                                                            <Divider />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            label="Nombre"
                                                            margin="normal"
                                                            value={client.firstname}
                                                            variant="outlined"
                                                            size="small"
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            label="Apellido"
                                                            margin="normal"
                                                            value={client.lastname}
                                                            variant="outlined"
                                                            size="small"
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            label="Dirección E-mail"
                                                            margin="normal"
                                                            value={client.email}
                                                            variant="outlined"
                                                            size="small"
                                                            disabled
                                                        />

                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            label="Compañía (Opcional)"
                                                            margin="normal"
                                                            value={client.companyname}
                                                            variant="outlined"
                                                            size="small"
                                                            disabled
                                                        />
                                                    </Grid>


                                                </Grid>

                                                {/** medios de pago */}
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Box mt={5} mb={2} >
                                                            <Typography variant='h4'>Medios de Pago</Typography>
                                                            <Divider />

                                                            <FormControl component="fieldset" sx={{display: "inherit"}}>
                                                                <RadioGroup aria-label="payment" value={payment} onChange={handlePayment} row >
                                                                    {paymentMethods.map(({ id, gateway, value }) => (
                                                                        <React.Fragment key={id} >
                                                                            {gateway === "tco" && <FormControlLabel value={gateway} control={<Radio color="primary" />} label={value} variant="labelPay-nk" className={classes.radioPay} sx={{ backgroundImage: "url(/static/images/payments/cards_2co.webp)", backgroundSize: "47.2%" }} />}
                                                                            {gateway === "stripe" && <FormControlLabel value={gateway} control={<Radio color="primary" />} label={value} variant="labelPay-nk" className={classes.radioPay} sx={{ backgroundImage: "url(/static/images/payments/cards_stripe.webp)", backgroundSize: "48%" }} />}
                                                                            {gateway === "paypal" && <FormControlLabel value={gateway} control={<Radio color="primary" />} label={value} variant="labelPay-nk" className={classes.radioPay} sx={{ backgroundImage: "url(/static/images/payments/paypal.webp)", backgroundSize: "22%", backgroundPosition: "16% 80%" }} />}
                                                                            {gateway === "payu" && <FormControlLabel value={gateway} control={<Radio color="primary" />} label={value} variant="labelPay-nk" className={classes.radioPay} sx={{ backgroundImage: "url(/static/images/payments/payu.webp)", backgroundSize: "58%", backgroundPosition: "30% 80%" }} />}
                                                                            {gateway === "banktransfer" && <FormControlLabel value={gateway} control={<Radio color="primary" />} label={value} variant="labelPay-nk" className={classes.radioPay} sx={{ backgroundImage: "url(/static/images/payments/bancos_bol.webp)", backgroundSize: "39%" }} />}
                                                                            {gateway === "mailin" && <FormControlLabel value={gateway} control={<Radio color="primary" />} label={value} variant="labelPay-nk" className={classes.radioPay} sx={{ backgroundImage: "url(/static/images/payments/wu_2.webp)", backgroundSize: "23%", backgroundPosition: "15% 80%" }} />}
                                                                        </React.Fragment>
                                                                    ))}
                                                                </RadioGroup>
                                                            </FormControl>

                                                        </Box>
                                                    </Grid>

                                                </Grid>

                                                {/** sumario de pedidos */}

                                                <Grid container sx={{ mb: 4 }}>
                                                    <Grid item xs={12}>
                                                        <Box mt={2} mb={2} >
                                                            <Typography variant='h4'>Sumario de Pedido</Typography>

                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        < Container sx={{ backgroundColor: "#F6F9FC", borderRadius: "5px", p: 1, paddingTop: "4%" }}>
                                                            <Grid container spacing={2} sx={{ mb: 2 }}>

                                                                <Grid item sm={8} xs={9}>
                                                                    {order.eventdomain === 'domainregister' ?
                                                                        <Typography color="colornk.bluedark" variant="txtSummary-nk">Registro de Dominio - {order.domain} / {order.qtyanios} año(s)</Typography>
                                                                        :
                                                                        <Typography color="colornk.bluedark" variant="txtSummary-nk">Transferencia de Dominio - {order.domain} / {order.qtyanios} año(s)</Typography>
                                                                    }

                                                                </Grid>
                                                                <Grid item sm={4} xs={3} style={{ paddingTop: '13px', textAlign: 'right' }}>
                                                                    <Typography color="colornk.bluedark" variant="txtSummary-nk">US$ {order.pricedomain}</Typography>
                                                                </Grid>
                                                                <Grid item sm={12} style={{ paddingTop: 0 }}>
                                                                    <Typography color="colornk.grey3" style={{ fontSize: '1.18rem' }}>Renovación $ {order.pricerenewdomain} / {order.qtyanios} año</Typography>
                                                                </Grid>


                                                                <Grid item sm={8} xs={9} style={{ paddingTop: '13px' }}>
                                                                    <Typography color="colornk.bluedark" variant="txtSummary-nk">Complementos</Typography>
                                                                </Grid>
                                                                <Grid item sm={4} xs={3} style={{ paddingTop: '13px', textAlign: 'right' }}>
                                                                    <Typography color="colornk.bluedark" variant="txtSummary-nk">US$ {order.subtotaladd.toFixed(2)}</Typography>
                                                                </Grid>

                                                                <Grid item xs={12} sx={{ borderTop: '1px solid #e3e8ee' }} style={{ marginTop: '10px', paddingTop: '0px' }}></Grid>

                                                                <Grid item sm={8} xs={8} style={{ paddingTop: '13px' }}>
                                                                    <Typography color="colornk.bluedark" variant="txtSummary-nk"><strong>Subotal</strong></Typography>
                                                                </Grid>
                                                                <Grid item sm={4} xs={4} style={{ paddingTop: '13px', textAlign: 'right' }}>
                                                                    <Typography color="colornk.bluedark" variant="txtSummary-nk"><strong>US$ {order.subtotal}</strong></Typography>
                                                                </Grid>


                                                                <Grid item sm={8} xs={8} style={{ paddingTop: '13px' }}>
                                                                    <Typography color="colornk.bluedark" variant="txtSummary-nk"><strong>Total</strong></Typography>
                                                                </Grid>
                                                                <Grid item sm={4} xs={4} style={{ paddingTop: '13px', textAlign: 'right' }}>
                                                                    <Typography color="colornk.bluedark" variant="txtSummary-nk"><strong>US$ {order.total}</strong></Typography>
                                                                </Grid>

                                                            </Grid>

                                                        </Container>

                                                    </Grid>

                                                </Grid>


                                                <Formik
                                                    initialValues={{
                                                        policy: false
                                                    }}
                                                    validationSchema={
                                                        Yup.object().shape({
                                                            policy: Yup.boolean().oneOf([true], 'Este campo debe estar marcado')
                                                        })
                                                    }
                                                    onSubmit={async (val, actions) => {

                                                        //console.log('values', order);

                                                        const ip = await getPublicIp();
                                                        const pricedomaindec = parseFloat(order.pricedomain).toFixed(2);
                                                        const pricerenewdomaindec = parseFloat(order.pricerenewdomain).toFixed(2);

                                                        
                                                        order['pricedomain'] = pricedomaindec;
                                                        order['pricerenewdomain'] = pricerenewdomaindec;
                                                        order['paymentmethod'] = payment;
                                                        order['ipaddress'] = ip;
                                                        order['country'] = client.country;
                                                        order['status'] = "buyinprogress";

                                                        //console.log('new order', order);

                                                        const data = { order: order };

                                                        //console.log('data order', data);

                                                        const invoice = await createDomainRecord(data);

                                                        if (invoice.error === false) {
                                                            navigate(`/clientarea/invoices/${invoice.id}/`, { replace: true });
                                                        } else {
                                                            //mandar un mensaje de error para que inicie nuevamente la compra
                                                            if (invoice.error === true) {
                                                                if (invoice.message.status === 401) {
                                                                    logout();
                                                                    navigate('/', { replace: true });
                                                                }
                                                            }
                                                        }

                                                    }}
                                                >
                                                    {({
                                                        errors,
                                                        handleChange,
                                                        handleSubmit,
                                                        isSubmitting,
                                                        touched,
                                                        values
                                                    }) => (

                                                        <form onSubmit={handleSubmit}>

                                                            <Box sx={{ alignItems: 'center', display: 'flex', ml: -1 }} >
                                                                <Checkbox
                                                                    checked={values.policy}
                                                                    name="policy"
                                                                    onChange={handleChange}
                                                                />
                                                                <Typography color="textSecondary" variant="body2">
                                                                    Estoy de acuerdo con los
                                                                    {' '}
                                                                    <Link
                                                                        color="primary"
                                                                        component="a"
                                                                        href="http://www.neothek.com/legal/"
                                                                        underline="always"
                                                                        variant="body2"
                                                                        target="_blank"
                                                                        rel="noopener"
                                                                    >
                                                                        Términos y condiciones de Uso
                                                                    </Link>
                                                                </Typography>
                                                            </Box>
                                                            {Boolean(touched.policy && errors.policy) && (
                                                                <FormHelperText error>
                                                                    {errors.policy}
                                                                </FormHelperText>
                                                            )}
                                                            <Box sx={{ py: 3 }}>
                                                                <Button
                                                                    color="success"
                                                                    disabled={isSubmitting}
                                                                    fullWidth
                                                                    size="large"
                                                                    type="submit"
                                                                    variant="contained"
                                                                >
                                                                    Completar Pedido
                                                                </Button>
                                                            </Box>

                                                        </form>


                                                    )}
                                                </Formik>
                                            </>

                                        }

                                    </Grid>
                                </Box>
                            }


                        </>


                    }








                </Grid>
            </Container>
        </>
    );
}

export default DomainCheckoutClient;