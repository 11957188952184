import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import theme from './theme'
import routes from './routes';

function App() {
  const isAuth = localStorage.getItem('_isAuthenticated_');
  //const isAuth = localStorage.getItem('_cli_storage_').isAuth;
  const routing = useRoutes(routes(isAuth));
  return (
      <>
        <ThemeProvider theme={theme}>
          {routing}
        </ThemeProvider>

      </>
    
  );
}

export default App;
