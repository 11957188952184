export function saveAuth(res) {
    //console.log()
    //const user = {token: res.token, uid:res.uid, cid:res.cid, language:res.language};
    const user = {token: res.token, language:res.language};
    localStorage.setItem('_cli_storage_', JSON.stringify(user)); 
    localStorage.setItem('_isAuthenticated_', true);
}
export function logout() {
    localStorage.removeItem('_cli_storage_');
    localStorage.removeItem('_isAuthenticated_');
    //localStorage.clear();
}