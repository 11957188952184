import React, { useEffect, useState } from 'react';
import { Box, Container, Link, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { getAnnouncements } from '../../services/Announcement';
import TableAnnouncements from './TableAnnouncements';
import SpinnerLoad from '../../components/FormsUI/Spinner';
import { NavLink as RouterLink } from 'react-router-dom';

const Announcements = (props) => {
    const [announcements, setAnnouncements] = useState(null);

    //to pagination
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(15);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };
    const handleLimitChange = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    useEffect(() => {
        const getAdds = async () => {
            const ads = await getAnnouncements();

            if (ads.error === false) {
                setAnnouncements(ads.announcements);
            } else {
                setAnnouncements([]);
            }
        }


        getAdds();
    }, [])

    return (
        <>
            <Helmet>
                <title>Portal Neothek - Área de clientes de Neothek</title>
            </Helmet>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center'
                }}
            >
                {announcements ?
                    <>
                        {(announcements.length !== 0) ?
                            <>
                                <Container maxWidth="lg" sx={{ pb: '5px', mt: 5 }}>
                                    <Link component={RouterLink} to={`/`} >{'Portal'}</Link> <span style={{ color: '#5f81ff' }}>{'  >  '}</span>
                                    <Link sx={{ textDecoration: 'none', color: '#23527c' }}>Anuncios</Link>
                                </Container>
                                <Container maxWidth="lg" variant='cont-nk'>
                                    <Box sx={{ mb: 2, mt: 5 }}>
                                        <Typography variant='h1' color="textPrimary" sx={{ textAlign: "center", mb: 5 }}>Anuncios</Typography>

                                        <TableAnnouncements announcements={announcements} page={page} limit={limit} handlePageChange={handlePageChange} handleLimitChange={handleLimitChange} />

                                    </Box>

                                </Container>
                            </>


                            :
                            <Container maxWidth="sm" variant='cont-nk'>
                                <Box sx={{ mb: 2, mt: 3 }}>
                                    <Typography
                                        color="textPrimary"
                                        variant="h2"
                                        align="center"
                                    >
                                        Lo sentimos,
                                    </Typography>

                                </Box>

                                <Box sx={{ pt: 1, pb: 2 }}>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="body1"
                                        align="center"
                                    >
                                        No existe anuncios disponibles.
                                    </Typography>
                                </Box>
                            </Container>
                        }
                    </>
                    :
                    <SpinnerLoad />
                }


            </Box>

        </>
    );
}

export default Announcements;