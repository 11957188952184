import { Outlet } from 'react-router-dom';
import { experimentalStyled, Box, Container } from '@material-ui/core';
import MainNavbar from './MainNavbar';
import NeoNavbar from './NeoNavbar';

const MainLayoutRoot = experimentalStyled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    /*display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'*/
  })
);

const LayoutNavbar = experimentalStyled('div')(
  ({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      top:53
    }
  })
);

/*const MainLayoutWrapper = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 64
});

const MainLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const MainLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  //height: '100%',
  display:'flex',
  //overflow: 'auto'
});*/

const ContentLayout = experimentalStyled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    //backgroundColor: theme.palette.secondary.main,
    marginTop: '34%'
  },
  [theme.breakpoints.up('sm')]: {
    //backgroundColor: theme.palette.secondary.main,
    marginTop: '21%'
  },
  [theme.breakpoints.up('md')]: {
    //backgroundColor: theme.palette.primary.main, 
    marginTop: '15%'
  },
  [theme.breakpoints.up('lg')]: {
    //backgroundColor: green[500],
    marginTop: '8%'
  },
}));

const MainLayout = () => (
  <MainLayoutRoot>

    <LayoutNavbar>
      <MainNavbar />
    </LayoutNavbar>
    

    <ContentLayout>
      <Box>
        <Container>
          <Outlet />
        </Container>
      </Box>
    </ContentLayout>


    <NeoNavbar />


    {/*<MainLayoutWrapper>
      <MainLayoutContainer>
        <MainLayoutContent>
          <Outlet />
        </MainLayoutContent>
      </MainLayoutContainer>
    </MainLayoutWrapper>*/}
  </MainLayoutRoot>
);

export default MainLayout;
