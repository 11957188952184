import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';

const ButtonIni = styled(Button)(({ theme }) => ({
    backgroundColor: '#A6C307',
    '&:hover': {
        backgroundColor: '#97b108',
        color: '#ffffff'
    },
}));

const NotFound = () => {
    return (
        <>
            <Helmet>
                <title>Error 404 | Página no encontrada</title>
            </Helmet>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center'
                }}
            >

                <Container maxWidth="md">
                    <Grid item xs={12} align='center'>
                        <Typography variant='h4'>Lo sentimos,</Typography>
                        <Typography variant='body1' color="textSecondary">pero la página que buscas no puede ser encontrada</Typography>

                        <Box sx={{ py: 2 }}>
                            <ButtonIni variant="contained" href="/">Volver al Inicio</ButtonIni>
                        </Box>

                        <img
                            alt="Error 404"
                            src="/static/images/404.png"
                        />
                    </Grid>
                </Container>
            </Box>
        </>

    )
}
export default NotFound