import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Chip, Link, Grid, Typography, Container, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, FormControl, InputLabel, MenuItem, Select, OutlinedInput } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import PayPalButton from '../components/payments/PayPalButton';
import CheckoutButton from '../components/payments/2CheckoutButton';
import StripeButton from '../components/payments/StripeButton';
import PayuButton from '../components/payments/PayuButton';
import { getInvoiceClient, updatePaymentMethod } from '../services/Invoice';
import downloadInvoicePdf from '../services/InvoicePdf';
import DownloadIcon from '@material-ui/icons/Download';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import SpinnerLoad from '../components/FormsUI/Spinner';
import LoadingButton from '@mui/lab/LoadingButton';
import { logout } from '../hooks/useAuth'
import { useNavigate } from 'react-router-dom';
import StripeButtonPayElement from '../components/payments/StripeButtonPayElement';
import TooltipInvoiceDetail from '../components/Invoices/TooltipInvoiceDetail';

const Invoice = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [payment, setPayment] = useState(null);
    const [invoice, setInvoice] = useState(null);

    const [errorInvoice, setErrorInvoice] = useState(false);

    const [loading, setLoading] = useState(false);


    const handleChangePayment = async (ev) => {
        //console.log('valor payment', ev.target.value)

        const values = {
            invoiceid: invoice.invoiceid,
            paymentmethod: ev.target.value
        }

        const pgupdated = await updatePaymentMethod(values);

        setPayment(ev.target.value)

    }

    const handleInvoicePdf = async () => {
        setLoading(true);
        const id = invoice.invoiceid;
        const res = await downloadInvoicePdf(id);

        if (res.error === false) {
            setLoading(false);
        }
    }

    useEffect(() => {

        const getInvoice = async () => {
            const data = await getInvoiceClient(id);

            if (data.error === true) {
                if (data.message.status === 401) {
                    logout();
                    navigate('/', { replace: true });
                }
                setErrorInvoice(true);
            }
            if (data.error === false) {
                setInvoice(data);
                setPayment(data.invoicepayment);
            }
        }

        getInvoice();

    }, [])


    if (invoice === null || payment === null) {
        return (
            <>
                {errorInvoice ?
                    <Container maxWidth="sm" variant='cont-nk'>

                        <Box sx={{ mb: 2, mt: 3 }}>
                            <Typography
                                color="textPrimary"
                                variant="h2"
                                align="center"
                            >
                                Lo sentimos,
                            </Typography>

                        </Box>

                        <Box sx={{ pt: 1, pb: 2 }}>
                            <Typography
                                color="textSecondary"
                                gutterBottom
                                variant="body1"
                                align="center"
                            >
                                Ha existido una falla o la nota de débito no corresponde a su cuenta.
                            </Typography>

                        </Box>

                    </Container>

                    :
                    <SpinnerLoad />
                }
            </>
        )
    } else {
        return (
            <>
                <Helmet>
                    <title>Portal - Área de clientes de Neothek - {`Invoice #${invoice.invoiceid}`}</title>
                </Helmet>
                <Box>
                    <Container maxWidth="lg" variant='cont-nk' sx={{ pb: 4, pt: 2 }}>
                        <Grid container spacing={2}>
                            {invoice.invoicestatus === 'Unpaid' ?
                                <Grid item xs={12} sm={12} md={8} >
                                    <Grid container sx={{ backgroundColor: '#f8f8f8' }} mt={3} mb={3} pb={2}>

                                        <Grid item xs={8}>
                                            <Box sx={{ paddingTop: '16px', paddingLeft: '10px' }}>
                                                <Typography variant='h4' sx={{ display: 'inline', pr: 1 }}>Nota #{invoice.invoiceid}</Typography>
                                                {invoice.invoicestatus === 'Unpaid' &&
                                                    <Chip color="secondary" size="small" label={invoice.invoicestatus} sx={{ borderRadius: 1, marginTop: '-4px', height: '20px', backgroundColor: '#E3E8EE', color: '#53566B' }} />
                                                }
                                                {invoice.invoicestatus === 'Paid' &&
                                                    <Chip size="small" label={invoice.invoicestatus} sx={{ borderRadius: 1, marginTop: '-4px', height: '20px', backgroundColor: '#cbf4c9', color: '#268717' }} />
                                                }
                                                {invoice.invoicestatus === 'Cancelled' &&
                                                    <Chip size="small" label={invoice.invoicestatus} sx={{ borderRadius: 1, marginTop: '-4px', height: '20px', backgroundColor: '#F8DADB', color: '#B72136' }} />
                                                }
                                                {invoice.invoicestatus === 'Refunded' &&
                                                    <Chip size="small" label={invoice.invoicestatus} sx={{ borderRadius: 1, marginTop: '-4px', height: '20px', backgroundColor: '#F8DADB', color: '#B72136' }} />
                                                }
                                            </Box>

                                        </Grid>
                                        <Grid item xs={4} >
                                            {/* <Button variant="outlined" onClick={handleInvoicePdf} startIcon={<DownloadIcon sx={{ mr: 0, ml: 0 }} />} endIcon={<PdfIcon sx={{ ml: 0, mr: 2, color: '#686868' }} />} sx={{ mt: 1.7, mr: 2, padding: '4px 5px' }} ></Button> */}
                                            <Grid container direction="row" justifyContent="flex-end">
                                                <LoadingButton
                                                    onClick={handleInvoicePdf}
                                                    loading={loading}
                                                    variant="outlined"
                                                    endIcon={<DownloadIcon sx={{ mr: 1, ml: 0 }} />}
                                                    loadingPosition="end"
                                                    sx={{ mt: 1.7, mr: 2, padding: '4px 5px' }}
                                                >
                                                    <PdfIcon sx={{ ml: 1, mr: 1, color: '#686868', fontSize: '2rem' }} />

                                                </LoadingButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Box>
                                        <Grid container spacing={5}>
                                            <Grid item xs={6}>
                                                <Typography variant='subtitule1' color='primary'>Pagar a:</Typography>
                                                <Typography variant='body2' color="grey.500">{invoice.invoicepayto}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant='subtitule1' color='primary'>Emitido a:</Typography>
                                                <Typography variant='body2' color="grey.500">{invoice.client_companyname}</Typography>
                                                <Typography variant='body2' color="grey.500">{invoice.client_firstname} {invoice.client_lastname}</Typography>
                                                <Typography variant='body2' color="grey.500">{invoice.client_address1}</Typography>
                                                <Typography variant='body2' color="grey.500">{invoice.client_city} {invoice.client_state} {invoice.client_postcode}</Typography>
                                                <Typography variant='body2' color="grey.500">{invoice.client_country}</Typography>
                                            </Grid>
                                        </Grid>

                                    </Box>
                                    <Box mt={5} mb={3} >
                                        <Typography variant='h5' sx={{ mb: 2 }} color='primary'>Productos/Servicios</Typography>

                                        <TableContainer component={Paper} variant="tbLight2-nk">
                                            <Table aria-label="simple table" size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell><strong>Descripción</strong></TableCell>
                                                        <TableCell align="right"><strong>Importe</strong></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    
                                                    {invoice.hostings.map((host) => (
                                                        <TableRow hover key={host.id}>
                                                            <TableCell component="th" scope="row">
                                                                {
                                                                    host.description.split("\n").map((item, idx) => (
                                                                        <span key={idx}>
                                                                            {item}
                                                                            <br />
                                                                        </span>

                                                                    ))
                                                                }
                                                                
                                                            </TableCell>
                                                            <TableCell align="right">${host.amount}USD</TableCell>
                                                        </TableRow>
                                                    ))}

                                                    {invoice.promos.map((promo) => (
                                                        <TableRow hover key={promo.id}>
                                                            <TableCell component="th" scope="row">
                                                                {promo.description}
                                                            </TableCell>
                                                            <TableCell align="right">${promo.amount}USD</TableCell>
                                                        </TableRow>
                                                    ))}


                                                    {invoice.addons.map((addon) => (
                                                        <TableRow hover key={addon.id}>
                                                            <TableCell component="th" scope="row">
                                                                {addon.description}
                                                            </TableCell>
                                                            <TableCell align="right">${addon.amount}USD</TableCell>
                                                        </TableRow>
                                                    ))}

                                                    {invoice.domains.map((domain) => (
                                                        <TableRow hover key={domain.id}>
                                                            <TableCell component="th" scope="row">
                                                                {
                                                                    domain.description.split("\n").map((item, idx) => (
                                                                        <span key={idx}>
                                                                            {item}
                                                                            <br />
                                                                        </span>
                                                                    ))
                                                                }
                                                            </TableCell>
                                                            <TableCell align="right">${domain.amount}USD</TableCell>
                                                        </TableRow>
                                                    ))}

                                                    {invoice.customitems.map((item) => (
                                                        <TableRow hover key={item.id}>
                                                            <TableCell component="th" scope="row">
                                                                {item.description}
                                                            </TableCell>
                                                            <TableCell align="right">${item.amount}USD</TableCell>
                                                        </TableRow>
                                                    ))}

                                                    {invoice.variousitems.map((item) => (
                                                        <TableRow hover key={item.id}>
                                                            <TableCell component="th" scope="row">
                                                                {item.type === "Invoice" ?
                                                                    <>{item.description} <TooltipInvoiceDetail idinvoice={item.relid} /> </>
                                                                    :
                                                                    <>{item.description}</>
                                                                }
                                                            </TableCell>
                                                            <TableCell align="right">${item.amount}USD</TableCell>
                                                        </TableRow>
                                                    ))}


                                                    <TableRow>
                                                        <TableCell> <strong>Total</strong></TableCell>
                                                        <TableCell align="right">${invoice.total}USD</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                    </Box>

                                    {/* transactions*/}

                                    <Box mt={5} mb={3}>
                                        <Typography variant='h5' sx={{ mb: 2 }} color='primary'>Transacciones</Typography>
                                        <TableContainer component={Paper} variant="tbLight2-nk">
                                            <Table aria-label="simple table" size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell><strong>Fecha</strong></TableCell>
                                                        <TableCell><strong>Método/Gateway</strong></TableCell>
                                                        <TableCell><strong>ID Transacción</strong></TableCell>
                                                        <TableCell align="right"><strong>Total</strong></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {invoice.transactions.length !== 0 &&
                                                        <>
                                                            {invoice.transactions.map((trans) => (
                                                                <TableRow hover key={trans.id}>
                                                                    <TableCell component="th" scope="row">{trans.date}</TableCell>
                                                                    <TableCell>{trans.gateway}</TableCell>
                                                                    <TableCell>{trans.transid}</TableCell>

                                                                    {(trans.amountin !== '0.00' && trans.amountout === '0.00') && <TableCell align="right">${trans.amountin}USD</TableCell>}
                                                                    {(trans.amountout !== '0.00' && trans.amountin === '0.00') && <TableCell align="right">$-{trans.amountout}USD</TableCell>}

                                                                </TableRow>
                                                            ))}
                                                        </>
                                                    }
                                                    {invoice.transactions.length === 0 &&
                                                        <TableRow hover>
                                                            <TableCell colSpan={4} align="center">No se encontraron transacciones relacionadas</TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>

                                </Grid>
                                :
                                <Grid item xs={12} sm={12} md={12} >
                                    <Grid container sx={{ backgroundColor: '#f8f8f8' }} mt={3} mb={3} pb={2}>

                                        <Grid item xs={8}>
                                            <Box sx={{ paddingTop: '16px', paddingLeft: '10px' }}>
                                                <Typography variant='h4' sx={{ display: 'inline', pr: 1 }}>Nota #{invoice.invoiceid}</Typography>
                                                {invoice.invoicestatus === 'Unpaid' &&
                                                    <Chip color="secondary" size="small" label={invoice.invoicestatus} sx={{ borderRadius: 1, marginTop: '-4px', height: '20px', backgroundColor: '#E3E8EE', color: '#53566B' }} />
                                                }
                                                {invoice.invoicestatus === 'Paid' &&
                                                    <Chip size="small" label={invoice.invoicestatus} sx={{ borderRadius: 1, marginTop: '-4px', height: '20px', backgroundColor: '#cbf4c9', color: '#268717' }} />
                                                }
                                                {invoice.invoicestatus === 'Cancelled' &&
                                                    <Chip size="small" label={invoice.invoicestatus} sx={{ borderRadius: 1, marginTop: '-4px', height: '20px', backgroundColor: '#F8DADB', color: '#B72136' }} />
                                                }
                                                {invoice.invoicestatus === 'Refunded' &&
                                                    <Chip size="small" label={invoice.invoicestatus} sx={{ borderRadius: 1, marginTop: '-4px', height: '20px', backgroundColor: '#F8DADB', color: '#B72136' }} />
                                                }
                                            </Box>

                                        </Grid>
                                        <Grid item xs={4} >
                                            <Grid container direction="row" justifyContent="flex-end">
                                                <LoadingButton
                                                    onClick={handleInvoicePdf}
                                                    loading={loading}
                                                    variant="outlined"
                                                    endIcon={<DownloadIcon sx={{ mr: 1, ml: 0 }} />}
                                                    loadingPosition="end"
                                                    sx={{ mt: 1.7, mr: 2, padding: '4px 5px' }}
                                                >
                                                    <PdfIcon sx={{ ml: 1, mr: 1, color: '#686868', fontSize: '2rem' }} />

                                                </LoadingButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Box>
                                        <Grid container spacing={5}>
                                            <Grid item xs={6}>
                                                <Typography variant='subtitule1' color='primary'>Pagar a:</Typography>
                                                <Typography variant='body2' color="grey.500">{invoice.invoicepayto}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant='subtitule1' color='primary'>Emitido a:</Typography>
                                                <Typography variant='body2' color="grey.500">{invoice.client_companyname}</Typography>
                                                <Typography variant='body2' color="grey.500">{invoice.client_firstname} {invoice.client_lastname}</Typography>
                                                <Typography variant='body2' color="grey.500">{invoice.client_address1}</Typography>
                                                <Typography variant='body2' color="grey.500">{invoice.client_city} {invoice.client_state} {invoice.client_postcode}</Typography>
                                                <Typography variant='body2' color="grey.500">{invoice.client_country}</Typography>
                                            </Grid>
                                        </Grid>

                                    </Box>
                                    <Box mt={5} mb={3} >
                                        <Typography variant='h5' sx={{ mb: 2 }} color='primary'>Productos/Servicios</Typography>

                                        <TableContainer component={Paper} variant="tbLight2-nk">
                                            <Table aria-label="simple table" size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell><strong>Descripción</strong></TableCell>
                                                        <TableCell align="right"><strong>Importe</strong></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    {invoice.hostings.map((host) => (
                                                        <TableRow hover key={host.id}>
                                                            <TableCell component="th" scope="row">
                                                                {
                                                                    host.description.split("\n").map((item, idx) => (
                                                                        <span key={idx}>
                                                                            {item}
                                                                            <br />
                                                                        </span>

                                                                    ))
                                                                }
                                                                
                                                            </TableCell>
                                                            <TableCell align="right">${host.amount}USD</TableCell>
                                                        </TableRow>
                                                    ))}

                                                    {invoice.promos.map((promo) => (
                                                        <TableRow hover key={promo.id}>
                                                            <TableCell component="th" scope="row">
                                                                {promo.description}
                                                            </TableCell>
                                                            <TableCell align="right">${promo.amount}USD</TableCell>
                                                        </TableRow>
                                                    ))}

                                                    {invoice.addons.map((addon) => (
                                                        <TableRow hover key={addon.id}>
                                                            <TableCell component="th" scope="row">
                                                                {addon.description}
                                                            </TableCell>
                                                            <TableCell align="right">${addon.amount}USD</TableCell>
                                                        </TableRow>
                                                    ))}


                                                    {invoice.domains.map((domain) => (
                                                        <TableRow hover key={domain.id}>
                                                            <TableCell component="th" scope="row">
                                                                {
                                                                    domain.description.split("\n").map((item, idx) => (
                                                                        <span key={idx}>
                                                                            {item}
                                                                            <br />
                                                                        </span>
                                                                    ))
                                                                }
                                                            </TableCell>
                                                            <TableCell align="right">${domain.amount}USD</TableCell>
                                                        </TableRow>
                                                    ))}

                                                    {invoice.customitems.map((item) => (
                                                        <TableRow hover key={item.id}>
                                                            <TableCell component="th" scope="row">
                                                                {item.description}
                                                            </TableCell>
                                                            <TableCell align="right">${item.amount}USD</TableCell>
                                                        </TableRow>
                                                    ))}

                                                    {invoice.variousitems.map((item) => (
                                                        <TableRow hover key={item.id}>
                                                            <TableCell component="th" scope="row">
                                                                {item.type === "Invoice" ?
                                                                    <>{item.description} <TooltipInvoiceDetail idinvoice={item.relid} /> </>
                                                                    :
                                                                    <>{item.description}</>
                                                                }
                                                            </TableCell>
                                                            <TableCell align="right">${item.amount}USD</TableCell>
                                                        </TableRow>
                                                    ))}

                                                    <TableRow>
                                                        <TableCell> <strong>Total</strong></TableCell>
                                                        <TableCell align="right">${invoice.total}USD</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                    </Box>

                                    {/* transactions*/}

                                    <Box mt={5} mb={3}>
                                        <Typography variant='h5' sx={{ mb: 2 }} color='primary'>Transacciones</Typography>
                                        <TableContainer component={Paper} variant="tbLight2-nk">
                                            <Table aria-label="simple table" size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell><strong>Fecha</strong></TableCell>
                                                        <TableCell><strong>Método/Gateway</strong></TableCell>
                                                        <TableCell><strong>ID Transacción</strong></TableCell>
                                                        <TableCell align="right"><strong>Total</strong></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {invoice.transactions.length !== 0 &&
                                                        <>
                                                            {invoice.transactions.map((trans) => (
                                                                <TableRow hover key={trans.id}>
                                                                    <TableCell component="th" scope="row">{trans.date}</TableCell>
                                                                    <TableCell>{trans.gateway}</TableCell>
                                                                    <TableCell>{trans.transid}</TableCell>

                                                                    {(trans.amountin !== '0.00' && trans.amountout === '0.00') && <TableCell align="right">${trans.amountin}USD</TableCell>}
                                                                    {(trans.amountout !== '0.00' && trans.amountin === '0.00') && <TableCell align="right">$-{trans.amountout}USD</TableCell>}

                                                                </TableRow>
                                                            ))}
                                                        </>
                                                    }
                                                    {invoice.transactions.length === 0 &&
                                                        <TableRow hover>
                                                            <TableCell colSpan={4} align="center">No se encontraron transacciones relacionadas</TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>

                                </Grid>
                            }

                            <Grid item xs={12} sm={12} md={4} align='center'>

                                {invoice.invoicestatus === 'Unpaid' &&
                                    <>
                                        <Typography variant='h5' sx={{ mt: 4, mb: 2, ml: 2, textAlign: 'left' }} color='primary'>Pagar a través de:</Typography>
                                        <Box>
                                            <FormControl sx={{ m: 1, width: 250 }}>
                                                <InputLabel id="labelPayment" sx={{ ml: 1.7, mt: -1 }}>Método de Pago</InputLabel>
                                                <Select
                                                    labelId="labelPayment"
                                                    onChange={handleChangePayment}
                                                    value={payment}
                                                    input={<OutlinedInput label="Método de Pago" />}
                                                    size="small"
                                                >
                                                    {invoice.payments.map((pay) => (
                                                        <MenuItem
                                                            key={pay.id}
                                                            value={pay.gateway}
                                                        >
                                                            {pay.value}
                                                        </MenuItem>
                                                    ))}
                                                </Select>

                                                <Box mt={4}>
                                                    {payment === 'banktransfer' &&
                                                        <Typography variant='body2' color="textSecondary">Puede pagar a través de depósito, transferencia bancaria o pago por QR. Encuentre las cuentas bancarias habilitadas <Link
                                                            color="primary"
                                                            component="a"
                                                            href="https://www.neothek.com/es-bo/efectivo/"
                                                            underline="always"
                                                            variant="body2"
                                                            target="_blank"
                                                            rel="noopener"
                                                        >
                                                            aquí
                                                        </Link></Typography>
                                                    }
                                                    {payment === 'paypal' &&
                                                        <PayPalButton invoice={invoice} paymethod={payment} />
                                                    }
                                                    {payment === 'tco' &&
                                                        <CheckoutButton invoice={invoice} paymethod={payment} />
                                                    }
                                                    {payment === 'stripe' &&
                                                        <>
                                                            {/*<StripeButton invoice={invoice} paymethod={payment} />*/}
                                                            <StripeButtonPayElement invoice={invoice} paymethod={payment} />
                                                        </>
                                                    }
                                                    {payment === 'payu' &&
                                                        <PayuButton invoice={invoice} paymethod={payment} />
                                                    }
                                                    {payment === 'mailin' &&
                                                        <><Typography variant='body2' color="textSecondary">Para pagar en efectivo por favor visite: <Link
                                                            color="primary"
                                                            component="a"
                                                            href="https://www.neothek.com/efectivo/"
                                                            underline="always"
                                                            variant="body2"
                                                            target="_blank"
                                                            rel="noopener"
                                                        >
                                                            https://www.neothek.com/efectivo/
                                                        </Link></Typography>
                                                            <Typography variant='body2' color="textSecondary">Referencia de Pago: {invoice.invoiceid}</Typography></>
                                                    }
                                                </Box>
                                            </FormControl>
                                        </Box>

                                    </>
                                }


                            </Grid>


                        </Grid>
                    </Container>
                </Box>

            </>

        )
    }

}

export default Invoice;