import { Box, Container, Divider, Grid, Tab, Typography, Paper, Button } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import SpinnerLoad from '../../components/FormsUI/Spinner';
import { logout } from '../../hooks/useAuth';
import { getTicketsClient } from '../../services/Ticket';
import TableTickets from './TableTickets';

const Tickets = (props) => {

    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [fullTickets, setFullTickets] = useState(null);
    const [tickets, setFilteredTickets] = useState(null);

    //to pagination
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);

    //to filter
    const [status, setStatus] = useState('all');

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };
    const handleLimitChange = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    const handleStatusChange = (event, newValue) => {
        setPage(0);
        setStatus(newValue);
    };

    const handleOpenTicket = () => {
        navigate('/clientarea/tickets/open/', { replace: true });
    }

    useEffect(() => {

        const getTickets = async () => {
            const resserver = await getTicketsClient();

            if (resserver.error === true) {
                if (resserver.message.status === 401) {
                    logout();
                    navigate('/', { replace: true });
                }
            }
            if (resserver.error === false) {
                if (resserver.tickets.length === 0) {
                    setError(true);
                } else {
                    setFullTickets(resserver.tickets);
                    //Filtrando los respondifos en caso que no exista, poner all
                    const answeredTickets = resserver.tickets.filter(ticket => (ticket.status === 'Answered' || ticket.status === 'Contestada'));
                    if (answeredTickets.length !== 0) {
                        setFilteredTickets(answeredTickets);
                        setStatus('Answered');
                    } else {
                        setFilteredTickets(resserver.tickets)
                    }
                }
            }

        }

        getTickets();

    }, []);

    useEffect(() => {

        if (status === 'all') {
            setFilteredTickets(fullTickets);
        }
        if (status === 'Answered') {
            const filteredticket = fullTickets.filter(dt => dt.status === 'Answered' || dt.status === 'Contestada');
            setFilteredTickets(filteredticket);
        }
        if (status === 'Customer-Reply') {
            const filteredticket = fullTickets.filter(dt => dt.status === 'Open' || dt.status === 'Customer-Reply' || dt.status === 'In Progress' || dt.status === 'En progreso' || dt.status === 'Escalado' || dt.status === 'Diseno Web' || dt.status === 'Grafico' || dt.status === 'Facturacion');
            setFilteredTickets(filteredticket);
        }
        if (status === 'Closed') {
            const filteredticket = fullTickets.filter(dt => dt.status === 'Closed' || dt.status === 'Cerrado' || dt.status === 'cerrado');
            setFilteredTickets(filteredticket);
        }

    }, [status])


    return (
        <>
            <Helmet>
                <title>Portal - Área de clientes de Neothek</title>
            </Helmet>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center'
                }}
            >

                <Container maxWidth="lg" variant='cont-nk'>
                    < Box >
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <Box mt={3} mb={3} >
                                    <Typography variant='h4'>Mis Tickets</Typography>
                                    <Divider />
                                </Box>
                            </Grid>

                            <Grid container justifyContent="flex-end">
                                <Button variant="contained" onClick={handleOpenTicket}>Abrir Ticket</Button>
                            </Grid>

                            {fullTickets === null || tickets === null ?

                                <>
                                    {error ?
                                        <Container maxWidth="sm" variant='cont-nk'>

                                            <Box sx={{ mb: 2, mt: 3 }}>
                                                <Typography
                                                    color="textPrimary"
                                                    variant="h2"
                                                    align="center"
                                                >
                                                    ****
                                                </Typography>

                                            </Box>

                                            <Box sx={{ pt: 1, pb: 2 }}>
                                                <Typography
                                                    color="textSecondary"
                                                    gutterBottom
                                                    variant="body1"
                                                    align="center"
                                                >
                                                    Aún no tienes tickets registrados bajo tu cuenta.
                                                </Typography>

                                            </Box>

                                        </Container>

                                        :

                                        <SpinnerLoad />
                                    }
                                </>

                                :

                                <Grid item xs={12}>

                                    <TabContext value={status} component={Paper}>
                                        <Box sx={{ borderBottom: 1, borderColor: '#e8f2ff' }}>
                                            <TabList onChange={handleStatusChange} aria-label="tabs estados pagos">
                                                <Tab label="Todos" value="all" variant='btnTab-nk' />
                                                <Tab label="Contestados" value="Answered" variant='btnTab-nk' />
                                                <Tab label="Abiertos" value="Customer-Reply" variant='btnTab-nk' />
                                                <Tab label="Cerrados" value="Closed" variant='btnTab-nk' />
                                            </TabList>
                                        </Box>
                                        <TabPanel value="all">
                                            <TableTickets tickets={tickets} page={page} limit={limit} handlePageChange={handlePageChange} handleLimitChange={handleLimitChange}></TableTickets>
                                        </TabPanel>
                                        <TabPanel value="Answered">
                                            <TableTickets tickets={tickets} page={page} limit={limit} handlePageChange={handlePageChange} handleLimitChange={handleLimitChange}></TableTickets>
                                        </TabPanel>
                                        <TabPanel value="Customer-Reply">
                                            <TableTickets tickets={tickets} page={page} limit={limit} handlePageChange={handlePageChange} handleLimitChange={handleLimitChange}></TableTickets>
                                        </TabPanel>
                                        <TabPanel value="Closed">
                                            <TableTickets tickets={tickets} page={page} limit={limit} handlePageChange={handlePageChange} handleLimitChange={handleLimitChange}></TableTickets>
                                        </TabPanel>
                                    </TabContext>

                                </Grid>

                            }
                        </Grid>
                    </Box>
                </Container>

            </Box >
        </>
    )
}

export default Tickets;