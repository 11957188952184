import axios from 'axios'
import appConfig from '../config/main.config'
const apiserv = appConfig.url_api;

export default async function getPriceTld(tld, type, currency) {

    const data = {
        extension: tld,
        type: type,
        currency: currency
    }
    const res = await axios.post(`${apiserv}/pricing/domain/`, data)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            //console.log(error)
        })

    return res;
}
