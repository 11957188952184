import React from 'react';
import { Box, Chip, Paper, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, TablePagination, Typography } from '@material-ui/core'
import TimeIcon from '@material-ui/icons/AccessTimeFilled'
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const TableServices = (props) => {
    const navigate = useNavigate();
    const { services, page, limit, handlePageChange, handleLimitChange } = props;
    //console.log('services', services)

    const handleViewDetails = (idhost) => {
        navigate(`/clientarea/products/${idhost}/`);
    }

    return (
        <Box mt={1} mb={3} >
            <TableContainer component={Paper} variant='tbLight-nk'>
                <Table aria-label="simple table" sx={{ minWidth: 650 }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Producto/Servicio</strong></TableCell>
                            <TableCell align="left"><strong>Estado</strong></TableCell>
                            <TableCell align="left"><strong>Período</strong></TableCell>
                            <TableCell align="left"><strong>Vencimiento</strong></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {services.slice(page * limit, page * limit + limit).map((service) => (
                            <TableRow hover key={service.id} >
                                <TableCell component="th" scope="row">
                                    {service.category} - {service.name} <br></br>
                                    <Typography color="primary" variant='hightlightText-nk'> {service.domain} </Typography>
                                </TableCell>
                                <TableCell align="left">
                                    {service.status === 'Pending' &&
                                        <Chip color="secondary" size="small" label={service.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#E3E8EE', color: '#53566B' }} />
                                    }
                                    {service.status === 'Active' &&
                                        <Chip size="small" label={service.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#cbf4c9', color: '#268717' }} />
                                    }
                                    {service.status === 'Completed' &&
                                        <Chip size="small" label={service.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#abf6f9', color: '#34a7c1' }} />
                                    }
                                    {(service.status === 'Suspended' || service.status === 'Terminated' || service.status === 'Cancelled') &&
                                        <Chip size="small" label={service.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#F8DADB', color: '#B72136' }} />
                                    }
                                </TableCell>
                                <TableCell align="left"> {service.billingcycle}</TableCell>
                                <TableCell align="left"> {(service.billingcycle === 'One Time' || service.billingcycle === 'Free Account') ? <> - </> : <> {service.nextduedate} </>} </TableCell>

                                {((service.status === 'Active' || service.status === 'Suspended') && (service.billingcycle !== 'One Time' && service.billingcycle !== 'Free Account')) ?
                                    <>
                                        {service.status === 'Active' ?
                                            <TableCell>
                                                {service.expirydays < 30 ?
                                                    <Box sx={{ display: "inline-table", width: "9rem", fontSize: "1.3rem", color: "#ff5a5a" }}>
                                                        <TimeIcon sx={{ fontSize: "1.6rem", verticalAlign: "text-top", marginTop: "0.1rem", marginRight: "0.2rem" }} /> {service.expirydays} días
                                                    </Box>
                                                    :
                                                    <Box sx={{ display: "inline-table", width: "9rem", fontSize: "1.3rem" }}>
                                                        <TimeIcon sx={{ fontSize: "1.6rem", verticalAlign: "text-top", marginTop: "0.1rem", marginRight: "0.2rem" }} /> {service.expirydays} días
                                                    </Box>
                                                }

                                            </TableCell>
                                            :
                                            <TableCell> </TableCell>
                                        }
                                    </>
                                    :
                                    <TableCell> </TableCell>
                                }

                                <TableCell><Button variant="outlined" onClick={() => handleViewDetails(service.id)} >Ver Detalles</Button></TableCell>

                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={services.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25]}
            />

        </Box>
    );
}

export default TableServices;