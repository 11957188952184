import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import SpinnerLoad from '../FormsUI/Spinner';

const StripePaymentStatus = () => {

    const urlParams = new URLSearchParams(useLocation().search);

    //console.log('url params search', urlParams)
    const invoiceid = urlParams.get('invoice_id');
    const payment_intent = urlParams.get('payment_intent');
    const payment_intent_client_secret = urlParams.get('payment_intent_client_secret');

    return (
        <div>
            {invoiceid && payment_intent && payment_intent_client_secret ?
                <Container maxWidth="sm" variant='cont-nk' sx={{ py: 2 }}>
                    <Typography variant='h2' color="textSecondary" style={{ textAlign: 'center' }}>Gracias!</Typography>
                    <br></br>
                    <Typography variant='body1' color="textSecondary" style={{ textAlign: 'center' }}>Hemos recibido su pago. <br></br>Cuando la solicitud haya sido procesada exitosamente se le enviará un correo electrónico.</Typography>
                    <br></br>
                    <Typography variant='body1' color="textSecondary" style={{ textAlign: 'center' }}>Puede encontrar los detalles en <Link to={`/clientarea/invoices/${invoiceid}/`}>#{invoiceid}</Link></Typography>
                </Container>
                :
                <Container>
                    <SpinnerLoad />
                </Container>
            }
        </div>

    )

};

export default StripePaymentStatus;