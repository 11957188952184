import React from 'react'
import { Container, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import SpinnerLoad from '../FormsUI/Spinner';
//http://localhost:3000/clientarea/return-2co?uid=7854&invoiceid=7854&key=785323&total=45.85&order_number=222

function ReturnPayutemp(props) {
    const urlParams = new URLSearchParams(useLocation().search);

    const signature = urlParams.get('signature');


    return (
        <div>

            {signature ? 
                <Container maxWidth="sm" variant='cont-nk'>
                    <Typography variant='body2' color="textSecondary" style={{ textAlign: 'center' }}>Su pago está siendo procesado, recibirá la confirmación más adelante al correo registrado.</Typography>
                </Container>
             : 
                <Container>
                    <SpinnerLoad />
                </Container>
            }


        </div>
    )
}

export default ReturnPayutemp;