import React from 'react'
import { Container, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import SpinnerLoad from '../FormsUI/Spinner';
//http://localhost:3000/clientarea/return-2co?uid=7854&invoiceid=7854&key=785323&total=45.85&order_number=222

function Return2COtemp(props) {
    const urlParams = new URLSearchParams(useLocation().search);

    //console.log('url params search', urlParams)
    const orderKey = urlParams.get('key');
    const orderTotal = urlParams.get('total');
    const orderNumber = urlParams.get('order_number');


    return (
        <div>

            {orderKey && orderTotal && orderNumber ? 
                <Container maxWidth="sm" variant='cont-nk' sx={{ py: 2 }}>
                    <Typography variant='h2' color="textSecondary" style={{ textAlign: 'center' }}>Gracias!</Typography>
                    <br></br>
                    <Typography variant='body1' color="textSecondary" style={{ textAlign: 'center' }}>Hemos recibido su pago. <br></br>Cuando la solicitud haya sido procesada exitosamente se le enviará un correo electrónico.</Typography>
                    <br></br>
                </Container>
             : 
                <Container>
                    <SpinnerLoad />
                </Container>
            }


        </div>
    )
}

export default Return2COtemp;