import React from 'react';
import Alert from '@material-ui/core/Alert';
import { Box, Button, Grid } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom';
import NextIcon from '@material-ui/icons/ArrowForward';

function DominioDisponible({ dominio, product, billingcycle, tld, eventDomain, configproduct, coupon, typeui }) {
    const navigate = useNavigate();
    const location = useLocation();

    function handleRoute() {
        let pathnavigate = '/cart/product/revorder/';
        const path = location.pathname;
        const pathini = path.substring(1, 10);
        if (pathini === 'cart/plan') {
            pathnavigate = '/cart/plan/revorder/';
        }

        navigate(pathnavigate, { state: { dominio: dominio, product: product, billingcycle: billingcycle, tld: tld, eventDomain: eventDomain, confopt: configproduct.confopt, subopt: configproduct.subopt, coupon: coupon, typeui: typeui} });
    }

    return (
        <Grid>
            <Box mt={3}>
                {eventDomain === 'domainregister' && <Alert severity="success"><strong>{dominio}:</strong> Está disponible para registro</Alert>}
                {eventDomain === 'domaintransfer'
                    && <Alert severity="success"><strong>{dominio}:</strong> Su dominio es elegible para transferencia <br></br> Por favor, asegúrese de haber desbloqueado su dominio en el actual registrador antes de continuar.</Alert>}
            </Box>

            <Grid container dir="rtl">
                {/*<Link to="/ajustes" as={Button} variant='contained' color='primary'>Añadir al Carrito
                 </Link>
                <ListItem button component={Link} to="/ajustes" variant='contained' color='primary'>Ajustes</ListItem>*/}
                <Box mt={3}>
                    <Button color='success' variant='contained' startIcon={<NextIcon sx={{marginRight: '-8px', marginLeft: '6px'}} />}  onClick={handleRoute}>
                        Siguiente
                    </Button>
                </Box>

            </Grid>

        </Grid>


    )
}

export default DominioDisponible;