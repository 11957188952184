import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { LoadingButton } from '@mui/lab';
import { createStripePaymentIntent } from '../../services/Payment';
import { useNavigate } from 'react-router-dom';

function StripeButtonPayElement(props) {
    const navigate = useNavigate();
    const [loadingButton, setLoadingButton] = useState(false);

    const handlePayIntentStripe = async () => {
        setLoadingButton(true);
        const csecret = await createStripePaymentIntent(props.invoice);

        if (csecret.error === false) {
            setLoadingButton(false);
            const payData = {
                clientSecret: csecret.client_secret,
                invoiceid: props.invoice.invoiceid,
                client_email: props.invoice.client_email,
                client_firstname: props.invoice.client_firstname,
                client_lastname: props.invoice.client_lastname,
                client_country: props.invoice.client_country,
                total: props.invoice.total
            }

            navigate('/clientarea/stripe-payment-elements/', { state: { payData } });
           
        } else {
            //poner un mensaje para intentar con otra pasarela en caso de error
        }
    };

    return (
        <div>

            <Box sx={{ px: 2 }}>
                <LoadingButton loading={loadingButton} variant="contained" style={{ color: '#fff' }} fullWidth onClick={handlePayIntentStripe}>Iniciar Pago</LoadingButton>
            </Box>

        </div>
    );
}

export default StripeButtonPayElement;