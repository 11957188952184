import React, { useEffect, useState } from "react";
import {
  LinkAuthenticationElement,
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { LoadingButton } from "@mui/lab";
import { Box, Typography, Grid, Container, makeStyles } from "@material-ui/core";
import appConfig from '../../config/main.config';

const useStyles = makeStyles((theme) => ({

  [theme.breakpoints.up('md')]: {
    contStripeSummary: {
      marginTop: "22%"
    },
  },
  [theme.breakpoints.up('sm')]: {
    contStripePay: {
      paddingLeft: "50px !important",
      paddingRight: "50px !important",
      paddingTop: "16px",
      paddingBottom: "32px"
    },
  },
  [theme.breakpoints.down('md')]: {
    contStripeSummary: {
      marginTop: "5%"
    },
    bxSafeStripe: {
      backgroundSize: "20%"
    }
  },
  [theme.breakpoints.down('sm')]: {
    contStripePay: {
      paddingLeft: "24px !important",
      paddingRight: "24px !important",
      paddingTop: "16px",
      paddingBottom: "16px"
    },
    bxSafeStripe: {
      backgroundSize: "31%"
    }
  },
}));

function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const client_email = props.clientEmail;
  const client_fullname = props.clientFullname;
  const client_country = props.clientCountry;
  const invoiceid = props.invoiceid;
  const total = props.total;

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${appConfig.url_domain}/clientarea/return-stripe/status?invoice_id=${invoiceid}`,
        //redirect: 'if_required'

      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  };

  return (
    <Container maxWidth="md" variant="cont-nk" className={classes.contStripePay} >

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8} >
            <Typography variant='h4' sx={{ mt: 2, mb: 2 }}>Contact Info</Typography>

            <LinkAuthenticationElement
              options={{
                defaultValues: {
                  email: client_email,
                },
              }}
            />

            <Typography variant='h4' sx={{ mt: 3, mb: 2 }}>Payment</Typography>
            <PaymentElement
              options={{
                defaultValues: {
                  billingDetails: {
                    name: client_fullname,
                    address: {
                      country: client_country,
                    }
                  },
                },
              }}

            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Container variant="cont-nk" sx={{ p: 3, pb: 4 }} className={classes.contStripeSummary}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant='body2'>Subtotal</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant='body2'>${total}USD</Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} sx={{ borderTop: "1px solid #efefef", paddingTop: "0px !important", marginTop: 2 }}></Grid>

                <Grid item xs={8}>
                  <Typography variant='body2'><strong>Total</strong></Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant='body2'><strong>${total}USD</strong></Typography>
                </Grid>

              </Grid>

              <Grid container sx={{ pt: 4 }}>
                <LoadingButton loading={isLoading} variant="contained" style={{ color: '#fff' }} fullWidth disabled={isLoading || !stripe || !elements} type="submit">Pagar Ahora</LoadingButton>

                {/* Show any error or success messages */}
                {message && <Typography variant='body2' sx={{ color: "#ff2323", fontSize: "1.3rem", marginTop: "10px" }}>{message}</Typography>}

              </Grid>

            </Container>

            <Grid container sx={{ textAlign: "center" }}>
              <Box className={classes.bxSafeStripe} sx={{ backgroundImage: "url(/static/images/payments/safe_stripe.png)", backgroundSize: "40%", width: "100%", height: "70px", backgroundRepeat: "no-repeat", marginTop: "3%", backgroundPosition: "center" }}></Box>
            </Grid>


          </Grid>
        </Grid>

      </form>


    </Container>

  );
}

export default CheckoutForm;