import { Box, ClickAwayListener, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import React, { useState } from 'react';
import { getInvoiceDescription } from '../../services/Invoice';

const useStyles = makeStyles((theme) => ({
    [theme.breakpoints.down('sm')]: {
        tooltipInv: {
            '& .MuiTooltip-popper': { maxWidth: "300px" }
        }
    },
}));

const TooltipInvoiceDetail = (props) => {
    const { idinvoice } = props;
    const [open, setOpen] = useState(false);
    const [details, setDetails] = useState([]);

    const classes = useStyles();

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = async () => {
        const detailinvoices = await getInvoiceDescription(idinvoice);
        setDetails(detailinvoices.descriptions);

        if (detailinvoices.error === false) {
            setDetails(detailinvoices.descriptions);
            setOpen(true);
        }

    };

    return (
        <>
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <Box sx={{ display: "inline" }} className={classes.tooltipInv} >
                    <Tooltip
                        PopperProps={{
                            disablePortal: true,
                        }}
                        onClose={handleTooltipClose}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener

                        title={details.map((detail) => (
                            <div key={detail.id} > * {detail.description}</div>
                        ))}
                        arrow
                        variant='tooltipinv-nk'
                        placement='top'
                    >

                        <IconButton onClick={handleTooltipOpen} sx={{ padding: "0px" }} >
                            <InfoIcon sx={{ fontSize: "16px" }} />
                        </IconButton>
                    </Tooltip>
                </Box>
            </ClickAwayListener>
        </>
    );
}

export default TooltipInvoiceDetail;