import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled, Box, Container } from '@material-ui/core';
import DashboardNavbar from './DashboardNavbar';
import NeoNavbar from './NeoNavbar';
import DashboardSidebar from './DashboardSidebar';

const DashboardLayoutRoot = experimentalStyled('div')(
  ({ theme }) => ({
    //backgroundColor: theme.palette.background.default
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingTop: 64
    }

  })
);

const DashboardLayoutWrapper = experimentalStyled('div')(
  ({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  })
);

const LayoutNavbar = experimentalStyled('div')(
  ({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      top:56
    }
  })
);


const ContentLayout = experimentalStyled('div')(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    marginTop: '27%'
  },
  [theme.breakpoints.up('sm')]: {
    marginTop: '21%'
  },
  [theme.breakpoints.up('md')]: {
    marginTop: '10%'
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: '2%'
  },
}));

const DashboardLayoutTestSide = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div>
      <DashboardLayoutRoot>
        <LayoutNavbar>
          <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
        </LayoutNavbar>
        

      
          <DashboardSidebar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
          />
        


        <DashboardLayoutWrapper>
          <ContentLayout>
            <Box>
              <Container style={{ marginTop: '4%', marginBottom: '3%' }}>
                <Outlet />
              </Container>
            </Box>
          </ContentLayout>
        </DashboardLayoutWrapper>

        <NeoNavbar />
      </DashboardLayoutRoot>
    </div>
  );
};

export default DashboardLayoutTestSide;