import axios from 'axios';
import appConfig from '../config/main.config';
const apiserv = appConfig.url_api;

export const getDomainTlds = async () => {
    const response = await fetch(`${apiserv}/domains/extensions/`)
    const body = await response.json();

    return body;
}

export const getProductAvailable = async (idProd) => {
    //const apiprod = '/products/isavailable/' + idProd;
    const apiprod = `${apiserv}/products/isavailable/${idProd}`
    const response = await fetch(apiprod);
    if (response.ok) {
        const body = await response.json();
        return body;
    } else {
        const product = false;
        return product;
    }

}

export const getProduct = async (idProd) => {
    //const apipriceprod = '/products/prices/' + idProd;
    const apipriceprod = `${apiserv}/products/${idProd}/prices/`;

    const response = await fetch(apipriceprod);
    const body = await response.json();

    return body;
}

export const getSpecialProduct = async (idProd) => { //producto con precios especiales
    //const apipriceprod = '/products/prices/' + idProd;
    const apipriceprod = `${apiserv}/products/${idProd}/specialprices/`;

    const response = await fetch(apipriceprod);
    const body = await response.json();

    return body;
}

export const getRenewInvoiceProduct = (hostid, userid) => {
    const api = `${apiserv}/hostings/${hostid}/renewinvoices/`;

    const data = {
        idhost: hostid,
        userid: userid
    }

    const invoice = axios.post(api, data)
        .then(res => {
            return { ...res.data, ...{ error: false } };
        })
        .catch((error) => {
            return {error: true}
    });

    return invoice;
}

//dar array de ciclo de product
export const getCycleProduct = (body) => {
    let arrayperiod = [];

    if (body.monthly !== -1) {
        let cycleprod = {};
        cycleprod.period = 'Mensual';
        cycleprod.price = body.monthly;
        cycleprod.periodeng = 'monthly';
        cycleprod.qtymonths = 1;
        cycleprod.pricerenewmonth = (body.monthly / 1).toFixed(2);
        arrayperiod.push(cycleprod);
    };
    if (body.quarterly !== -1) {
        let cycleprod = {};
        cycleprod.period = 'Trimestral';
        cycleprod.price = body.quarterly;
        cycleprod.periodeng = 'quarterly';
        cycleprod.qtymonths = 3;
        cycleprod.pricerenewmonth = (body.quarterly / 3).toFixed(2);
        arrayperiod.push(cycleprod);
    };
    if (body.semiannually !== -1) {
        let cycleprod = {};
        cycleprod.period = 'Semi-Anual';
        cycleprod.price = body.semiannually;
        cycleprod.periodeng = 'semiannually';
        cycleprod.qtymonths = 6;
        cycleprod.pricerenewmonth = (body.semiannually / 6).toFixed(2);
        arrayperiod.push(cycleprod);
    };
    if (body.annually !== -1) {
        let cycleprod = {};
        cycleprod.period = 'Anual';
        cycleprod.price = body.annually;
        cycleprod.periodeng = 'annually';
        cycleprod.qtymonths = 12;
        cycleprod.pricerenewmonth = (body.annually / 12).toFixed(2); //mostrar cuando hay promociones 
        arrayperiod.push(cycleprod);
    };
    if (body.biennially !== -1) {
        let cycleprod = {};
        cycleprod.period = 'Bi-Anual';
        cycleprod.price = body.biennially;
        cycleprod.periodeng = 'biennially';
        cycleprod.qtymonths = 24;
        cycleprod.pricerenewmonth = (body.biennially / 24).toFixed(2);
        arrayperiod.push(cycleprod);
    };
    if (body.triennially !== -1) {
        let cycleprod = {};
        cycleprod.period = 'Tri-Anual';
        cycleprod.price = body.triennially;
        cycleprod.periodeng = 'triennially';
        cycleprod.qtymonths = 36;
        cycleprod.pricerenewmonth = (body.triennially / 36).toFixed(2);
        arrayperiod.push(cycleprod);
    };

    return arrayperiod;
}

export const getAddons = async (idProd) => {
    const api = `${apiserv}/addons/products/${idProd}`;
    const res = await fetch(api);
    const apiaddons = await res.json();
    const addonsarray = apiaddons;

    //return addons de product mas stauts ischeckfalse
    return addonsarray;
}

//get addons filtrando tipo de addon
export const getMinAddons = async (addons) => {
    let addonsminarr = [];

    addons.map((body) => {
        let mincyclenew = {};

        //si billyngcyle no es onetime o free
        if (body.billingcycle === "recurring") {

            if (body.monthly !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Mensual'; mincyclenew.description = body.description; mincyclenew.price = body.monthly; mincyclenew.periodeng = 'monthly'; mincyclenew.id = body.id; mincyclenew.checked = body.ischeck; addonsminarr.push(mincyclenew) }
            else {
                if (body.quarterly !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Trimestral'; mincyclenew.description = body.description; mincyclenew.price = body.quarterly; mincyclenew.periodeng = 'quarterly'; mincyclenew.id = body.id; mincyclenew.checked = body.ischeck; addonsminarr.push(mincyclenew) }
                else {
                    if (body.semiannually !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Semi-Anual'; mincyclenew.description = body.description; mincyclenew.price = body.semiannually; mincyclenew.periodeng = 'semiannually'; mincyclenew.id = body.id; mincyclenew.checked = body.ischeck; addonsminarr.push(mincyclenew) } else {
                        if (body.annually !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Anual'; mincyclenew.description = body.description; mincyclenew.price = body.annually; mincyclenew.periodeng = 'annually'; mincyclenew.id = body.id; mincyclenew.checked = body.ischeck; addonsminarr.push(mincyclenew) } else {
                            if (body.biennially !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Bi-Anual'; mincyclenew.description = body.description; mincyclenew.price = body.biennially; mincyclenew.periodeng = 'biennially'; mincyclenew.id = body.id; mincyclenew.checked = body.ischeck; addonsminarr.push(mincyclenew) } else {
                                if (body.triennially !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Tri-Anual'; mincyclenew.description = body.description; mincyclenew.price = body.triennially; mincyclenew.periodeng = 'triennially'; mincyclenew.id = body.id; mincyclenew.checked = body.ischeck; addonsminarr.push(mincyclenew) };
                            }
                        }
                    }
                }
            }


        } //si es onetime o free
        else {
            //si es onetime
            if (body.billingcycle === "onetime") {
                if (body.monthly !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Una vez'; mincyclenew.description = body.description; mincyclenew.price = body.monthly; mincyclenew.periodeng = 'monthly'; mincyclenew.id = body.id; mincyclenew.checked = body.ischeck; addonsminarr.push(mincyclenew) }
                else {
                    if (body.quarterly !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Una vez'; mincyclenew.description = body.description; mincyclenew.price = body.quarterly; mincyclenew.periodeng = 'quarterly'; mincyclenew.id = body.id; mincyclenew.checked = body.ischeck; addonsminarr.push(mincyclenew) }
                    else {
                        if (body.semiannually !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Una vez'; mincyclenew.description = body.description; mincyclenew.price = body.semiannually; mincyclenew.periodeng = 'semiannually'; mincyclenew.id = body.id; mincyclenew.checked = body.ischeck; addonsminarr.push(mincyclenew) } else {
                            if (body.annually !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Una vez'; mincyclenew.description = body.description; mincyclenew.price = body.annually; mincyclenew.periodeng = 'annually'; mincyclenew.id = body.id; mincyclenew.checked = body.ischeck; addonsminarr.push(mincyclenew) } else {
                                if (body.biennially !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Una vez'; mincyclenew.description = body.description; mincyclenew.price = body.biennially; mincyclenew.periodeng = 'biennially'; mincyclenew.id = body.id; mincyclenew.checked = body.ischeck; addonsminarr.push(mincyclenew) } else {
                                    if (body.triennially !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Una vez'; mincyclenew.description = body.description; mincyclenew.price = body.triennially; mincyclenew.periodeng = 'triennially'; mincyclenew.id = body.id; mincyclenew.checked = body.ischeck; addonsminarr.push(mincyclenew) };
                                }
                            }
                        }
                    }
                } //fin

            } else {
                //si es free
                if (body.billingcycle === "free") {

                    mincyclenew.name = body.name; mincyclenew.period = 'Gratis!'; mincyclenew.description = body.description; mincyclenew.price = body.monthly; mincyclenew.periodeng = 'monthly'; mincyclenew.id = body.id; mincyclenew.checked = body.ischeck; addonsminarr.push(mincyclenew)

                }//si no es onetime - free- recurring, lo mismo q recurring temporal
                else {
                    //ini
                    /*if (body.monthly !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Mensual'; mincyclenew.description = body.description; mincyclenew.price = body.monthly; mincyclenew.periodeng = 'monthly'; mincyclenew.id = body.id; mincyclenew.checked = body.ischeck; addonsminarr.push(mincyclenew) }
                    else {
                        if (body.quarterly !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Trimestral'; mincyclenew.description = body.description; mincyclenew.price = body.quarterly; mincyclenew.periodeng = 'quarterly'; mincyclenew.id = body.id; mincyclenew.checked = body.ischeck; addonsminarr.push(mincyclenew) }
                        else {
                            if (body.semiannually !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Semi-Anual'; mincyclenew.description = body.description; mincyclenew.price = body.semiannually; mincyclenew.periodeng = 'semiannually'; mincyclenew.id = body.id; mincyclenew.checked = body.ischeck; addonsminarr.push(mincyclenew) } else {
                                if (body.annually !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Anual'; mincyclenew.description = body.description; mincyclenew.price = body.annually; mincyclenew.periodeng = 'annually'; mincyclenew.id = body.id; mincyclenew.checked = body.ischeck; addonsminarr.push(mincyclenew) } else {
                                    if (body.biennially !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Bi-Anual'; mincyclenew.description = body.description; mincyclenew.price = body.biennially; mincyclenew.periodeng = 'biennially'; mincyclenew.id = body.id; mincyclenew.checked = body.ischeck; addonsminarr.push(mincyclenew) } else {
                                        if (body.triennially !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Tri-Anual'; mincyclenew.description = body.description; mincyclenew.price = body.triennially; mincyclenew.periodeng = 'triennially'; mincyclenew.id = body.id; mincyclenew.checked = body.ischeck; addonsminarr.push(mincyclenew) };
                                    }
                                }
                            }
                        }
                    }//end */

                    let periodspanish = 'Mensual';
                    let periodeng = 'monthly';
                    switch (body.billingcycle) {
                        case 'Monthly': {
                            periodspanish = 'Mensual';
                            periodeng = 'monthly';
                            break;
                        };
                        case 'Quarterly': {
                            periodspanish = 'Trimestral';
                            periodeng = 'quarterly';
                            break;
                        };
                        case 'Semi-Annually': {
                            periodspanish = 'Semi-Anual';
                            periodeng = 'semiannually';
                            break;
                        };
                        case 'Annually': {
                            periodspanish = 'Anual';
                            periodeng = 'annually';
                            break;
                        };
                        case 'Biennially': {
                            periodspanish = 'Bi-Anual';
                            periodeng = 'biennially';
                            break;
                        };
                        case 'Triennially': {
                            periodspanish = 'Tri-Anual';
                            periodeng = 'triennially';
                            break;
                        };
                        default: {
                            periodspanish = 'Mensual'
                            periodeng = 'monthly';
                            break;
                        }
                    };

                    mincyclenew.name = body.name; mincyclenew.period = periodspanish; mincyclenew.description = body.description; mincyclenew.price = body.monthly; mincyclenew.periodeng = periodeng; 
                    mincyclenew.id = body.id; mincyclenew.checked = body.ischeck; addonsminarr.push(mincyclenew);

                }
            }

        }



    });

    //poner minimos para mostrar en list, add description, periodeng, recurring
    //console.log('min addons', addonsminarr)
    return addonsminarr;
}

//obtiene precio y cycle inicial válido de producto
export const getValIniProd = (cycleProd, cycle) => {
    let price = 0;
    let res = {};
    cycleProd.map((billingcycle) => {
        if (billingcycle.periodeng === cycle) {
            price = billingcycle.price;
        }
    });

    //console.log('price', price);

    res = { price: price, billingcycle: cycle };

    //en caso de que cycle no exista, return el minimo cycle
    if (price === 0) {
        const pricemin = cycleProd[0].price;
        const cyclemin = cycleProd[0].periodeng;
        res = { price: pricemin, billingcycle: cyclemin };
    }

    //console.log('get val ini prod', res);

    return res;
}

export const getIsFreeDomain = (tld, billingcycle, product) => {
    const cyclesfree = product.freedomainpaymentterms.split(',');
    const tldsfree = product.freedomaintlds.split(',')
    let billingcycleselect = billingcycle;
    let res = { isFree: false, type: '' };
    const tldfree = tldsfree.find(n => n === tld);
    //let cyclefree = undefined;

    /*if(cyclesfree[0] === 'onetime'){
        cyclefree = cyclesfree.find(n => n === 'onetime');
    }else{
        cyclefree = cyclesfree.find(n => n === billingcycleselect);
    }*/

    if(product.paytype === 'onetime'){
        billingcycleselect = 'onetime'
    }

    const cyclefree = cyclesfree.find(n => n === billingcycleselect);

    if(product.paytype !== 'free'){
        if ((product.freedomain === 'on' || product.freedomain === 'once') && tldfree !== undefined && cyclefree !== undefined) {
            res = { isFree: true, type: product.freedomain };
        }
    }
    
    return res;


}

export const getDetailProduct = (idhost) => {

    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/hostings/${idhost}`;

    const hosting = axios.get(api, { headers: { "Authorization": `Bearer ${token}` } })
        .then(res => {
            return { ...res.data, ...{error: false}}
        })
        .catch((error) => {
            return {error: true, message: error.response}
        });

    return hosting;

};