import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

export default function SpinnerLoad() {
  return (
    <Box sx={{textAlign: 'center' }}>
      <CircularProgress />
    </Box>
  );
}