import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Badge, Box, Hidden, IconButton, Toolbar, Menu, MenuItem, Typography, Tooltip } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import { logout } from '../hooks/useAuth';
import { makeStyles } from '@material-ui/core/styles';
import AccountIcon from '@material-ui/icons/AccountCircle'
import { getBasicDataClient } from '../services/Client';
import SpinnerLoad from './FormsUI/Spinner';
import Logo from './Logo';

const useStyles = makeStyles((theme) => ({
  /*root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  [theme.breakpoints.down('sm')]: {
    contMainNav: {
      marginTop: '14%'
    }
  },
  [theme.breakpoints.up('sm')]: {
    contMainNav: {
      marginTop: '8%'
    }
  },
  [theme.breakpoints.up('md')]: {
    contMainNav: {
      marginTop: '5.8%'
    }
  },
  [theme.breakpoints.up('lg')]: {
    contMainNav: {
      marginTop: '4.5%',
    }
  },
  [theme.breakpoints.up('xl')]: {
    contMainNav: {
      marginTop: '3.4%',
    }
  } */

}));

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const [notifications] = useState([]);
  const [client, setClient] = useState(null);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/')
  }

  //menu item
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {

    const captureDataClient = async () => {
      const client = await getBasicDataClient();
      //console.log('client', client)

      if (client.error === true) {
        if (client.message.status === 401) {
          const fullpath = window.location.href;
          const iniposition = fullpath.indexOf("/cart");
          const path = fullpath.substring(iniposition);
          const pathini = path.substring(1, 5);
          //console.log("path", path);
          let pathnavigate = '/';
          if (pathini === 'cart') {
            pathnavigate = path;
          }
          logout();
          //navigate('/', {replace: true});
          navigate(pathnavigate, { replace: true });
        }
      }
      if (client.error === false) {
        setClient(client);
      }
    }

    captureDataClient();

  }, []);

  return (
    <AppBar className={classes.contMainNav}
      sx={{ backgroundColor: '#f8f9fb', top: '60px' }}
      elevation={0}
      {...rest}
    >
      {client === null ?
        <SpinnerLoad />
        :
        <>
          {client.error === false &&
            <Toolbar>
              <RouterLink to="/" >
                <Box sx={{ paddingLeft: 1 }} >
                  <Logo />
                </Box>
              </RouterLink>
              <Box sx={{ flexGrow: 1 }} />
              <Hidden lgDown>
                {/* <IconButton color="inherit">
                  <Badge
                    badgeContent={notifications.length}
                    color="primary"
                    variant="dot"
                  >
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
                <IconButton color="inherit" onClick={handleLogout}>
                  <InputIcon />
                </IconButton>  */}
                <Typography sx={{ color: '#596275', fontSize: '12px' }}><b>Hola {client.firstname} !</b></Typography>

                <Tooltip title={client.email}>
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="primary"
                    sx={{ padding: '6px' }}
                  >
                    <AccountIcon style={{ fontSize: '1.5em' }} />
                  </IconButton>
                </Tooltip>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  keepMounted
                  /*anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}*/
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
                //open={isOpen}
                //onClose={() => setIsOpen(false)}

                > 
                  <MenuItem onClick={handleLogout}><Typography variant='h6'>Cerrar Sesión</Typography></MenuItem>
                  
                </Menu>


              </Hidden>

              <Hidden lgUp>
                <IconButton
                  color="primary"
                  onClick={onMobileNavOpen}
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>
            </Toolbar>
          }
        </>

      }

    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
