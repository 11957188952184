import React from 'react';
import { Box, Button } from '@material-ui/core';
import appConfig from '../../config/main.config';


function CheckoutButton(props) {
    const sid = appConfig.sid_2co;
    const invoiceid = props.invoice.invoiceid;
    const userid = props.invoice.client_id;

    const name = `Neothek - Nota Nro. ${invoiceid}`;
    const description = `Neothek - Nota Nro. ${invoiceid}`;
    const price = props.invoice.total;
    const firstname = props.invoice.client_firstname;
    const lastname = props.invoice.client_lastname;
    const cardname = `${firstname} ${lastname}`;
    const email = props.invoice.client_email;
    const address = props.invoice.client_address1;
    const city = props.invoice.client_city;
    const state = props.invoice.client_state;
    const zip = props.invoice.client_postcode;
    const country = props.invoice.client_country;
    const phone = props.invoice.client_phonenumber;
    const link_success= `${appConfig.url_domain}/clientarea/return-2co?uid=${userid}&invoiceid=${invoiceid}`;

    
    return (
        <div>
            <form action='https://www.2checkout.com/checkout/purchase' method='post'>
                <input type='hidden' name='sid' value= {sid}/>
                <input type='hidden' name='mode' value='2CO' />
                {/*<input type='hidden' name='demo' value='Y' />*/}
                <input type='hidden' name='currency_code' value='USD' />
                <input type='hidden' name='li_0_product_id' value={invoiceid} />
                <input type='hidden' name='li_0_type' value='product' />
                <input type='hidden' name='li_0_name' value={name} />
                <input type='hidden' name='li_0_description' value={description} />
                <input type='hidden' name='li_0_price' value={price} />
                <input type="hidden" name="card_holder_name" value={cardname} />
                <input type="hidden" name="email" value={email} />
                <input type="hidden" name="street_address" value={address} />
                <input type="hidden" name="city" value={city} />
                <input type="hidden" name="state" value={state} />
                <input type="hidden" name="zip" value={zip} />
                <input type="hidden" name="country" value={country} />
                <input type="hidden" name="phone" value={phone} />
                <input type='hidden' name='x_receipt_link_url' value={link_success} />
                
                <Box sx={{px:2}}>
                <Button variant="contained" name='submit' type='submit' color="success" style={{color: '#fff'}} fullWidth>
                    Pago Único
                </Button>
                </Box>
                

                {/* <input name='submit' type='submit' value='Checkout' /> */}
            </form>
        </div>
    );
}

export default CheckoutButton;