import React, { useState } from 'react';
import { Box, Button, Paper, TableContainer, Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material'
import { useNavigate } from 'react-router';
import { loginAccountClient } from '../../services/User';
import { Typography } from '@material-ui/core';
import { saveAuth } from '../../hooks/useAuth';
import { getPublicIp } from '../../utils';

const TableClientAccounts = (props) => {
    const { accounts, uid, language } = props;
    const [errLoginClient, setErrLoginClient] = useState(null);
    const navigate = useNavigate();

    const handleClientSession = async (cid) => {
        const ip = await getPublicIp();
        const res = await loginAccountClient(uid, cid, language, ip);

        //console.log("clientsession", res);

        if (res.error === false) {
            saveAuth(res);
            navigate('/clientarea/dashboard/', { replace: true });
        } else {
            setErrLoginClient("Ha existido un error, por favor intente nuevamente");
        }
    }

    return (
        <Box mt={1} mb={3} >
            <TableContainer component={Paper} variant='tbLight-nk'>
                {errLoginClient &&
                    <Box>
                        <Typography sx={{ color: 'red', paddingBottom: "1rem", fontSize: "1.2rem" }} >
                            {errLoginClient}
                        </Typography>
                    </Box>
                }
                <Table size="small" aria-label="invoices table">
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Cuentas</strong></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {accounts.map((account) => (
                            <TableRow hover key={account.cid}>
                                <TableCell>{account.fullname} ({account.companyname})</TableCell>
                                <TableCell><Button variant="outlined" onClick={() => handleClientSession(account.cid)}>Ingresar</Button> </TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>

        </Box>

    );
}

export default TableClientAccounts;