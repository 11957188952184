import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper, Button, Typography } from '@mui/material'

function CarouselNotices(props) {
    const items = props.announcements;

    return (
            <Carousel indicators={false}>
                {
                    items.map((item, i) => <Item key={i} item={item} />)
                }
            </Carousel>
    )
}

function Item(props) {
    return (
        <Paper sx={{ border: "none" }}>
            <Typography variant='h4'>{props.item.title}</Typography>
            <Typography sx={{ marginBottom: "2%", fontSize: "1.2rem", color: "#959595" }}>Anunciado el {props.item.date}</Typography>
            <Typography color="colornk.bluedark" sx={{ marginBottom: "2%" }}>{props.item.announcement}</Typography>

            <Button variant="contained" href={`/announcements/${props.item.id}/`} sx={{ marginLeft: "3%", color: "#fff", backgroundColor: "#5184ff", "&:hover": { color: "#fff", backgroundColor: "#447afc" } }}>
                Leer más
            </Button>

        </Paper>
    )
}

export default CarouselNotices