import React from 'react';
import { Box, Chip, Link, Paper, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, TablePagination } from '@material-ui/core'
import { NavLink as RouterLink } from 'react-router-dom';

const TableTickets = (props) => {
    const { tickets, page, limit, handlePageChange, handleLimitChange } = props;

    return (
        <Box mt={1} mb={3} >
            <TableContainer component={Paper} variant='tbLight-nk'>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="tickets table">
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Ticket Nro.</strong></TableCell>
                            <TableCell align="left"><strong>Estado</strong></TableCell>
                            <TableCell align="left"><strong>Departamento</strong></TableCell>
                            <TableCell align="left"><strong>Asunto</strong></TableCell>
                            <TableCell align="right"><strong>Última Actualización</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {tickets.slice(page * limit, page * limit + limit).map((ticket) => (
                            <TableRow hover key={ticket.id}>
                                <TableCell component="th" scope="row">
                                    <Link component={RouterLink} to={`/clientarea/tickets/${ticket.tid}/c/${ticket.c}/`} sx={{fontWeight: "500"}} >{ticket.tid}</Link>
                                </TableCell>
                                <TableCell align="left">
                                    {(ticket.status === 'Open') &&
                                        <Chip color="secondary" size="small" label={ticket.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#E3E8EE', color: '#53566B' }} />
                                    }
                                    {(ticket.status === 'Answered' || ticket.status === 'Contestada') &&
                                        <Chip size="small" label={ticket.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#cbf4c9', color: '#268717' }} />
                                    }
                                    {(ticket.status === 'In Progress' || ticket.status === 'En progreso' || ticket.status === 'Escalado') &&
                                        <Chip size="small" label={ticket.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#aff8ff', color: '#1f8c96' }} />
                                    }
                                    {(ticket.status === 'Closed' || ticket.status === 'Cerrado' || ticket.status === 'cerrado') &&
                                        <Chip size="small" label={ticket.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#F8DADB', color: '#B72136' }} />
                                    }
                                    {ticket.status === 'Customer-Reply' &&
                                        <Chip size="small" label={ticket.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#c0ddff', color: '#6773c9' }} />
                                    }
                                    {ticket.status === 'Facturacion' &&
                                        <Chip size="small" label={ticket.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#73adff', color: '#fff' }} />
                                    }
                                    {(ticket.status === 'Diseno Web' || ticket.status === 'Grafico') && 
                                        <Chip size="small" label={ticket.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#a89fff', color: '#fff' }} />
                                    }
                                </TableCell>
                                <TableCell align="left">{ticket.dpto}</TableCell>
                                <TableCell align="left">{ticket.title}</TableCell>
                                <TableCell align="right">{ticket.lastreply}</TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={tickets.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25]}
            />

        </Box>

    );
}

export default TableTickets;