const publicIp = require('public-ip');
const dateFormat = require("dateformat");
const crypto = require('crypto');

export const getTimeNow = (format) => {
    let today = new Date()
    today = dateFormat(today, format)
    return today
}
const getIp = async() => {
    const ipv4 = await publicIp.v4();
    return ipv4;
}

export const getPublicIp = () => {
    const pubIp = getIp().then( res => {return res})
    return pubIp
    //return '200.58.82.47'
}

export const hashString = (val) => {
    const md5 = crypto.createHash('md5')
    const signHash = md5.update(val).digest('hex')

    return signHash
}
//redondea un numero
export const number_format = (number, decimals, dec_point, thousands_sep) => {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    let n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

//retorna cantidad de meses
export const getQtyMonths = (cycle) => {
    let res = 1;
    const lowcycle = cycle.toLowerCase();
    switch (lowcycle) {
        case "monthly":
            res = 1;
            break;
        case "quarterly":
            res = 3;
            break;
        case "semiannually":
            res = 6;
            break;
        case 'semi-annually':
            res = 6;
            break;
        case "annually":
            res = 12;
            break;
        case "biennially":
            res = 24;
            break;
        case "triennially":
            res = 36;
            break;
    
        default:
            res = 1;
            break;
    }

    return res;
};

export const getPercentDiscountRuleThree = (total, cant) => {
    const difference = (cant * 100) / total;
    const percentdiscount = Math.round(100 - difference);
    return percentdiscount;
};

export const getTldDomain = (domain) =>{
    let tld = "";
    const indexIni = domain.indexOf(".");
    tld = domain.substring(indexIni);

    return tld;
};

//hacer funcion para sumar meses a una fecha
export const getNewdateAddMonth = (date, months) => {
    const newdate = new Date(date);
    const year = newdate.getFullYear();
    const month = newdate.getMonth();
    const day = newdate.getDate();
    const c = new Date(year, month + months, day);

    //format newdate
    const nday = c.getDate();
    const idxmonth = c.getMonth();
    const nyear = c.getFullYear();
    const nmonth = getMonthNumber(idxmonth);

    const res = `${nday}/${nmonth}/${nyear}`;
    //const res = c.toLocaleDateString();
    //const res = c.toLocaleString();
    return res;
};

const getMonthNumber = (index) => {
    const months = [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12'
    ]

    const res = months[index];
    return res;
}