import React from 'react';
import ReactDOM from "react-dom"
import { Container } from "@material-ui/core";
import { useLocation } from 'react-router-dom';
import { getPublicIp } from '../../utils/index';
import { captureOrderPaypal, createOrderPaypal, registerPayPalPayment } from '../../services/Payment';

//const PayPalButtonApp = window.paypal.Buttons.driver("react", { React, ReactDOM });
const PayPalButtonApp2 = window.paypal.Buttons.driver("react", { React, ReactDOM });

const PayPalButton = (props) => {
  const location = useLocation();

  const datainvoice = {
    userid: props.invoice.client_id,
    currency: 0,
    gateway: props.paymethod,
    description: 'Invoice Payment',
    amountin: props.invoice.total,
    invoiceid: props.invoice.invoiceid
  }

  const invoiceid = props.invoice.invoiceid;
  /*const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          description: `Neothek - Nota Nro. ${invoiceid}`,
          amount: {
            value: props.invoice.total,
          }
        },
      ],
    });
  };
  const onApprove = (data, actions) => {
    //return actions.order.capture();
    return actions.order.capture().then(async function (details) {

      datainvoice['ipclient'] = getPublicIp();
      const info = {
        datainvoice: datainvoice,
        datagateway: details
      };

      //console.log('info payment paypal', info);
      //const paydout = await setPaydoutPayPal(info);
      const paydout = await registerPayPalPayment(info);

      if (paydout.error === false) {
        if (details.status === 'COMPLETED') {
          //navigate(`/clientarea/invoices/${invoiceid}/`, { replace: true });
          window.open(location.pathname, "_self");
          //navigate('/clientarea/success-payment/');
        }

      }

      //llevar a página en caso de error ?

    });
  };*/


  const createOrder2 = async (data, actions) => {
    const datasend = {
      value: props.invoice.total,
      description: `Neothek - Nota Nro. ${invoiceid}`,
      invoiceid: invoiceid
    }
    const order = await createOrderPaypal(datasend);
    let orderid = null;
    
    //console.log('order', order);

    if(order.error === false){
      orderid = order.id
    }

    return orderid
  }

  const onApprove2 = async (data, actions) => {
    //console.log('data on aprrove2', data);
    const capture = await captureOrderPaypal(data.orderID);
    //console.log('capture', capture);

    if(capture.error === false){
      if(capture.status === 'COMPLETED'){
        datainvoice['ipclient'] = await getPublicIp();
        const infopay = {
          datainvoice: datainvoice,
          datagateway: capture
        } 
        const paydout = await registerPayPalPayment(infopay);
  
        if(paydout.error === false){
          window.open(location.pathname, "_self");
        }
        
      }
    }else{
      alert(`Error: ${capture.name} \n  ${capture.message} \n  Please contact support`);
    }

    //return capture;

  }


  return (

    <Container>
      {/*<PayPalButtonApp
        createOrder={(data, actions) => createOrder(data, actions)}
        onApprove={(data, actions) => onApprove(data, actions)}
      />*/}

      <PayPalButtonApp2
        createOrder={(data, actions) => createOrder2(data, actions)}
        onApprove={(data, actions) => onApprove2(data, actions)}
      />


    </Container>


  );
};

export default PayPalButton;