import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material';
import SpinnerLoad from '../../components/FormsUI/Spinner';
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import AddIcon from '@mui/icons-material/AddCircle';
import RefreshIcon from '@mui/icons-material/Refresh';
import { validate2FA } from '../../services/User';
import { useNavigate } from 'react-router-dom';

function Account(props) {
    const navigate = useNavigate();
    const [detailAccount, setDetailAccount] = useState(null);
    const [error, setError] = useState(false);

    const handleStatus2FA = () => {
        if (detailAccount.security.active2FA === 0) {
            navigate('/clientarea/signingoptions/two-step-verification/', { replace: true });
        } else {
            navigate('/clientarea/signingoptions/change-two-step-verification/', { state: { enable: detailAccount.security.active2FA } });
        }
    };

    const handleChangePass = () => {
        navigate('/clientarea/signingoptions/password/', { state: {changepass: 1} });
    };

    useEffect(() => {
        const getDetailAccount = async () => {
            const account = await validate2FA();

            if (account.error === false) {
                setDetailAccount(account);
            } else {
                setError(true);
            }
        }

        getDetailAccount();
    }, [])

    return (
        <>
            <Helmet>
                <title>Portal - Área de clientes de Neothek</title>
            </Helmet>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center'
                }}
            >

                {detailAccount === null ?
                    <>
                        {error ?
                            <Container maxWidth="sm" variant='cont-nk'>
                                <Box sx={{ mb: 2, mt: 3 }}>
                                    <Typography
                                        color="textPrimary"
                                        variant="h2"
                                        align="center"
                                    >
                                        Lo sentimos,
                                    </Typography>

                                </Box>

                                <Box sx={{ pt: 1, pb: 2 }}>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="body1"
                                        align="center"
                                    >
                                        Ha existido una falla, por favor intente nuevamente.
                                    </Typography>
                                </Box>
                            </Container>
                            :
                            <SpinnerLoad />
                        }
                    </>

                    :

                    <Container maxWidth="md" variant='cont-nk'>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box mt={3} mb={3} >
                                    <Typography variant='h4'>Seguridad</Typography>
                                    <Divider />
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container sx={{ border: "1px solid #dadce0", borderRadius: "5px", py: 3, px: 2, marginBottom: "3rem" }}>

                                <Grid item sm={6} xs={12} sx={{ pt: 3 }}>
                                    <Typography sx={{ fontSize: "1.45rem", paddingTop: ".6rem", fontWeight: "500" }}>Contraseña</Typography>
                                    <Typography color="textSecondary" sx={{ fontSize: "1.33rem", paddingTop: ".6rem" }}>Tener una contraseña segura ayuda a proteger tu cuenta y los datos que almacenas.</Typography>
                                </Grid>
                                <Grid item sm={6} xs={12} sx={{ pt: 3 }}>
                                    <Grid container justifyContent="flex-end">
                                        <Button variant="outlined" startIcon={<RefreshIcon />} onClick={handleChangePass} >  Cambiar contraseña </Button>
                                    </Grid>
                                </Grid>

                            <Grid item xs={12}>
                                <Divider sx={{ marginTop: 3 }} />
                            </Grid>

                            <Grid item sm={6} xs={12} sx={{ pt: 3 }}>
                                <Typography sx={{ fontSize: "1.45rem", paddingTop: ".6rem", fontWeight: "500" }}>Verificación en dos pasos</Typography>
                                {detailAccount.security.active2FA === 1 && <Typography color="textSecondary" sx={{ fontSize: "1.33rem", paddingTop: ".6rem" }}>La verificación en dos pasos se encuentra activa, cada vez que inicies sesión en tu cuenta, deberás ingresar tu contraseña y también un código de verificación generado por <span style={{ fontWeight: "500" }}>Google Authenticator</span>.</Typography>}
                                {detailAccount.security.active2FA === 0 && <Typography color="textSecondary" sx={{ fontSize: "1.33rem", paddingTop: ".6rem" }}>Incrementa la seguridad de tu cuenta utilizando dos niveles de verificación.</Typography>}
                            </Grid>
                            <Grid item sm={6} xs={12} sx={{ pt: 3 }}>
                                <Grid container justifyContent="flex-end">
                                    {detailAccount.security.active2FA === 1 && <Button variant="outlined" startIcon={<RemoveIcon />} onClick={handleStatus2FA} >  Deshabilitar paso de Verificación </Button>}
                                    {detailAccount.security.active2FA === 0 && <Button variant="outlined" startIcon={<AddIcon />} onClick={handleStatus2FA} >  Añadir paso de Verificación </Button>}
                                    {detailAccount.security.active2FA === -1 && <Typography color="textSecondary" sx={{ fontSize: "1.45rem", paddingTop: ".6rem" }}>Por favor comúniquese con soporte para activar esta opción.</Typography>}
                                </Grid>
                            </Grid>

                        </Grid>

                    </Container>
                }

            </Box>
        </>
    )
}

export default Account;