import React, { useState, useRef, useEffect } from 'react'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Container, Link, TextField, Typography } from '@material-ui/core';
import ReCAPTCHA from "react-google-recaptcha";
import { forgetPass, verifyToken } from '../../services/ForgetPass';
import { getPublicIp } from '../../utils/index';
import appConfig from '../../config/main.config';

const ForgetPassword = () => {
  const captcha = useRef(null);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(useLocation().search);
  const token = urlParams.get('t');

  const [errValidUser, setErrValidUser] = useState(null);
  const [validCaptcha, setValidCaptcha] = useState(null);
  const [tokensent, setTokensent] = useState(false);
  const [errorToken, setErrorToken] = useState(false);

  const [disabledButton, setDisabledButton] = useState(true);

  const onChangeCaptcha = () => {
    if (captcha.current.getValue()) {
      //console.log('no es robot', captcha.current.getValue())
      setValidCaptcha(true);
      setDisabledButton(false);
    } else {
      setValidCaptcha(false);
      setDisabledButton(true);
    }
  }

  useEffect(() => {
    if (token !== null) {

      (async function () {
        //verificar validez token
        const validToken = await verifyToken(token);
        //console.log('token valid', validToken);

        if (validToken.valid) {
          navigate('/reset/password/', { state: { token: token } })
        } else {
          //console.log('no es válido');
          setErrorToken(true);
        }

      })();

    }
  }, [])


  if (token === null) {
    return (
      <>
        <Helmet>
          <title>Neothek | Reset Password</title>
        </Helmet>
        <Box
          sx={{
            /*backgroundColor: 'background.default',*/
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center'
          }}
        >

          {tokensent ?

            <Container maxWidth="sm" variant='cont-nk'>

              <Box sx={{ mb: 2, mt: 3 }}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                  align="center"
                >
                  Gracias. Comprueba si has recibido un correo electrónico con las instrucciones para restablecer la contraseña.
                </Typography>

              </Box>

              <Box sx={{ pt: 1, pb: 2, textAlign: 'center' }}>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="body1"
                >
                  ¿No recibiste el correo electrónico? Verifica en tu carpeta de correo no deseado.
                </Typography>

                <Link
                  component={RouterLink}
                  to="/login/"
                  variant="h6"
                >
                  Ir a inicio de sesión
                </Link>
              </Box>

            </Container>

            :

            <Container maxWidth="sm" variant='cont-nk'>
              <Formik
                initialValues={{
                  email: ''
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string().email('E-mail inválido').max(255).required('Email es requerido')
                })}

                onSubmit={async (val, actions) => {

                  if (captcha.current.getValue() !== '') {
                    const ipv4 = await getPublicIp();
                    const res = await forgetPass(val.email, ipv4);

                    actions.resetForm();
                    setTokensent(true);

                  } else {
                    setValidCaptcha(false);
                  }


                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box sx={{ mb: 2, mt: 3 }}>
                      <Typography
                        color="textPrimary"
                        variant="h2"
                        align="center"
                      >
                        Restablecer la contraseña
                      </Typography>

                    </Box>

                    <Box sx={{ pt: 1, pb: 2 }}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="body1"
                        align="center"
                      >
                        Introduce la dirección de correo electrónico asociada a tu cuenta y te enviaremos un vínculo para restablecer tu contraseña.
                      </Typography>
                    </Box>

                    {errValidUser &&
                      <Box>
                        <Typography sx={{ color: 'red' }} variant="h6">
                          {errValidUser}
                        </Typography>
                      </Box>
                    }


                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Correo electrónico"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />

                    <Box align='center' sx={{ py: 2 }}>
                      <ReCAPTCHA
                        ref={captcha}
                        sitekey={appConfig.sitekey_captcha}
                        onChange={onChangeCaptcha}
                      />
                      {validCaptcha === false &&
                        <Typography sx={{ color: 'red' }} variant="h6">
                          Por favor acepte el capctha
                        </Typography>
                      }

                    </Box>

                    <Box sx={{ py: 3 }}>
                      <Button
                        color="primary"
                        disabled={disabledButton}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Continuar
                      </Button>
                    </Box>
                    <Box sx={{ paddingBottom: 3 }}>
                      <Typography
                        color="textSecondary"
                        variant="body1"
                        align='center'
                      >
                        <Link
                          component={RouterLink}
                          to="/login/"
                          variant="h6"
                        >
                          Volver a inicio de sesión
                        </Link>
                      </Typography>
                    </Box>

                  </form>
                )}
              </Formik>
            </Container>

          }


        </Box>
      </>
    );
  } else {
    if (errorToken) {

      return (
        <>
          <Helmet>
            <title>Neothek | Reset Password</title>
          </Helmet>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center'
            }}
          >

            <Container maxWidth="sm" variant='cont-nk'>

              <Box sx={{ mb: 2, mt: 3 }}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                  align="center"
                >
                  Lo sentimos,
                </Typography>

              </Box>

              <Box sx={{ pt: 1, pb: 2 }}>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="body1"
                  align="center"
                >
                  No se ha logrado procesar la solicitud para el restablecimiento de la contraseña. Es posible que el token se encuentre expirado.
                </Typography>

                <Typography
                  color="textSecondary"
                  variant="body1"
                  align='center'
                >
                  <Link
                    component={RouterLink}
                    to="/reset/"
                    variant="h6"
                  >
                    Volver a intentarlo.
                  </Link>
                </Typography>
              </Box>

            </Container>
          </Box>
        </>

      )

    } else {
      return (
        <Typography variant='body2' style={{ textAlign: 'center' }}>Validando datos...</Typography>
      )
    }

  }


};

export default ForgetPassword;
