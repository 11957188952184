import axios from 'axios';
import appConfig from '../config/main.config';
const apiserv = appConfig.url_api;

export default async function downloadInvoicePdf(invoiceId) {
    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/invoices/${invoiceId}/pdf`;

    const resp = await axios.get(api, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'arraybuffer' })
        .then(res => {
            //console.log('respuesta ', res)
            const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Nota-${invoiceId}`);
            document.body.appendChild(link);
            link.click();

            return {error: false}
        })
        .catch(e => {
            return {error: true}
            //console.log('error', e) 
        })
    /*.then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]
          ,{type: "application/pdf"}))
        var link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Nota-${invoiceId}`);
        document.body.appendChild(link);
        link.click();
    })
    .catch(function (error) {
        console.log('da error', error);
      }); */


    return resp
}